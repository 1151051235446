import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faPersonDress } from '@fortawesome/free-solid-svg-icons';



const DemographicsGender = () => {

    const statsDemographicsGender = [
        { 
            count: '90%', 
            label: 'Male', 
            bgColor: 'bgBlue', 
            corpIcon: faPerson, 
        },
        { 
            count: '24%', 
            label: 'Female', 
            bgColor: 'bgPink', 
            corpIcon: faPersonDress, 
        },
    ];

  return (
    <>
      <ul className="list-group list-group-flush">
      {statsDemographicsGender.map((stat, index) => (
        <li className="list-group-item px-0" key={index}>
          <div className="row align-items-center">
            <div className="col-5 pe-0">
              <h2 className="corporate-detail-subtitle">
              <FontAwesomeIcon icon={stat.corpIcon} className="css-DFA-Icon me-1" /> {stat.label}
              </h2>
            </div>
            <div className="col-1 px-0">
              <h2 className="corporate-detail-subtitle text-end">
                <span>{stat.count}</span>
              </h2>
            </div>
            <div className="col-6">
                <div className="progress-bar-container">
                    <div class="progress">
                        <div className={`progress-bar ${stat.bgColor}`} role="progressbar" style={{ width: stat.count }} ></div>
                    </div>
                </div>
            </div>
          </div>
        </li>
        ))}
      </ul>

    </>
  );
};

export default DemographicsGender;
