import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaPlus, FaArrowLeft, FaBlog, FaVideo } from 'react-icons/fa';
import Modal from './Modal';
import { fetchCategoryCount, addNewCategoryTag } from "../../../services/cms/Service";

const TagManagement = ({ onAddTag, onEditTag, onDeleteTag, onBack }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newTag, setNewTag] = useState({ title: '', description: '' });
  const [editingTag, setEditingTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [dummyTags, setDummyTags] = useState([]);

  // Dummy data with blog and video counts
  // const [dummyTags, setDummyTags] = useState([
  //   { id: 1, title: 'Workout', description: 'Exercise routines', publishedBlogs: 20, blogCount: 30, videoCount: 15 },
  //   { id: 2, title: 'Diet', description: 'Healthy eating tips', publishedBlogs: 15, blogCount: 25, videoCount: 8 },
  //   { id: 3, title: 'Mindfulness', description: 'Mental health and wellness', publishedBlogs: 10, blogCount: 18, videoCount: 5 },
  //   { id: 4, title: 'Equipment', description: 'Fitness gear reviews', publishedBlogs: 8, blogCount: 12, videoCount: 10 },
  // ]);

  async function fetch_Category_Count() {
    try {

      let response = await fetchCategoryCount({ type: "tag" });
      if (response) {
        const updatedCategories = response.data.data.pId.map((tag, index) => ({
          id: index + 1,  // You can adjust this as needed
          title: tag.name,
          description: `${tag.name} related topics`,  // Assuming a general description based on name
          publishedBlogs: 0,  // Assuming publishedBlogs is the same as blog_count
          blogCount: tag.blog_count,
          videoCount: tag.video_count
        }));

        setDummyTags(updatedCategories);
      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function add_New_Category_Tag(name) {
    try {

      let response = await addNewCategoryTag({ type: "tag", name: name });
      if (response) {
        console.log(response, "response")

      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetch_Category_Count()
  }, []);

  const handleCreateTag = () => {
    if (newTag.title && newTag.description) {
      const createdTag = {
        ...newTag,
        id: dummyTags.length + 1,
        publishedBlogs: 0,
        blogCount: 0,
        videoCount: 0
      };

      // add_New_Category_Tag(createdCategory.title)
      setDummyTags([...dummyTags, createdTag]);
      // onAddTag(createdTag);
      setNewTag({ title: '', description: '' });
      setShowModal(false);
    }
  };

  const handleEditTag = () => {
    if (editingTag) {
      setDummyTags(dummyTags.map(tag =>
        tag.id === editingTag.id ? editingTag : tag
      ));
      onEditTag(editingTag);
      setEditingTag(null);
      setShowModal(false);
    }
  };

  const openEditModal = (tag) => {
    setEditingTag(tag);
    setShowModal(true);
  };

  const openDeleteModal = (tag) => {
    setDeletingTag(tag);
    setShowDeleteModal(true);
  };

  const handleDeleteTag = () => {
    if (deletingTag) {
      setDummyTags(dummyTags.filter(tag => tag.id !== deletingTag.id));
      onDeleteTag(deletingTag.id);
      setDeletingTag(null);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl">
      <div className="tw-relative tw-mb-6">
        <h2 className="tw-text-3xl tw-font-bold tw-text-gray-800 tw-text-center">Tag Management</h2>
        <button
          className="tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
          onClick={onBack}
        >
          <FaArrowLeft className="tw-mr-2" /> Back
        </button>
        <button
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300 tw-absolute tw-right-0 tw-top-0 tw-bottom-0"
          onClick={() => setShowModal(true)}
        >
          <FaPlus className="tw-inline tw-mr-2" /> Create Tag
        </button>
      </div>
      <table className="tw-w-full tw-mt-4">
        <thead>
          <tr className="tw-bg-gray-100">
            <th className="tw-p-2">Title</th>
            <th className="tw-p-2">Description</th>
            <th className="tw-p-2">Published Blogs</th>
            <th className="tw-p-2">Blog Count</th>
            <th className="tw-p-2">Video Count</th>
            <th className="tw-p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {dummyTags.map(tag => (
            <tr key={tag.id} className="tw-border-b">
              <td className="tw-p-2">{tag.title}</td>
              <td className="tw-p-2">{tag.description}</td>
              <td className="tw-p-2">{tag.publishedBlogs}</td>
              <td className="tw-p-2">
                <span className="tw-flex tw-items-center">
                  <FaBlog className="tw-mr-1 tw-text-blue-500" /> {tag.blogCount}
                </span>
              </td>
              <td className="tw-p-2">
                <span className="tw-flex tw-items-center">
                  <FaVideo className="tw-mr-1 tw-text-green-500" /> {tag.videoCount}
                </span>
              </td>
              <td className="tw-p-2">
                <button className="tw-text-blue-500 hover:tw-text-blue-700 tw-mr-2" onClick={() => openEditModal(tag)}><FaEdit /></button>
                <button className="tw-text-red-500 hover:tw-text-red-700" onClick={() => openDeleteModal(tag)}><FaTrash /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingTag(null);
        }}
        title={editingTag ? "Edit Tag" : "Create New Tag"}
      >
        <input
          type="text"
          placeholder="Tag Title (max 20 characters)"
          maxLength={20}
          value={editingTag ? editingTag.title : newTag.title}
          onChange={(e) => editingTag
            ? setEditingTag({ ...editingTag, title: e.target.value })
            : setNewTag({ ...newTag, title: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <textarea
          placeholder="Tag Description (max 100 characters)"
          maxLength={100}
          value={editingTag ? editingTag.description : newTag.description}
          onChange={(e) => editingTag
            ? setEditingTag({ ...editingTag, description: e.target.value })
            : setNewTag({ ...newTag, description: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <button
          onClick={editingTag ? handleEditTag : handleCreateTag}
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
        >
          {editingTag ? "Update Tag" : "Create Tag"}
        </button>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setDeletingTag(null);
        }}
        title="Confirm Deletion"
      >
        <p className="tw-mb-4">Are you sure you want to delete the tag "{deletingTag?.title}"?</p>
        <div className="tw-flex tw-justify-end tw-space-x-2">
          <button
            onClick={() => setShowDeleteModal(false)}
            className="tw-bg-gray-300 tw-text-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-gray-400 tw-transition-colors tw-duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteTag}
            className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TagManagement;