import React, { useState } from 'react';
import { FaHeartbeat, FaLaptopMedical, FaClinicMedical, FaHospital, FaUserMd } from 'react-icons/fa';

const ehrSystems = [
  { name: 'Athena Health', icon: FaHeartbeat },
  { name: 'Nextgen Healthcare', icon: FaLaptopMedical },
  { name: 'eClinicalWorks', icon: FaClinicMedical },
  { name: 'Tribeca Care', icon: FaHospital },
  { name: 'Lifepoint', icon: FaHeartbeat },
  { name: 'Dr Chrona', icon: FaUserMd },
  { name: 'Experity', icon: FaLaptopMedical },
  { name: 'uDo test', icon: FaClinicMedical },
];

const EHRCard = ({ name, Icon, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-center">
      <div className="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-2xl" />
      </div>
      <h3 className="tw-text-lg tw-font-semibold tw-text-[#03335b]">{name}</h3>
    </div>
  </div>
);

const EHR = () => {
  const [activeSystem, setActiveSystem] = useState(null);

  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <p className="tw-text-gray-600 tw-mb-6">
        Our labs EHR integration is designed to facilitate seamless communication between labs and medical practices like healthcare providers & POL.
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
        {ehrSystems.map((system, index) => (
          <EHRCard
            key={system.name}
            name={system.name}
            Icon={system.icon}
            isActive={index === activeSystem}
            onClick={() => setActiveSystem(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default EHR;