import React, { useState } from 'react';
import { FaGoogle, FaAmazon, FaMicrosoft, FaEnvelope } from 'react-icons/fa';

const emailServices = [
  { name: 'Google', icon: FaGoogle, description: 'Integrate Google email services for efficient communication.' },
  { name: 'AWS SES', icon: FaAmazon, description: 'Use Amazon Simple Email Service for scalable email sending.' },
  { name: 'Microsoft Exchange', icon: FaMicrosoft, description: 'Connect Microsoft Exchange for robust email functionality.' },
  { name: 'Custom Email Service', icon: FaEnvelope, description: 'Configure a custom email service for tailored solutions.' },
];

const EmailServiceCard = ({ name, Icon, description, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-start tw-mb-4">
      <div className="tw-w-16 tw-h-16 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-4xl" />
      </div>
      <div>
        <h3 className="tw-text-lg tw-font-semibold tw-text-[#03335b] tw-mb-2">{name}</h3>
        <p className="tw-text-sm tw-text-gray-600 tw-leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

const Mails = () => {
  const [activeService, setActiveService] = useState(0); // Default to Google (index 0)

  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <h2 className="tw-text-2xl tw-font-bold tw-text-[#03335b] tw-mb-6">Email Service Integration</h2>
      <p className="tw-text-gray-600 tw-mb-6">
        Integrating email services is crucial for the platform to send email communications efficiently and effectively.
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
        {emailServices.map((service, index) => (
          <EmailServiceCard
            key={service.name}
            name={service.name}
            Icon={service.icon}
            description={service.description}
            isActive={index === activeService}
            onClick={() => setActiveService(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Mails;