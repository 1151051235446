import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';


function SmartcardsTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');

    const columns = [
        {
            name: 'Smartcards Tilte',
            selector: row => row.pushSmartcardsTitle,
            sortable: true,
            cell: row => (
                <a href="#" dangerouslySetInnerHTML={{ __html: row.pushSmartcardsTitle }}></a>
            ),
        },
        {
            name: 'Schedule Time',
            selector: row => row.scheduleTime,
            sortable: true,
        },
        {
            name: 'Smartcards Image',
            selector: row => row.notificationImage,
            sortable: true,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.notificationImage }} />
            ),
        },
        {
            name: 'Select Action',
            selector: row => row.selectAction,
            sortable: true,
        },
        {
            name: 'Smartcards Content',
            selector: row => row.notificationContent,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <span style={{ color: row.status === 'Sent' ? '#01CD88' : '#FF0000' }}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Replica',
            selector: row => row.replica,
            sortable: true,
            cell: row => (
                <span style={{ color: '#6180F0' }}>
                    {row.replica}
                </span>
            ),
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    <button className="btn btn-Cancel mx-2">
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            ),
        },
    ];

    const data = [
        {
            id: 1,
            pushSmartcardsTitle: 'Coach Interaction In-house Challenge',
            scheduleTime: '10 May 2017 05:16 PM',
            selectAction: 'Now More',
            notificationContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            notificationImage: '<img src="https://appcdn.goqii.com/storeimg/49850_1726645083.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />',
            status: 'Sent',
            replica: 'Replica',
        },
        {
            id: 2,
            pushSmartcardsTitle: 'Coach Interaction In-house Challenge',
            scheduleTime: '10 May 2017 05:16 PM',
            selectAction: 'Now More',
            notificationContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            notificationImage: '<img src="https://appcdn.goqii.com/storeimg/49850_1726645083.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />',
            status: 'Expired',
            replica: 'Replica',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.pushSmartcardsTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.scheduleTime.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesStatus = selectedStatus === 'All' || item.status === selectedStatus;

        return matchesSearchQuery && matchesStatus;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Smartcards Title or Schedule Time"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Sent">Sent</option>
                            <option value="Expired">Expired</option>
                        </select>
                    </div>
                    {/* <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div> */}
                    <div className="col-md-auto text-end pe-0"><Link className="btn btn-primary add-new-program-btn" to="/create-smart-cards">Create Smartcards</Link></div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default SmartcardsTable;
