import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchCNCalendarCalls, getCareNavigators } from "../../../services/medengage/Service";
import './CNCalendar.css';
import moment from 'moment';
import { event } from 'jquery';

const callIcon = 'https://appcdn.goqii.com/storeimg/18942_1722928366.png';
const doctorCallIcon = 'https://appcdn.goqii.com/storeimg/43961_1722928741.png';

const CNCalendar = ({ onUserClick }) => {
    const calendarRef = useRef(null);
    const [cnId, setCnId] = useState("");
    const [carenavigatorsList, setCarenavigatorsList] = useState([]);
    const [events, setEvents] = useState([]);
    const [calendarCalls, setCalendarCalls] = useState([]);
    const [emptySlots, setEmptySlots] = useState([]);
    const selectedUserObject = { userId: "", name: "" };
    const [selectedUser, setSelectedUser] = useState(selectedUserObject);
    const [users, setUsers] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [error, setError] = useState("");
    const [currentDateRange, setCurrentDateRange] = useState({ start: null, end: null });
    const [showScheduleCallModal, setShowScheduleCallModal] = useState(false);

    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    const validEndDate = new Date(nextMonth);
    validEndDate.setDate(validEndDate.getDate() + 2);


    useEffect(() => {
        getCareNavigatorsList();
    }, []);

    useEffect(() => {
        console.log("here", cnId);
        getCalendarCalls(cnId);
    }, [cnId])

    const getCareNavigatorsList = async () => {
        await getCareNavigators()
            .then((response) => {
                if (response.data.code === 200) {
                    const carenavigators = response.data.data.users;
                    setCarenavigatorsList(carenavigators);
                }
            }).catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        const mergedEvents = [...calendarCalls, ...emptySlots];
        setEvents(mergedEvents);
    }, [calendarCalls, emptySlots])

    const handleDatesSet = (arg) => {
        setError("");

        const viewType = arg.view.type;
        const startTime = arg.start;
        const endTime = arg.end;
        if (viewType == 'timeGridDay') {
            const formattedStartTime = moment(startTime).format('YYYY-MM-DD');
            const formattedEndTime = moment(endTime).format('YYYY-MM-DD');
            const formattedCurrentStart = currentDateRange.start ? moment(currentDateRange.start).format('YYYY-MM-DD') : null;
            const formattedCurrentEnd = currentDateRange.end ? moment(currentDateRange.end).format('YYYY-MM-DD') : null;

            if (formattedStartTime !== formattedCurrentStart || formattedEndTime !== formattedCurrentEnd) {
                //getEmptyCalendarSlots(formattedStartTime)
                setCurrentDateRange({ start: startTime, end: endTime });
            }

        }
    };

    /*const getEmptyCalendarSlots = async (date) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        };
        await fetchEmptyCalendarSlots(date, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    const calendarEmptySlots = response.data.data.slots;

                    setEmptySlots(calendarEmptySlots);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    };*/

    const getCalendarCalls = async () => {
        if (cnId !== "") {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            };
            await fetchCNCalendarCalls(cnId, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        const calls = response.data.data.calls
                        setCalendarCalls(calls);
                        /*if (dt !== undefined) {
                            getEmptyCalendarSlots(dt);
                        }*/
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'DoctorCall':
                return doctorCallIcon;
            case 'Cancelled':
                return callIcon;
            case 'Scheduled':
                return callIcon;
            case 'Completed':
                return callIcon;
            case 'Missed':
                return callIcon;
            default:
                return null; // Default icon or none
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'DoctorCall':
                return '#D9EBFF';
            case 'Cancelled':
                return '#F76E66';
            case 'Scheduled':
                return '#1b75bc';
            case 'Completed':
                return '#84bd93';
            case 'In-progress':
                return '#3BA947';
            case 'Unsuccessful':
                return '#F76E66';
            case 'Missed':
                return '#FBC525';
            default:
                return '#C0C0C0'; // Default color
        }
    };

    const renderEventContent = (eventInfo) => {
        const status = eventInfo.event.extendedProps.status;
        const icon = getStatusIcon(status);
        const backgroundColor = getStatusColor(status);
        let textColor = 'white'; // Default text color for most statuses

        if (status === 'DoctorCall') {
            textColor = '#333'; // Change text color to black for 'DoctorCall' status
            return (
                <div style={{ backgroundColor, color: textColor }} className='calendar-label-box'>
                    {eventInfo.event.title}
                    <img src={icon} alt="Status Icon" style={{ marginLeft: '5px', height: '18px' }} />
                </div>
            );
        }

        return (
            <div style={{ backgroundColor, color: textColor }} className='calendar-label-box'>
                {
                    (eventInfo.event.extendedProps.attruid) ?
                        <>
                            <Link className='name'>
                                <b className="icon-phone-schd"><label className='timeText'>{eventInfo.timeText}</label> <img src="https://appcdn.goqii.com/storeimg/18942_1722928366.png" alt="Status Icon" style={{ marginLeft: '5px', height: '14px' }} /></b>
                                <span className='mx-0'>{eventInfo.event.title}</span>
                            </Link>
                        </>
                        :
                        <b className="icon-phone-schd"><label className='timeText'>{eventInfo.timeText}</label> <img src="https://appcdn.goqii.com/storeimg/18942_1722928366.png" alt="Status Icon" style={{ marginLeft: '5px', height: '14px' }} /></b>
                }

            </div>
        );
    };

    const handleDateClick = (arg) => {
        console.log(arg);
        if (arg.view.type == 'timeGridDay') {
            setSelectedDate(arg.dateStr);
            setUsers([]);
            setShowScheduleCallModal(true);
        }
    };

    /*const handleSearch = (e) => {
        setError("");
        setSearchStr(e.target.value);
        if (e.target.value != "") {
            getUsersList(e.target.value);
        } else {
            setUsers([])
        }
    }*/

    /*const getUsersList = async (searchStr) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchUsersList({}, searchStr, 1, ct)
            .then((response) => {
                if (response.data.data.users)
                    setUsers(response.data.data.users);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }*/

    const handleSuggestionClick = (suggestion) => {
        setError("");
        setSelectedUser({ userId: suggestion.userId, name: suggestion.memberName });
        setSearchStr(suggestion.memberName);
        setUsers([]);
    };

    /*const scheduleCall = async () => {
        setError("");
        if (selectedUser.userId == "") {
            setError("Please select the user");
        } else if (selectedDate == null) {
            setError("Please select the date and time");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await scheduleCarenavigatorCall(selectedUser.userId, moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'), ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedUser(selectedUserObject);
                        setShowScheduleCallModal(false);
                        setTimeout(() => {
                            getCalendarCalls(moment(selectedDate).format('YYYY-MM-DD'));
                        }, 3000);

                        setSelectedDate(null);
                    } else {
                        setError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }*/

    return (
        <>
        <section className='ReportsPrograms'>
        <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">CareNavigator Calendar
                        </h1>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://appcdn.goqii.com/storeimg/75342_1725007987.jpg" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The CareNavigator Calendar offers a quick, clear view of all appointments, making it easier to stay organized and manage daily schedules efficiently.</p>
                        </div>
                    </div>
                </div>
                <div className='content-programs mb-3'>


            <div className='row align-items-center'>
                <div className='col-md me-5 pe-5'>
                    <select name="cnId" value={cnId} className='form-select input-field-ui' onChange={(e) => setCnId(e.target.value)}>
                        <option value="">Select Care Navigator</option>
                        {
                            carenavigatorsList.map((item, index) => {
                                return <option key={index} value={item.id}>{item.userName}</option>
                            })
                        }
                    </select>
                </div>
                <div className='col-md-auto text-end pe-0'>
                    <div className='row align-items-center calendar-status-text text-end'>
                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="legend-box" style={{ background: '#1b75bc', width: '20px', height: '20px', marginRight: '4px' }}></div>
                            <span className="me-3">Scheduled</span>
                        </div>
                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="legend-box" style={{ background: '#84bd93', width: '20px', height: '20px', marginRight: '4px' }}></div>
                            <span className="me-3">Completed</span>
                        </div>
                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="legend-box" style={{ background: '#FBC525', width: '20px', height: '20px', marginRight: '4px' }}></div>
                            <span className="me-3">Missed</span>
                        </div>
                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="legend-box" style={{ background: '#F76E66', width: '20px', height: '20px', marginRight: '4px' }}></div>
                            <span className="me-3">Cancelled/Unsuccessful</span>
                        </div>
                        <div className='col-md-auto pe-0' style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="legend-box" style={{ background: '#C0C0C0', width: '20px', height: '20px', marginRight: '4px' }}></div>
                            <span className="me-2">Member No-Show</span>
                        </div>
                        <div className='col hand-cursor text-end'> </div>
                    </div>
                </div>
            </div>
            <div className='row align-items-center'>
                <div className='col-md-12'>
                    <div className="calendar-container mt-3">
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay',
                            }}
                            initialView="dayGridMonth"
                            editable={false}
                            eventStartEditable={false}
                            eventDurationEditable={false}
                            selectable={false}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={true}
                            events={events}
                            height="1150px"
                            slotDuration="00:15:00"
                            eventContent={renderEventContent}
                        //dateClick={handleDateClick}
                        //datesSet={handleDatesSet}
                        /*validRange={{
                          end: validEndDate.toISOString().split('T')[0],
                        }}*/
                        />
                    </div>
                </div>
            </div >



            {/*<Modal id="appointmentSchedule" show={showScheduleCallModal} onHide={() => setShowScheduleCallModal(false)} centered size="" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Schedule Call - {moment(selectedDate).format('DD MMM, YYYY [at] h:mm a')}</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Name</label>
                            <input type="text" name="name" value={searchStr} className='form-control control-input-ui' onChange={handleSearch} />
                            {
                                users.length > 0 &&
                                <ul className="suggestions-list">
                                    {users.map((item) => (
                                        <li
                                            key={item.userId}
                                            onClick={() => handleSuggestionClick(item)}
                                        >
                                            {item.memberName}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                        <div className="col-12 mt-2">
                            <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                            <button type="button" className="btn btn-primary save-btn" onClick={() => scheduleCall()}>Save</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>*/}
            </div>
            </div>
            </section>
        </>
    );
};

export default CNCalendar