import React, { useState } from 'react';
import { FaYoutube, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import { useNavigate } from 'react-router-dom';

const UploadYouTubeVideo = ({ onVideoChosen, onBack }) => {
  const [youtubeLink, setYoutubeLink] = useState('');
  const [videoId, setVideoId] = useState('');
  const navigate = useNavigate(); 

  const handleYoutubeLinkChange = (e) => {
    const link = e.target.value;
    setYoutubeLink(link);
    // Extract video ID from YouTube link
    const match = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/);
    if (match) {
      setVideoId(match[1]);
    }
  };

  const handleProceed = () => {
    // if (videoId) {
    //   onVideoChosen({
    //     title: 'YouTube Video', // You might want to fetch the actual title from YouTube API
    //     videoUrl: `https://www.youtube.com/embed/${videoId}`,
    //     thumbnailUrl: `https://img.youtube.com/vi/${videoId}/0.jpg`,
    //     videoType: 'youtube',
    //     description: 'YouTube video', // You might want to fetch the actual description from YouTube API
    //   });
    // }

    if(videoId){ 
      navigate(`/content-management/edit-yt-video/${videoId}`); 
    }
  };

  return (
    <div className="tw-p-8 tw-bg-white tw-rounded-lg tw-shadow-xl tw-max-w-4xl tw-mx-auto">
      <h2 className="tw-text-3xl tw-font-bold tw-mb-8 tw-text-gray-800 tw-flex tw-items-center">
        <FaYoutube className="tw-text-red-600 tw-mr-3" /> Upload YouTube Video
      </h2>
      
      <div className="tw-mb-8">
        <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-2" htmlFor="youtube-link">
          YouTube Link
        </label>
        <div className="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
          <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
            <FaYoutube className="tw-h-5 tw-w-5 tw-text-gray-400" />
          </div>
          <input
            type="text"
            id="youtube-link"
            placeholder="Paste YouTube link here"
            className="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 tw-pr-12 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
            value={youtubeLink}
            onChange={handleYoutubeLinkChange}
          />
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center">
            <span className="tw-text-gray-500 sm:tw-text-sm tw-mr-3">
              youtube.com
            </span>
          </div>
        </div>
      </div>

      {videoId && (
        <div className="tw-mb-8 tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-shadow-inner">
          <div className="tw-aspect-w-16 tw-aspect-h-9">
            <iframe 
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              className="tw-w-full tw-h-full tw-rounded-md"
            ></iframe>
          </div>
        </div>
      )}

      <div className="tw-flex tw-justify-between tw-items-center tw-mt-8">
        <button 
          className="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-150 tw-ease-in-out"
          onClick={onBack}
        >
          <FaArrowLeft className="tw-mr-2 -tw-ml-1 tw-h-5 tw-w-5" />
          Back
        </button>
        <button 
          className={`tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-shadow-sm tw-text-white tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500 tw-transition tw-duration-150 tw-ease-in-out ${!videoId ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`}
          onClick={handleProceed}
          disabled={!videoId}
        >
          Proceed
          <FaArrowRight className="tw-ml-2 -tw-mr-1 tw-h-5 tw-w-5" />
        </button>
      </div>
    </div>
  );
};

export default UploadYouTubeVideo;