import React from "react";


function ContentCallDispositionDetails() {

    
    return (
        <>
                        <div className='member-engagement mb-3 coach-call-box-1'>
                            <div className='row align-items-center'>
                                <div className='col-auto'>
                                <div className="user-pics-box">
                                            <img src={require("../../../images/icon-profile-image.png")} className="user-pics-img" alt="" />
                                        </div>
                                </div>
                                <div className='col'>
                                    <h5 className="user-card-title" >Fatima Aaira</h5>
                                </div>
                                <div className='col-auto'>
                                    <h3 className="user-card-subtitle">Member ID: B65L23</h3>
                                    <h3 className="user-card-subtitle">Invited Date: 23 Mar 2024</h3>
                                </div>
                            </div>
                        </div>

                        <div className='member-engagement mb-3 p-4'>
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <h5 className="call-disposition-subtitle">Agent Name:</h5>
                                    <h6 className="call-disposition-title">Azma Nadaf</h6>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="call-disposition-subtitle">Call Duration:</h5>
                                    <h6 className="call-disposition-title">01 hr :36 min</h6>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="call-disposition-subtitle">Call date & time:</h5>
                                    <h6 className="call-disposition-title">04-02-2024 | 2:30 pm</h6>
                                </div>
                            </div>
                        </div>

                        <div className='member-engagement mb-3 p-4'>
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <h6 className="call-disposition-title">Call Status</h6>

                                    <div className="row mt-2">
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                                <label className="form-check-label" htmlFor="inlineRadio1">Enrolled</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                                <label className="form-check-label" htmlFor="inlineRadio2">No Answer</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3"/>
                                                <label className="form-check-label" htmlFor="inlineRadio3">Busy</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4"/>
                                                <label className="form-check-label" htmlFor="inlineRadio4">Wrong Number</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5a" value="option5a"/>
                                                <label className="form-check-label" htmlFor="inlineRadio5a">Do Not Call</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="option5"/>
                                                <label className="form-check-label" htmlFor="inlineRadio5">Not Interested</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio6" value="option6"/>
                                                <label className="form-check-label" htmlFor="inlineRadio6">Transferred</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio7" value="option7"/>
                                                <label className="form-check-label" htmlFor="inlineRadio7">Follow-Up Required</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio8" value="option8"/>
                                                <label className="form-check-label" htmlFor="inlineRadio8">Disconnected</label>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>
                                </div>
                                <div className="row mt-2">
                                        <div className="col-md-12">
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio9" value="option9"/>
                                                <label className="form-check-label" htmlFor="inlineRadio9">Schedule a callback</label>
                                            </div>
                                            <div className="row">
                                            <div className="col-auto">
                                                <label htmlFor='inputdate' className="col-form-label">Date and time:</label>
                                            </div>
                                            <div className="col-auto">
                                                <input type="date" id="inputdate" className="form-control input-field-ui" />
                                            </div>
                                            </div>
                                            <hr/>
                                        </div>
                                </div>
                                <div className="row mt-2">
                                        <div className="col-md-6">
                                            <div className="row">
                                            <div className="col-auto">
                                                <label htmlFor='inputdate' className="col-form-label">Preferred channel:</label>
                                            </div>
                                            <div className="col">
                                            <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example">
                                                <option>Select</option>
                                                <option value="1">Whatsapp</option>
                                                <option value="2">Email</option>
                                                <option value="3">Phone</option>
                                                <option value="4">SMS</option>
                                                </select>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                            <div className="col-auto">
                                                <label htmlFor='inputdate' className="col-form-label">Preferred time:</label>
                                            </div>
                                            <div className="col d-flex">
                                                <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example">
                                                    <option>From:</option>
                                                    <option value="1">10:00 AM</option>
                                                    <option value="2">11:00 AM</option>
                                                    <option value="3">12:00 PM</option>
                                                    <option value="4">1:00 PM</option>
                                                </select>

                                                <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example">
                                                    <option>To:</option>
                                                    <option value="1">10:00 AM</option>
                                                    <option value="2">11:00 AM</option>
                                                    <option value="3">12:00 PM</option>
                                                    <option value="4">1:00 PM</option>
                                                </select>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-5">
                                            <button type="button" className="btn btn-primary program-save-btn">Save</button>
                                        </div>
                                </div>
                            </div>
                        </div>

            
        </>
    );
}

export default ContentCallDispositionDetails;
