import React, { useState } from 'react';
import { FaEdit, FaTrash, FaPlus, FaArrowLeft, FaSearch } from 'react-icons/fa';
import Modal from './Modal';

const ImageTagging = ({ onAddImage, onEditImage, onDeleteImage, onBack }) => {
  const [showModal, setShowModal] = useState(false);
  const [newImage, setNewImage] = useState({ file: null, tags: '' });
  const [editingImage, setEditingImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Mock data for previously uploaded images
  const [images, setImages] = useState([
    { id: 1, url: 'https://example.com/image1.jpg', tags: ['nature', 'landscape'] },
    { id: 2, url: 'https://example.com/image2.jpg', tags: ['portrait', 'people'] },
  ]);

  const handleCreateImage = () => {
    if (newImage.file && newImage.tags) {
      onAddImage(newImage);
      setNewImage({ file: null, tags: '' });
      setShowModal(false);
    }
  };

  const handleEditImage = () => {
    if (editingImage) {
      onEditImage(editingImage);
      setEditingImage(null);
      setShowModal(false);
    }
  };

  const openEditModal = (image) => {
    setEditingImage(image);
    setShowModal(true);
  };

  const filteredImages = images.filter(image => 
    image.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl">
      <div className="tw-relative tw-mb-6">
        <h2 className="tw-text-3xl tw-font-bold tw-text-gray-800 tw-text-center">Image Tagging</h2>
        <button 
          className="tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-border-2 tw-border-black tw-px-2 tw-py-2 tw-rounded-full hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
          onClick={onBack}
        >
          <FaArrowLeft className="" />
        </button>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <button 
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
          onClick={() => setShowModal(true)}
        >
          <FaPlus className="tw-inline tw-mr-2" /> Upload New Image
        </button>
        <div className="tw-flex tw-items-center tw-border tw-rounded-md tw-overflow-hidden">
          <input
            type="text"
            placeholder="Search images by tag..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="tw-p-2 tw-outline-none"
          />
          <button className="tw-bg-blue-500 tw-text-white tw-p-2">
            <FaSearch />
          </button>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
        {filteredImages.map((image) => (
          <div key={image.id} className="tw-border tw-rounded-md tw-p-2">
            <img src={image.url} alt="Tagged" className="tw-w-full tw-h-40 tw-object-cover tw-mb-2" />
            <div className="tw-flex tw-flex-wrap tw-gap-1 tw-mb-2">
              {image.tags.map((tag, index) => (
                <span key={index} className="tw-bg-gray-200 tw-rounded-full tw-px-2 tw-py-1 tw-text-sm">
                  {tag}
                </span>
              ))}
            </div>
            <div className="tw-flex tw-justify-between">
              <button
                onClick={() => openEditModal(image)}
                className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded-md hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
              >
                <FaEdit className="tw-inline tw-mr-1" /> Edit
              </button>
              <button
                onClick={() => onDeleteImage(image.id)}
                className="tw-bg-red-500 tw-text-white tw-px-2 tw-py-1 tw-rounded-md hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
              >
                <FaTrash className="tw-inline tw-mr-1" /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingImage(null);
        }}
        title={editingImage ? "Edit Image" : "Upload New Image"}
      >
        <input
          type="file"
          accept="image/*"
          onChange={(e) => editingImage
            ? setEditingImage({...editingImage, file: e.target.files[0]})
            : setNewImage({...newImage, file: e.target.files[0]})}
          className="tw-w-full tw-mb-4"
        />
        <input
          type="text"
          placeholder="Tags (comma-separated)"
          value={editingImage ? editingImage.tags.join(', ') : newImage.tags}
          onChange={(e) => editingImage
            ? setEditingImage({...editingImage, tags: e.target.value.split(',').map(tag => tag.trim())})
            : setNewImage({...newImage, tags: e.target.value})}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <button 
          onClick={editingImage ? handleEditImage : handleCreateImage}
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
        >
          {editingImage ? "Update Image" : "Upload Image"}
        </button>
      </Modal>
    </div>
  );
};

export default ImageTagging;