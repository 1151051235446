import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

function TablePracticeConnect() {
    const [searchQuery, setSearchQuery] = useState('');
    const [divisionName, setDivisionName] = useState('All');
    const [practiceName, setPracticeName] = useState('All');

    const columns = [
        {
            name: 'Division Name',
            selector: row => row.divisionName,
            sortable: true,
        },
        {
            name: 'Practice Name',
            selector: row => row.practiceName,
            sortable: true,
        },
        {
            name: 'Doctors Onboarded',
            selector: row => row.doctorsOnboarded,
            sortable: true,
        },
        {
            name: 'Patients Onboarded',
            selector: row => row.patientsOnboarded,
            sortable: true,
        },
    ];

    const data = [
        {
            id: 1,
            divisionName: 'Modality Walsall',
            practiceName: 'New Invention Practice',
            doctorsOnboarded: '10',
            patientsOnboarded: '200',
        },
        {
            id: 2,
            divisionName: 'Modality Walsall',
            practiceName: 'Keys Practice',
            doctorsOnboarded: '10',
            patientsOnboarded: '120',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setDivisionName('All');
        setPracticeName('All');
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery =
            item.practiceName.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesDivisionName = divisionName === 'All' || item.divisionName === divisionName;
        const matchesPracticeName = practiceName === 'All' || item.practiceName === practiceName;

        return matchesSearchQuery && matchesDivisionName && matchesPracticeName;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Practice Name or Division Name"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={divisionName}
                            onChange={e => setDivisionName(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Division--</option>
                            <option value="Modality Walsall">Modality Walsall</option>
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={practiceName}
                            onChange={e => setPracticeName(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Practice--</option>
                            <option value="New Invention Practice">New Invention Practice</option>
                            <option value="Keys Practice">Keys Practice</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default TablePracticeConnect;
