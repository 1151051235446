import React, { useState, useEffect } from 'react';
import { FaStripe, FaSlack, FaTrello, FaDropbox, FaFigma } from 'react-icons/fa';
import { SiHubspot, SiDiscord, SiZapier, SiMailchimp, SiGoogledrive, SiNotion, SiAsana } from 'react-icons/si';

const integrations = [
  { name: 'Stripe', icon: FaStripe, connected: false },
  { name: 'Hubspot', icon: SiHubspot, connected: false },
  { name: 'Slack', icon: FaSlack, connected: true },
  { name: 'Discord', icon: SiDiscord, connected: false },
  { name: 'Trello', icon: FaTrello, connected: false },
  { name: 'Zapier', icon: SiZapier, connected: false },
  { name: 'Dropbox', icon: FaDropbox, connected: false },
  { name: 'Mailchimp', icon: SiMailchimp, connected: true },
  { name: 'Figma', icon: FaFigma, connected: true },
  { name: 'Google Drive', icon: SiGoogledrive, connected: false },
  { name: 'Asana', icon: SiAsana, connected: false },
  { name: 'Notion', icon: SiNotion, connected: false },
];

const IntegrationCard = ({ name, Icon, connected, onClick }) => {
  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-4 tw-border tw-border-[#03335b] tw-flex tw-flex-col tw-items-center tw-justify-between tw-transition-all tw-duration-300 hover:tw-scale-105">
      <div className="tw-w-12 tw-h-12 tw-bg-gray-200 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mb-2">
        <Icon className="tw-text-[#03335b] tw-text-2xl" />
      </div>
      <h3 className="tw-text-[#03335b] tw-font-semibold tw-mb-2">{name}</h3>
      <p className="tw-text-[#03335b] tw-text-sm tw-text-center tw-mb-4">
        The process of creating functional and aesthetically attractive objects.
      </p>
      <button
        onClick={onClick}
        className={`tw-px-4 tw-py-2 tw-rounded-md tw-font-medium tw-transition-all tw-duration-300 tw-text-white ${
          connected
            ? 'tw-bg-green-600 hover:tw-bg-green-700'
            : 'tw-bg-[#03335b] hover:tw-bg-[#024b8b]'
        }`}
      >
        {connected ? 'Connected' : 'Connect'}
      </button>
    </div>
  );
};

const AllIntegration = () => {
  const [integrationState, setIntegrationState] = useState(integrations);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [modalAnimation, setModalAnimation] = useState('tw-opacity-0 tw-scale-95');

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => setModalAnimation('tw-opacity-100 tw-scale-100'), 10);
    } else {
      setModalAnimation('tw-opacity-0 tw-scale-95');
    }
  }, [isModalOpen]);

  const handleIntegrationClick = (integration) => {
    setSelectedIntegration(integration);
    setIsModalOpen(true);
  };

  const handleConnect = () => {
    if (username && password) {
      setIntegrationState(prev =>
        prev.map(int => int.name === selectedIntegration.name ? { ...int, connected: true } : int)
      );
      setIsModalOpen(false);
      setUsername('');
      setPassword('');
    }
  };

  const handleDisconnect = () => {
    setIntegrationState(prev =>
      prev.map(int => int.name === selectedIntegration.name ? { ...int, connected: false } : int)
    );
    setIsModalOpen(false);
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-m-4 tw-mb-20 tw-shadow-lg">
      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-6">
        {integrationState.map((integration) => (
          <IntegrationCard
            key={integration.name}
            name={integration.name}
            Icon={integration.icon}
            connected={integration.connected}
            onClick={() => handleIntegrationClick(integration)}
          />
        ))}
      </div>

      {isModalOpen && selectedIntegration && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50 tw-transition-opacity tw-duration-300">
          <div className={`tw-bg-white tw-rounded-lg tw-p-6 tw-w-full tw-max-w-md tw-transform tw-transition-all tw-duration-300 tw-relative ${modalAnimation}`}>
          <button onClick={() => setIsModalOpen(false)} className='tw-text-[#03335b] tw-absolute tw-top-4 tw-right-4 tw-text-3xl tw-transition-all tw-duration-500 hover:tw-rotate-90 tw-px-2 tw-rounded-full tw-bg-gray-200 '>×</button>
            <h2 className="tw-text-2xl tw-font-bold tw-text-[#03335b] tw-mb-4">
              {selectedIntegration.connected
                ? `Connected to ${selectedIntegration.name}`
                : `Login to ${selectedIntegration.name}`}
            </h2>
            {!selectedIntegration.connected ? (
              <>
                <input
                  type="text"
                  placeholder="Username or Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="tw-w-full tw-px-4 tw-py-2 tw-mb-4 tw-border tw-border-gray-300 tw-rounded-md tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-[#03335b]"
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="tw-w-full tw-px-4 tw-py-2 tw-mb-6 tw-border tw-border-gray-300 tw-rounded-md tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-[#03335b]"
                />
                <button
                  onClick={handleConnect}
                  className="tw-w-full tw-px-4 tw-py-2 tw-bg-[#03335b] tw-text-white tw-rounded-md tw-font-medium tw-hover:bg-[#024b8b] tw-transition-colors tw-duration-300"
                >
                  Login
                </button>
              </>
            ) : (
              <p className="tw-text-[#03335b] tw-mb-6">
                You are already connected to {selectedIntegration.name}.
              </p>
            )}
            <button
              onClick={selectedIntegration.connected ? handleDisconnect : () => setIsModalOpen(false)}
              className={`tw-w-full tw-px-4 tw-py-2 tw-mt-4 tw-rounded-md tw-font-medium tw-transition-colors tw-duration-300 ${
                selectedIntegration.connected
                  ? 'tw-bg-red-600 tw-text-white tw-hover:bg-red-700'
                  : 'tw-bg-gray-200 tw-text-gray-800 tw-hover:bg-gray-300'
              }`}
            >
              {selectedIntegration.connected ? 'Disconnect' : 'Cancel'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllIntegration;