import React, { useState,useEffect,useRef } from 'react';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomLoader, exportTableData, formatDate } from '../../../utils/CommonLibrary';
import { fetchMembersProgress, getCareNavigators } from '../../../../services/medengage/Service';


function MemberProgressTable({startDate,endDate}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCare, setSelectedCare] = useState('All');
    const [memberData, setMemberData] = useState([]);
    const [careList, setCareList] = useState([]);
    
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetchMemberProgressData();
    },[startDate,endDate]);

    useEffect(() => {
        fetchCareNavigatorData();
    },[]);

    const fetchMemberProgressData = async () => {
        try {
            fetchMembersProgress(formatDate(startDate),formatDate(endDate))
            .then((response) => {
                setMemberData(response.data.data.users);
                setIsLoading(false);
            });
        } catch (error) {
            console.error(error);
        } 
        finally {
        }
    };

    const fetchCareNavigatorData = async () => {
        try {
            getCareNavigators()
            .then((response) => {
                setCareList(response.data.data.users);
            });
        } catch (error) {
            console.error(error);
        } 
        finally {
            // setIsLoading(false);
        }
    };

    const renderMETP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the member.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the member.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Name of Care Navigator.
        </Tooltip>
    );

    const renderMETP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Enrollment Date.
        </Tooltip>
    );

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Program Name.
        </Tooltip>
    );

    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Condition Name.
        </Tooltip>
    );

    const renderMETP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Pathway Start Date.
        </Tooltip>
    );

    const renderMETP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Initial Pathway Name.
        </Tooltip>
    );

    const renderMETP9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Current Pathway Name.
        </Tooltip>
    );

    const renderMETP10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Pathway Completion Date.
        </Tooltip>
    );

    const renderMETP11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Current Pathway Month.
        </Tooltip>
    );

    const renderMETP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Appointments Count with Care Navigator.
        </Tooltip>
    );

    const renderMETP13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Task Count.
        </Tooltip>
    );

    const renderMETP14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member's Service Count.
        </Tooltip>
    );

    const renderMETP15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Lab Referrals Count.
        </Tooltip>
    );

    const renderMETP19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Completed Lab Referrals Count.
        </Tooltip>
    );

    const renderMETP16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Home Care Referrals Count.
        </Tooltip>
    );

    const renderMETP20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Completed Home Care Referrals Count.
        </Tooltip>
    );

    const renderMETP17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Medications Referrals Count.
        </Tooltip>
    );  

    const renderMETP21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Completed Medications Referrals Count.
        </Tooltip>
    );  

    const renderMETP18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's TeleHealth Referrals Count.
        </Tooltip>
    );  

    const renderMETP22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Member's Completed TeleHealth Referrals Count.
        </Tooltip>
    ); 

    const columns = [
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Member ID</span>
                </OverlayTrigger>
            ),
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP2Tooltip}>
                    <span>Member Name</span>
                </OverlayTrigger>
            ),
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Care Navigator</span>
                </OverlayTrigger>
            ),
            selector: row => row.careName,
            sortable: true,
            width:"100px",
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP4Tooltip}>
                    <span>Enrollment Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.enrollmentTime,
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP5Tooltip}>
                    <span>Program</span>
                </OverlayTrigger>
            ),
            selector: row => row.programName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Condition</span>
                </OverlayTrigger>
            ),
            selector: row => row.conditionName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP7Tooltip}>
                    <span>Pathway Start Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayStartDate,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP8Tooltip}>
                    <span>Initial Pathway</span>
                </OverlayTrigger>
            ),
            selector: row => row.initialPathway,
            sortable: true,
            width:"100px",
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP9Tooltip}>
                    <span>Current Pathway</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayName,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP10Tooltip}>
                    <span>Pathway Completion Date</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayEndDate,
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP11Tooltip}>
                    <span>Pathway Month</span>
                </OverlayTrigger>
            ),
            selector: row => row.pathwayMonth,
            sortable: true,
            width:"100px",
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP12Tooltip}>
                    <span>CareNavigator Appointments Count</span>
                </OverlayTrigger>
            ),
            selector: row => row.totalAppointment,
            sortable: true,
        },
        // {
        //     name: (
        //         <OverlayTrigger placement="top" overlay={renderMETP13Tooltip}>
        //             <span>Task Count</span>
        //         </OverlayTrigger>
        //     ),
        //     selector: row => row.taskCount,
        //     sortable: true,
        // },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP14Tooltip}>
                    <span>Services Count</span>
                </OverlayTrigger>
            ),
            selector: row => row.serviceCount,
            sortable: true,
            width:"100px",
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP15Tooltip}>
                    <span>Lab Refferals</span>
                </OverlayTrigger>
            ),
            selector: row => row.labRefferals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP19Tooltip}>
                    <span>Lab Refferals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.labRefferalsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP16Tooltip}>
                    <span>Home Care Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.homeCareReferrals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP20Tooltip}>
                    <span>Home Care Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.homeCareReferralsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP17Tooltip}>
                    <span>Medication Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.medicationReferrals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP21Tooltip}>
                    <span>Medication Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.medicationReferralsCompleted,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP18Tooltip}>
                    <span>Telehealth Referrals</span>
                </OverlayTrigger>
            ),
            selector: row => row.telehealthReferrals,
            sortable: true,
        },
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP22Tooltip}>
                    <span>Telehealth Referrals Completed</span>
                </OverlayTrigger>
            ),
            selector: row => row.telehealthReferralsCompleted,
            sortable: true,
        },
    ];
    const data = memberData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCare('All');
    };
   
    const filteredData = data.filter(user => {
        const matchesSearchQuery = 
        (typeof user.memberName === 'string' && user.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof user.membershipNo === 'string' && user.membershipNo.toString().includes(searchQuery));
        
        const matchCare = selectedCare === 'All' || user.careName === selectedCare;

        return matchesSearchQuery && matchCare;
    });

    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Member ID' : item.membershipNo,
                'Member Name' : item.memberName,
                'Care Navigator' : item.careName,
                'Enrollment Date' : item.enrollmentTime,
                'Program' : item.programName,
                'Condition' : item.conditionName,
                'Pathway Start Date' : item.pathwayStartDate,
                'Initial Pathway' : item.initialPathway,
                'Current Pathway' : item.pathwayName,
                'Pathway Compltion Date' : item.pathwayEndDate,
                'Pathway Month' : item.pathwayMonth,
                'CareNavigator Appointments Count' : item.totalAppointment,
                'Task Count' : item.taskCount,
                'Services Count' : item.serviceCount,
                'Lab Refferals' : item.labRefferals,
                'Home Care Referrals' : item.homeCareReferrals,
                'Medication Referrals' : item.medicationReferrals,
                'Telehealth Referrals' : item.telehealthReferrals
            };
        });
    
        exportTableData("MemberProgressData",modifiedData);
    };

    return (
        <>
           
            <div className='container-fluid'>
                
                <div className='row mb-3'>
                    
                    <div className='col-md d-flex px-1'>
                    <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search by Member Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://appcdn.goqii.com/storeimg/27564_1721051941.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedCare}
                            onChange={e => setSelectedCare(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Care Navigator--</option>
                            {careList.length > 0 && careList.map(option => (
                                <option key={option.id} value={option.userName}>
                                    {option.userName}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            {isLoading && <CustomLoader /> }
        </>
    );

    
}

export default MemberProgressTable;
