import React, { useState } from 'react';
import './ChallengeReport.css';
import { Link } from 'react-router-dom';

function ChallengeReport() {


  return (
    <>
      <section className='ChallengeReport'>
        <div className="container-fluid px-0 mb-3">
          {/* <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Challenge Report</h1>
            </div>
          </div> */}

          {/* <div className='challenge-report mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/37543_1726209216.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div> */}

          {/* <div className='challenge-report p-4'> */}
            
          <div className="row">
                    <div className="col-md">
                        <label className="form-label sub-title">Start Date</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="Start Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">End Date</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="End Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">Select Report</label>
                            <select className="form-select input-field-ui " id="" name="" aria-label="Default select example">
                            <option value="All">Select Report</option>
                            <option value="Steps">Steps</option>
                            <option value="Steps Milestone">Steps Milestone</option>
                            <option value="Distance">Distance</option>
                            <option value="Calories">Calories</option>
                            <option value="Water">Water</option>
                            <option value="Food">Food</option>
                            <option value="Karma Earned">Karma Earned</option>
                            <option value="Karma Donated">Karma Donated</option>
                            <option value="Activity">Activity</option>
                            <option value="Team Engagement Report">Team Engagement Report</option>
                            <option value="Onboarding Status Report">Onboarding Status Report</option>
                            <option value="Self Challenge Report">Self Challenge Report</option>
                            <option value="App Usage Report">App Usage Report</option>
                            </select>
                        </div>
                        <div className="col-md">
                            <label className="form-label sub-title">Reporting Email</label>
                            <input type="email" className="form-control input-field-ui" id="" placeholder="Enter your Email Id"/>
                        </div>
                </div>

            <div className="row mt-3">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Generate</button> <button type="button" className="btn clear-btn">Cancel</button>
                </div>
            </div>
            
          </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default ChallengeReport;
