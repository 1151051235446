import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load the 3D module
require('highcharts/highcharts-3d')(Highcharts);

const AgeBreakdownColoumn = () => {
    const columnGraphHeight = 180; // Set desired height
    const colCategories = ['', '25 to 35', '36 to 45', 'Above 45']; // Categories from the image
    const yAxisJson = {
        title: {
            text: null // Remove Y-axis title as in the design
        },
        max: 200 // Set max value based on the chart
    };
    const tooltipJson = {
        shared: true,
        useHTML: true
    };

    // Data for the chart
    const columnData = [
        {
            name: '',
            data: [34, 85, 20,10], // Completed calls data
            color: '#f08c44' // Orange color similar to the chart
        },
    ];

    const options = {
        chart: {
            type: "column",
            height: columnGraphHeight,
            marginTop: 40,
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 15,
                depth: 50,
                viewDistance: 0
            },
        },
        title: null,
        subtitle: null,
        xAxis: {
            categories: colCategories,
            gridLineWidth: 0,
            lineWidth: 0,
            labels: {
                style: {
                    fontSize: '12px' // Match font size
                }
            }
        },
        yAxis: yAxisJson,
        tooltip: tooltipJson,
        plotOptions: {
            column: {
                depth: 0, 
                stacking: "normal",
                dataLabels: {
                    enabled: true,
                    verticalAlign: 'top', // Position labels at the top
                    align: 'center', // Center align the labels
                    y: -20,
                    style: {
                        textOutline: 'none',
                    }
                },
            },
        },
        legend: {
            enabled: false 
        },
        series: columnData,
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen', 
                        'printChart', 
                        'separator', 
                        'downloadPNG', 
                        'downloadJPEG', 
                        'downloadPDF', 
                        'downloadSVG'
                    ],
                },
            },
            menuItemDefinitions: {
                viewFullscreen: {
                    onclick: function () {
                        this.fullscreen.toggle();
                    },
                },
                printChart: {
                    onclick: function () {
                        this.print();
                    },
                },
                downloadPNG: {
                    onclick: function () {
                        this.exportChart({
                            type: 'image/png',
                            filename: 'gender-distribution-chart',
                        });
                    },
                },
                downloadJPEG: {
                    onclick: function () {
                        this.exportChart({
                            type: 'image/jpeg',
                            filename: 'gender-distribution-chart',
                        });
                    },
                },
                downloadPDF: {
                    onclick: function () {
                        this.exportChart({
                            type: 'application/pdf',
                            filename: 'gender-distribution-chart',
                        });
                    },
                },
                downloadSVG: {
                    onclick: function () {
                        this.exportChart({
                            type: 'image/svg+xml',
                            filename: 'gender-distribution-chart',
                        });
                    },
                },
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AgeBreakdownColoumn;
