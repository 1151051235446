import React, { useState } from 'react';
import './EmployeeCommunications.css';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import EmailCampaigns from './EmailCampaigns';
import SMSCampaigns from './SMSCampaigns';
import PushNotifications from './PushNotifications';
import Smartcards from './Smartcards';
import BlockerPopUps from './BlockerPopUps';

function EmployeeCommunications() {


  return (
    <>
      <section className='EmployeeCommunications'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Communications</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-employee-communications">Create Email Campaigns</Link></div> */}
          </div>

          <div className='employee-communications mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/49850_1726645083.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo. Vivamus et interdum felis, ut vulputate tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis turpis quis suscipit commodo.</p>
              </div>
            </div>
          </div>

          <div className='employee-communications'>
            
            <div className="row">
                <div className="col-md-12">
                    <Tabs>
                        <TabList>
                        <Tab>Email Campaigns</Tab>
                        <Tab>SMS Campaigns</Tab>
                        <Tab>Push Notifications</Tab>
                        <Tab>Smartcards</Tab>
                        <Tab>Blocker Pop ups</Tab>
                        </TabList>

                        <TabPanel>
                           <EmailCampaigns/>
                        </TabPanel>

                        <TabPanel>
                           <SMSCampaigns/>
                        </TabPanel>

                        <TabPanel>
                           <PushNotifications/>
                        </TabPanel>

                        <TabPanel>
                           <Smartcards/>
                        </TabPanel>

                        <TabPanel>
                           <BlockerPopUps/>
                        </TabPanel>
                    </Tabs>
                    
                    </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default EmployeeCommunications;
