import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { add_new_services, fetch_pathway_frequency_data, fetch_program_data, fetch_service_data } from '../../../services/mtp/Service';
import { Commingsoon, LoaderLoader, ProgramDots,PathwayDots } from "../../utils/CommonLibrary";
import 'react-dropdown-tree-select/dist/styles.css';
import DropDownAction from "./DropDownAction";


function ContentProgramsList() {
    const [pathwayName, setPathwayName] = useState("");
    const [pathwayPopupData, setPathwayPopupData] = useState([]);
    const [servicePopupData, setServicePopupData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pathwayId, setPathWayId] = useState(null);
    const [showhide, setShowHide] = useState(true);
    

    const pathwayModelPopup = async (pathwayId) => {
        try {
            setIsLoading(true); //to show loader
            let response = await fetch_pathway_frequency_data(pathwayId);
            if (response.data.code === 200) {
                console.log(response.data.data);         
                setPathwayName(response.data.pathwayName);
                setPathWayId(pathwayId);
                setPathwayPopupData(response.data.data);
                setServicePopupData(response.data.data[0].serviceData);
                setShowHide(false);  
            }
            setIsLoading(false); //to hide loader
           
        } catch (error) {
            console.error(error);
        }
    }

    // const servicesModelPopup = async (frequencyId) => {
    //     try {
    //         fetchServiceData();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    useEffect(() => {
        fetchProgramData();
        fetchServiceData();
    }, []);

    //To get program data list
    const fetchProgramData = async () => {
        try {
            let resp = await fetch_program_data();
            setIsLoading(true); //to show loader
            if (resp.data.code === 200) {
                setProgramData(resp.data.data);
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }

    //To get service data list
    const fetchServiceData = async () => {
        try {
            setIsLoading(true); //to show loader
            let result = await fetch_service_data();
            if (result.data.code === 200) {
                setServiceData(result.data.data);
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }

    // To add new service 
    const AddNewServices = async ()=>{
        try {

            setIsLoading(true); //to show loader
            let result = await add_new_services();
            if (result.data.code === 200) {
                setServiceData(result.data.data);
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }
    const pageReverse = () =>{
        setShowHide(true); 
    }
    return (
        <>
            <div className="container-fluid px-0 mb-3">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md"> 
                        <h4 className="mb-0 content-main-title">Programs</h4>
                    </div>
                    <div className="col-md text-end">
                        <Link to="" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal" className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add new Program
                        </Link>
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/Program.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">The Care Program module helps you effortlessly create new Care programs, add conditions, pathways, and services to the pathway. This is essential for enabling our Super Care service.</p>
                            <p>
                            <img src="https://appcdn.goqii.com/storeimg/52208_1719307659.png" alt="Invited Members icon" style={{height:"100px"}}/>
                            </p>
                        </div>
                    </div>
                </div>
                {showhide ? (
                <div className='content-programs mb-3'>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="body-tree-view">
                        <LoaderLoader isLoading={isLoading} />
                        {/* Program 1 */}
                        {programData.map((itemFirst, index) => (
                            <>
                        <details open key={index}>
                            <summary className="d-flex align-items-center">
                                {itemFirst.programName}
                                <ProgramDots/>
                            </summary>
                            
                            {itemFirst.children ? ( 
                                        <>
                            {itemFirst.children.map((itemSecond, index) => (
                            <details open>
                                <summary className="d-flex align-items-center">
                                    {itemSecond.programName}
                                    <DropDownAction/>
                                </summary>
                                <ul>
                                    {itemSecond.children ? ( 
                                        <>
                                        {itemSecond.children.map((itemThird, index) => (
                                                <>
                                                {itemThird.pathwayCount ? (
                                                    <>
                                                     <li className="d-flex align-items-center"><span onClick={() => pathwayModelPopup(itemThird.programId)}>{itemThird.programName}</span> <PathwayDots pathway = {itemThird.programId}/></li>
                                                    </>

                                                ): (

                                                    <>
                                                    <li className="d-flex align-items-center"><Link to={`/add-service/${itemThird.programId}`} style={{textDecoration:'none'}}>{itemThird.programName}</Link> <PathwayDots pathway = {itemThird.programId}/></li>
                                                    </>

                                                )}
                                                </>
                                                
                                        ))}
                                        </> 
                                        ) : ('') }
                                </ul>
                            </details>
                            ))}
</>
                            ) : ("") }
                            
                            </details>
                            </>
                        )
                    )}
                    
                    </div>
                        
                        </div>
                    </div>
                </div>
                ):("")}
                { pathwayName && !showhide ? (<>
                <div className='content-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-auto mt-2 px-4">
                            <h3 className="btn btn-primary back-btn mb-0" onClick={pageReverse}><i className="bi bi-chevron-left me-1"></i> Back</h3>
                        </div>
                        <div className="col mt-2 px-0 align-items-center">
                            <h3 className="main-title mb-0">{pathwayName}</h3>
                        </div>
                        <div className="col-auto mt-2 px-4">
                        {/* <a data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal" className="btn btn-primary devision-add-btn" href="/programs"><i className="bi bi-plus-circle me-2"></i> Add</a> */}
                        <Link to={`/add-service/${pathwayId}`} className="btn btn-primary devision-add-btn" ><i className="bi bi-plus-circle me-2"></i> Edit</Link>
                        </div>

                        <div className="col-md-12 mt-2 px-4">
                            <div className="table-responsive pathways-new-table">
                            <table className="table table-striped mb-0 mt-2">
                                            <tbody>    
                                            {pathwayPopupData.map((item, indexth) => (
                                                    <tr>
                                                        <td key={indexth}>
                                                            <h5 className="main-pathways-title">{item.month} Month</h5>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                             {item.servicesName}   
                                                            {/* {item.serviceData.map((option, index) => ( 
                                                            
                                                                <span  key={index}>{option.serviceName}</span>
                                                                
                                                            
                                                            ))} */}
                                                            </td>
                                                        {/* <td className="text-end">
                                                            <font data-bs-toggle="modal" data-bs-target="#pathwaysProgramsModal" className="btn btn-primary program-add-btn" onClick={() => servicesModelPopup(pathwayData.frequencyId)}><i className="bi bi-plus-circle me-2" style={{textDecoration:'none'}}></i> Add</font>
                                                        </td> */}
                                                    </tr>
                                            ))}
                                                    
                                            </tbody>
                                        </table>
                            </div>
                        </div>
                       

                    </div>
                </div>
                </>) : ("")}
            </div>


            <div className="modal fade" id="pathwaysModal" tabindex="1" aria-labelledby="pathwaysModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-subtitle">Duration</h4>
                            <h1 className="modal-title">{pathwayName}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {pathwayPopupData.map((item, index) => (
                                        <>
                                            <h3 className='text-start pathways-box' key={index}>{item.month} Month</h3>
                                            <div className="row px-2 mb-3">
                                                {/* {item.serviceData.map((option, index) => (
                                                    <>
                                                        <div className="col-md-6 my-2" key={index}><img src={require("../../../images/icons-doctor.png")} className="table-icon" alt="" />{option.serviceName}</div>
                                                    </>
                                                ))} */}
                                                <div className="col-md-6 my-2" key={index}><img src={require("../../../images/icons-doctor.png")} className="table-icon" alt="" />{item.serviceName}</div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Commingsoon />
            
            <div className="modal fade" id="pathwaysProgramsModal" tabindex="1" aria-labelledby="pathwaysProgramsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">Add Services</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 px-4">
                                {serviceData.map((service, index) => (
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" value="" id="ServicesCheck1"/>
                                        <label className="form-check-label" htmlFor="ServicesCheck1">
                                            {service.serviceName}
                                        </label>
                                    </div>
                                ))}
                                </div>
                                <div className="col-md-12 px-4 mb-3">
                                <textarea className="form-control input-field-ui mb-1" id="exampleFormControlTextarea1" rows="2"></textarea>
                                <font className="btn btn-primary services-add-btn"><i className="bi bi-plus-circle me-2" onClick={AddNewServices} ></i> Add Service</font>
                                </div>

                                <div className="col-md-12 px-4 my-2">
                                 <button type="submit" className="btn btn-primary program-save-btn">Save</button> <button type="submit" className="btn btn-primary clear-btn">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContentProgramsList;
