import React, { useState } from 'react';
import './CreateGroups.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

function CreateGroups() {

  const handleBackButton = () => {
    navigate('/corporates-detail');
    }

    const navigate = useNavigate();
    
  return (
    <>
      <section className='CreateGroups'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                  <h1 className="main-title" style={{ margin: '0' }}>Create Groups</h1>
              </div>
              {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testPushSmartcards">Test Smartcards</Link></div> */}

          </div>

          <div className='create-groups p-4'>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Add Group Name <span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Group Name"/>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Add Group Description <span className='text-danger'>*</span> </label>
                    <textarea rows="4" type="text" className="form-control input-field-ui" id="" placeholder="Enter Group Description"></textarea>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                  <div className='row'>
                    <label className="form-label sub-title">Add Users <span className='text-danger'>*</span> </label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Image Url"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload (csv file)</button>
                    </div>
                    <label className="form-label mb-0 mt-2 sub-title-1">Upload (csv file)</label>
                  </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Submit</button> <button type="button" className="btn clear-btn" >Cancel</button>
                </div>
            </div>
            
          </div>
        </div>
      </section>

    </>
  );
}

export default CreateGroups;
