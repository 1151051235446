import React from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load the 3D module
require('highcharts/highcharts-3d')(Highcharts);

const HealthGoalsChart = () => {
    const columnGraphHeight = 380; // Adjust height to match design
    const colCategories = [
        'Weight Loss',
        'Weight/Muscle Gain',
        'Fat Loss',
        'Fit & Active',
        'Reduce Stress',
        'Improve Sleep',
        'Fitness Event Training',
        'Manage Illness',
        'Eat Healthy',
        'Others'
    ]; 
    const yAxisJson = {
        title: {
            text: null // Remove Y-axis title
        },
        // max: 180 // Adjust max value based on the chart
    };

    const columnData = [
        {
            name: 'People',
            data: [121, 155, 35, 47, 121, 160, 35, 47, 121, 61],
            color: '#00398a'
        }
    ];

    const optionsSL = {
        chart: {
            animation: false,
            type: 'bar',
            height: columnGraphHeight,
            
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: colCategories,
            labels: {
                rotation: 0,
                style: {
                    fontSize: '14px',
                    fontWeight: 400
                }
            },
            gridLineWidth: 0,
            tickmarkPlacement: 'on', // Ensure ticks are aligned with categories
            tickInterval: 1 // Set interval for ticks
        },
        series: columnData,
        credits: {
            enabled: false
        },
        yAxis: {
            tickInterval: 20,
            gridLineWidth: 1,
            startOnTick: false,
            min: 0,
            // max: 180,
            title: {
                text: ''
            },
            labels: {
                enabled: true // Enable Y-axis labels
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#eee' // Color of the X-axis line
            }]
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                return `<b>${this.x}</b>: ${this.y}`;
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        plotOptions: {
            series: {
                animation: false,
                stacking: 'normal',
                showInLegend: false,
                pointPadding: 0.1,
                pointWidth: 30,
                dataLabels: {
                    enabled: true,
                    align: 'right',
                    inside: true,
                    // x: 5,
                    style: {
                        textOutline: 'none',
                    }
                },
                // borderRadius: 10,
            }
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={optionsSL} />;
};

export default HealthGoalsChart;
