import React, { useState, useEffect } from 'react';
import { FcApproval, FcHighPriority } from 'react-icons/fc';

const Modal = ({ title, content, action, isOpen, onClose, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  if (!isModalOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-overflow-y-auto tw-h-full tw-w-full tw-z-50" id="modal-overlay">
      <div className="tw-relative tw-top-40 tw-mx-auto tw-p-5 tw-border tw-w-96 tw-shadow-lg tw-rounded-md tw-bg-white">
        <div className="tw-mt-3">
          <button
            onClick={closeModal}
            className="tw-absolute tw-right-2 tw-top-2 tw-text-gray-500 hover:tw-text-gray-800"
          >
            ✕
          </button>
          {action && (
            <div className="tw-text-center">
              {action === 'success' ? (
                <FcApproval className="tw-text-7xl tw-mx-auto tw-animate-pulse" />
              ) : (
                <FcHighPriority className="tw-text-7xl tw-mx-auto tw-animate-bounce" />
              )}
            </div>
          )}
          <h3 className="tw-text-2xl tw-text-center tw-font-bold tw-text-gray-900 tw-mb-4">{title}</h3>
          {content && (
            <div className="tw-mt-2 tw-mb-4">
              <p className="tw-text-lg tw-text-gray-500 tw-text-center">{content}</p>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;