import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load the 3D module
require('highcharts/highcharts-3d')(Highcharts);

const HRAPieChart = () => {
    const pieGraphHeight = 180; 

    const pieData = [{
        name: 'Optimum:',
            y: 24,
            color: '#1b883c',  // Blue color for male
        }, {
            name: 'Healthy',
            y: 73,
            color: '#0079c8',  // Pink color for female
        }, {
            name: 'Borderline',
            y: 43,
            color: '#f1c22d',  // Pink color for female
        }, {
            name: 'High Risk',
            y: 7,
            color: '#d60023',  // Pink color for female
        }
    ];

    const optionsHra = {
        chart: {
            type: 'pie',
            height: pieGraphHeight,
            options3d: {
                enabled: true,
                alpha: 70,
                beta: 0
            },
        },
        title: null,
        subtitle: null,
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            pointFormat: '{point.name}: <b>{point.y}</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                depth: 35,
                size: 200,
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            symbolHeight: 12,
            symbolRadius: 20,
            itemStyle: {
                fontSize: '12px',
                fontWeight: 'normal'
            },
            labelFormatter: function () {
                return `${this.name}: ${this.y}`;
            }
        },
        series: [{
            name: 'Gender',
            colorByPoint: true,
            data: pieData,
        }],
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen', 
                        'printChart', 
                        'separator', 
                        'downloadPNG', 
                        'downloadJPEG', 
                        'downloadPDF', 
                        'downloadSVG'
                    ],
                },
            },
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={optionsHra} />
        </div>
    );
};

export default HRAPieChart;
