import React, { useState } from 'react';
import { FaDropbox, FaGoogleDrive } from 'react-icons/fa';

const storageServices = [
  { 
    name: 'Dropbox', 
    icon: FaDropbox, 
    description: 'Cloud storage and file synchronization service for secure file sharing and collaboration.',
  },
  { 
    name: 'Google Drive', 
    icon: FaGoogleDrive, 
    description: 'Cloud-based file storage and synchronization service for easy access and sharing of documents.',
  },
];

const StorageCard = ({ name, Icon, description, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-start">
      <div className="tw-w-20 tw-h-20 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-5xl" />
      </div>
      <div>
        <h3 className="tw-text-xl tw-font-bold tw-text-[#03335b] tw-mb-2">{name}</h3>
        <p className="tw-text-sm tw-text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const Storage = () => {
  const [activeService, setActiveService] = useState(null);

  return (
    <div className="tw-bg-gray-50 tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <p className="tw-text-gray-600 tw-mb-6">
        Integrate cloud storage solutions to securely store, manage, and share files within your healthcare platform.
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
        {storageServices.map((service, index) => (
          <StorageCard
            key={service.name}
            name={service.name}
            Icon={service.icon}
            description={service.description}
            isActive={index === activeService}
            onClick={() => setActiveService(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Storage;