import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHoldingCircle, faSuitcaseMedical, faHeartPulse, faUserShield } from '@fortawesome/free-solid-svg-icons';


import './InsurePlusCovered.css';

function InsurePlusCovered() {
  const corporateinsurePlusCover = [
    {
        corpinsurePlusCoverBg: '#FDA4B1',
        corpLabelinsurePlusCover: 'Players Covered',
        corpCountinsurePlusCover: '73',
        corpIconinsurePlusCover: faHandsHoldingCircle,
    },
    {
        corpinsurePlusCoverBg: '#D7EFCC',
        corpLabelinsurePlusCover: 'Current Health Cover',
        corpCountinsurePlusCover: '534',
        corpIconinsurePlusCover:faSuitcaseMedical,
    },
    {
        corpinsurePlusCoverBg: '#C6DCF1',
        corpLabelinsurePlusCover: 'Life Insurance',
        corpCountinsurePlusCover: '468',
        corpIconinsurePlusCover:faHeartPulse,
    },
    {
        corpinsurePlusCoverBg: '#F6EFD2',
        corpLabelinsurePlusCover: 'Claim Status',
        corpCountinsurePlusCover: '211',
        corpIconinsurePlusCover: faUserShield,
    },
  ];

  return (
    <>
      {corporateinsurePlusCover.map((statP, index) => (
        <div 
          className="box1insurePlusCover col-md" 
          style={{ backgroundColor: statP.corpinsurePlusCoverBg }} 
          key={index}
        >
              {/* <img src={statP.corpIconinsurePlusCover} className="css-ImgIcon" alt="" /> */}
              <FontAwesomeIcon icon={statP.corpIconinsurePlusCover} className="css-SvgIconNew"/>
                <h1 className="insurePlusCover-title">
                   {statP.corpCountinsurePlusCover}
                </h1>
                <h2 className="insurePlusCover-subtitle">{statP.corpLabelinsurePlusCover}</h2>
              </div>
      ))}
    </>
  );
}

export default InsurePlusCovered;
