import React,{ useState, useRef, useEffect } from "react";
import { fetch_ad_users_list, fetch_program_condition, createCareNavigator, fetchCareNavigator, updateCareNavigator, updateCareNavigatorMultiPart, removeCareNavigatorCertification } from "../../../services/medengage/Service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TreeDropdown from "../../utils/TreeDropdown";
import './Dropdown.css';
import { MultiSelect } from 'react-multi-select-component';
import { validateFormCareAdd } from "../../utils/validationUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomFieldLevelLoader, LoaderLoader } from "../../utils/CommonLibrary";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NotificationToast, showToast } from "./NotificationToast";
import { toast } from "react-toastify";

function AddNewCareNavigator() {

    const fileInputRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [count, setCount] = useState(1);
    const [inputs, setInputs] = useState([{ text: '', file: null }]);
    const [fetchInputs, setFetchInputs] = useState([{ fileId: '', documentName: '', fileUrl: '', actualFileName: '' }]);
    const [errorMessage, setErrorMessage] = useState('');
    const [fileUploadError, setFileUploadError] = useState('');    
    const [profilePicPathFile, setProfilePicPathFile] = useState(null)
    const [programDataError, setProgramDataError] = useState(false);   
    const [isSubmitting, setIsSubmitting] = useState(false)
    
    const [formData, setFormData] = useState({
        imagePath: 'https://appcdn.goqii.com/storeimg/81897_1719660486.png',
        // imagePath: '',
        firstName: '',
        lastName: '',
        emailId: '',
        gender: '',
        // dob: '',
        mobileNumber : '',
        language: '',
        phe: '', 
        yourSelf: '',
        // activities: '',
        skils: '',
    });
    const [errors, setErrors] = useState({
        imagePath: '',
        firstName: '',
        lastName: '',
        emailId: '',
        gender: '',
        // dob: '',
        mobileNumber : '',
        language: '',
        phe: '',
        yourSelf: '',
        // activities: '',
        skils: '',
        roleId:'',
      }); 
    
      const[programConditionFilter,setProgramConditionFilter] = useState({program:[],condition:[]});
      const [programConditionData, setProgramConditionData] = useState([]);
      const { id } = useParams();
      const routeName = useLocation();

      const action = routeName.pathname.includes("edit") ? "edit" : "create";
      const [isLoading, setIsLoading] = useState(false)
      const navigate = useNavigate();

      const fetchCareNavigatorData = async (id) => {
        setIsLoading(true);
        try {
            const payload = {
                careNavigatorId: id
            }
            const response = await fetchCareNavigator(payload);

            if (response.data.code === 200) {

                const selectedLanguages = response.data.data.language.split(',').map(lang => ({
                    value: lang.trim(),
                    label: lang.trim()
                }));
                setSelectedOptions(selectedLanguages);
                
                let programsResponseData = response.data.data.programs;
                if (programsResponseData.length > 0) {
                    setProgramConditionData(response.data.data.programs);
                } else {
                    fetchDivsionPractice();
                }

                setFetchInputs(response.data.data.certifications);

                let data = response.data.data;
                delete data.certifications;
                delete data.programs;
                setFormData(data);
            }

            setIsLoading(false);
        } catch(e) {
            setIsLoading(false);
            console.log(e);
        }
      }

      useEffect(() => {
        if (action === 'edit') {
            fetchCareNavigatorData(id);
        }
      }, [id])
  
      useEffect(() => {
            if (action === 'create') {
                fetchDivsionPractice();
            }
        }, []);


        useEffect(() => {
            if(id != '' && id !== undefined){
                // loadUserData();
            }else{
                if (action === 'create') {
                    fetchDivsionPractice();
                }
            }
            
        },[]);
  
        let programArr=[];let conditionArr=[];
      const assignObjectPaths = (obj, stack) => {
          
          Object.keys(obj).forEach(k => {
              const node = obj[k];
              if(node.type=='parent'){
                  programArr = appendUniqueValue(programArr,node.value);
              }
              if(node.type=='child'){
                  conditionArr = appendUniqueValue(conditionArr,node.value);
              }
              
              if (typeof node === "object") {
              node.path = stack ? `${stack}.${k}` : k;
              assignObjectPaths(node, node.path);
              }
          });
          
      };
      function appendUniqueValue(arr, value) {
          const existingIndex = arr.findIndex(item => item === value);
          if (existingIndex !== -1) {
            arr[existingIndex] = value;
          } else {
            arr.push(value);
          }
          return arr; 
        }
      const fetchDivsionPractice = async () => {        
      await fetch_program_condition()
          .then((res) => {
              assignObjectPaths(res.data.data);
              setProgramConditionData(res.data.data);
          });
      }
     
  
      
  
      const updateParentChildDropdown = (index, checkedValue,type,childindex) => {
          if(type=='parent'){
              
              const chld = programConditionData[index].children.map((child)=> {
                  if(checkedValue){
                      setProgramConditionFilter(prevState => ({...prevState,condition: [...prevState.condition, child.value] }));
                  }else{
                      setProgramConditionFilter(prevState => {
                          const updatedClinic = prevState.condition.filter(d => d !== child.value);
                          return { ...prevState, condition: updatedClinic };
                        });
                  }
                  
              });
  
              const updatedData = programConditionData.map((parent, parentIndex) => {
                      if (parentIndex == index) {
                          const updatedChildren = parent.children.map((child) => ({
                          ...child,
                          checked: checkedValue,
                          }));
   
                          return {
                          ...parent,
                          checked: checkedValue,
                          expanded: false,
                          children: updatedChildren,
                          };
                      }else{
                          return {
                              ...parent,
                              expanded: false,
                          };
                      }
                      
              });
              setProgramConditionData(updatedData); // Update the state with the modified array
          }else{
              if (type == 'child') {
                  const updatedData = [...programConditionData]; // Create a copy of the original data
                  updatedData[index].children[childindex].checked = checkedValue; 
                  
                  const allChecked = updatedData[index].children.every(child => child.checked);
                  const allUnchecked = updatedData[index].children.every(child => !child.checked);
                  const atLeastOneChecked = updatedData[index].children.some(child => child.checked);
                  const atLeastOneUnchecked = updatedData[index].children.some(child => !child.checked);
  
                  const uncheckedChild = updatedData[index].children.find(child => !child.checked);
                  const allChildrenUnchecked = updatedData[index].children.every(child => !child.checked);
                  if(atLeastOneChecked){
                      pushToDivision(updatedData[index].value);
                  }
                  if(allChildrenUnchecked){
                      setProgramConditionFilter(prevState => {
                              const updatedDivision = prevState.program.filter(d => d !== updatedData[index].value);
                              return { ...prevState, program: updatedDivision };
                            });
                  }
                  if(uncheckedChild){//mark parent
                      updatedData[index].checked = false;
                  }else{
                      updatedData[index].checked = true;
                  }
                  const updatedData1 = updatedData.map((item, i) => {
                      if (index == i) { // Skip updating 'expanded' for key 3 node (index 2)
                          return { ...item, expanded: true };
                      } else {
                        return { ...item, expanded: false };
                      }
                    });
                  
                  setProgramConditionData(updatedData1); 
              }
          }
      };
      const pushToDivision = (value) => {
          setProgramConditionFilter(prevState => {
            if (!prevState.program.includes(value)) {
              return {
                ...prevState,
                program: [...prevState.program, value]
              };
            }
            return prevState;
          });
        };
      const getDropdownData = (currentNodeLabel,currentNode) => {
          const currentVal = currentNode.value;
          const currentPath = currentNode.path;
  
          if(currentNodeLabel=='parent'){
              if(currentNode.checked){
                  updateParentChildDropdown(currentPath, true,'parent','');
                  pushToDivision(currentVal);
              }else{
                  updateParentChildDropdown(currentPath, false,'parent','');
                  setProgramConditionFilter(prevState => {
                      const updatedDivision = prevState.program.filter(d => d !== currentVal);
                      return { ...prevState, program: updatedDivision };
                    });
              }
          }
  
          if(currentNodeLabel=='child'){
              let pathSplit = currentPath.split('.');
              let parentKey = pathSplit[0];
              let childKey = pathSplit[2];
              if(currentNode.checked){
                  updateParentChildDropdown(parentKey, true,'child',childKey);
                  setProgramConditionFilter(prevState => ({...prevState,condition: [...prevState.condition, currentVal] }));
              }else{
                  updateParentChildDropdown(parentKey, false,'child',childKey);
                  setProgramConditionFilter(prevState => {
                      const updatedClinic = prevState.condition.filter(d => d !== currentVal);
                      return { ...prevState, condition: updatedClinic };
                    });
                  
              }
          }
        };


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFormData((prevData) => ({ ...prevData, imagePath: reader.result }));
          };
          reader.readAsDataURL(file);

          setProfilePicPathFile(file);
          setErrors((prev) => ({ ...prev, imagePath: ''}));
        } else {
          alert('Please select a valid image file.');
          
        }
    };
    
    const handleOnTypingEmail = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
       if (!value) {
          setFormData((prevData) => ({
            ...prevData,
            firstName: '',
            lastName: '',
          }));
        //   setIsLoading(false);
        setLoading(false)
           setSuggestions([]);
        }
        // Fetch suggestions from backend
        if (value) {
          try {
            // setIsLoading(true);
            setLoading(true)
            fetch_ad_users_list(value)
            .then((response) => {
                setSuggestions(response.data.data);
                // setIsLoading(false); 
                setLoading(false)
            });
          } catch (error) {
            console.error('Error fetching email suggestions', error);
          }
        } else {
          setSuggestions([]);
        //   setIsLoading(false); 
        setLoading(false)
        }
        setErrors((prev) => ({ ...prev, emailId: ''}));
      };

    const handleSuggestionClick = (suggestion) => {
        setFormData((prevData) => ({
            ...prevData,
            emailId: suggestion.mail,
            firstName: suggestion.givenName || '', // Assuming givenName is present in the suggestion
            lastName: suggestion.surname || '', // Assuming surname is present in the suggestion
        }));

        setSuggestions([]); // Clear suggestions after selection
        setErrors((prev) => ({ ...prev, emailId: '', firstName: '', lastName: ''}));
    };

    const handleDateChange = (date) => {
        let month = date.getMonth() + 1;
        let selectedDate = date.getFullYear() + '/' + ((month < 10) ? '0' + month : month) + '/' + date.getDate();
        setFormData((prevData) => ({ ...prevData, dob: selectedDate }));
    };

    const onValueChange = (e) => {
        const { name, value } = e.target;
        
        // Regular expression for numbers only
        const regExpName = /^[0-9]*$/;
    
        if (name === 'mobileNumber') {
            // Allow empty value to be set (to support backspace to empty)
            if (value === '' || (regExpName.test(value) &&  value.length <= 13)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }

        if (value) {
            setErrors((prev) => ({ ...prev, [name]: ''}));
        }
    };

    const options = [
        { value: 'Arabic', label: 'Arabic' },
        { value: 'English', label: 'English' },
        { value: 'French', label: 'French' }
    ];

    const handleChange = (selected) => {
        setSelectedOptions(selected);
        const selectedValues = selected.map(option => option.value).join(',');
        setFormData({...formData, language: selectedValues});
        setErrors((prev) => ({ ...prev, language: ''}));
      };

      const handleAddFields = () => {
        setInputs([...inputs, { text: '', file: null }]);
      };
    
      const handleRemoveFields = (index, type) => {
        if (type === 'removePerm') {    
            removeCertification(index);
        } else if (type === 'removeTemp') {
            const newInputs = [...inputs];
            newInputs.splice(index, 1);
            setInputs(newInputs);
        }
      };

      const removeCertification = async (id) => {
        try {
            const payload = {
                fileId: id
            }
            const response = await removeCareNavigatorCertification(payload);

            if (response.data.code === 200) {
                alert(response.data.message);
                setFetchInputs((prev) => prev.filter(prev => prev.fileId !== id));
            }
        } catch(error) {
            console.log(error);
        }
      }

      const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...inputs];
        newInputs[index][name] = value;
        setInputs(newInputs);
      };
    
      const handleFileChangeNew = (index, event) => {
        const file = event.target.files[0];
        const newInputs = [...inputs];
        newInputs[index]['file'] = file;
        setInputs(newInputs);
      };

      const handleRadioChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            setFormData((prev) => ({
                ...prev,
                gender: value
            }));
            setErrors((prev) => ({ ...prev, gender: ''}));
        }
      }

    const submitFinalData = async (e) => {
        e.preventDefault();

    
        setErrorMessage('');
        const validationErrors = validateFormCareAdd(formData);
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }else{

                if (action === 'edit') {

                    const hasError = fetchInputs.length < 1 && (inputs.some(input => !input.text && !input.file));
                    if (hasError) {
                        setFileUploadError(true);
                        return;
                    }
    
                    const formDataNew = new FormData();

                    formDataNew.append('careNavigatorId', parseInt(id));

                    if (!formData.imagePath.includes("http")) {
                        delete formData.imagePath;
                    }
                    formDataNew.append('formData', JSON.stringify(formData));

                    const updatedProgramData = [];
                    programConditionData.forEach((parent) => {
                      parent.children.forEach((child) => {
                        if (child.checked) {
                          updatedProgramData.push({
                            programId: parent.value,
                            conditionId: child.value,
                          });
                        }
                      });
                    });

                    const hasErrorProgramData = updatedProgramData.length < 1;
                    if (hasErrorProgramData) {
                        setProgramDataError(true);
                        return;
                    }

                    formDataNew.append('programConditionFilter', JSON.stringify(updatedProgramData));
                    
                    if (inputs.some(input => input.text !== null && input.file !== null) || profilePicPathFile !== null) {

                        if (inputs.some(input => input.text !== null && input.file !== null)) {
                            inputs.forEach(({ text, file }, index) => {
                                formDataNew.append(`texts[${index}]`, text);
                                formDataNew.append(`files[${index}]`, file);
                                });
                        }

                        if (profilePicPathFile !== null) {
                            formDataNew.append('imagePathFile', profilePicPathFile);
                        }

                        setIsSubmitting(true)
                        await updateCareNavigatorMultiPart(formDataNew)
                        .then((res) => {
                            toast.success(res.data.message, {
                                position: 'top-right',
                                onClose: () => {
                                    navigate('/care-navigator');
                                    setIsSubmitting(false)
                                }
                            })
                        })
                        .catch((err) => {
                            showToast(err.data.message, 'error', 'top-right');
                        });
                    } else {
                        const payload = {
                            careNavigatorId: id,
                            formData: JSON.stringify(formData),
                            programConditionFilter: JSON.stringify(updatedProgramData)
                        };  
                        setIsSubmitting(true);
                        await updateCareNavigator(payload)
                            .then((res) => {
                                // setIsLoading(true)
                                toast.success(res.data.message, {
                                    position: 'top-right',
                                    autoClose: 1000,
                                    onClose: () => {
                                        navigate('/care-navigator');
                                        setIsSubmitting(false);
                                    }
                                })
                            })
                            .catch((err) => {
                                showToast(err.data.message, 'error', 'top-right');
                            });
                    }
                } else {

                    const formDataNew = new FormData();

                    // if (!formData.imagePath.includes("http")) {
                    //     delete formData.imagePath;
                    // }

                    if (profilePicPathFile !== null) {
                        formDataNew.append('imagePathFile', profilePicPathFile);
                    }
                    
                    formDataNew.append('formData', JSON.stringify(formData));
                    // formDataNew.append('programConditionFilter', JSON.stringify(programConditionFilter));

                    const updatedProgramData = [];
                    programConditionData.forEach((parent) => {
                      parent.children.forEach((child) => {
                        if (child.checked) {
                          updatedProgramData.push({
                            programId: parent.value,
                            conditionId: child.value,
                          });
                        }
                      });
                    });

                    const hasErrorProgramData = updatedProgramData.length < 1;
                    if (hasErrorProgramData) {
                        setProgramDataError(true);
                        return;
                    }

                    formDataNew.append('programConditionFilter', JSON.stringify(updatedProgramData));

                    setFileUploadError(false);
                    const hasError = inputs.some(input => !input.text || !input.file);
                    if (hasError) {
                        setFileUploadError(true);
                        return;
                    }

                    inputs.forEach(({ text, file }, index) => {
                        formDataNew.append(`texts[${index}]`, text);
                        formDataNew.append(`files[${index}]`, file);
                        });

                        setIsSubmitting(true);
                    await createCareNavigator(formDataNew)
                        .then((res) => {
                            if (res.data.code === 200) {
                                toast.success(res.data.data.message, {
                                    position: 'top-right',
                                    autoClose: 1000,
                                    onClose: () => {
                                        navigate('/care-navigator');
                                        setIsSubmitting(false);
                                    }
                                });                                
                            } else {
                                if (res.data.code === 403) {
                                    setIsSubmitting(false);
                                }
                                showToast(res.data.data.message, 'error', 'top-right');
                            }
                        })
                        .catch((err) => {
                            showToast(err.data.message, 'error', 'top-right');
                        });
                }
            }
    }

    const setMaxDate = () => {
        var d = new Date();
        d.setFullYear(d.getFullYear() - 20);
        return d;
    }    

    const handleBackButton = () => {
        navigate('/care-navigator');
    }

    const handleClearForm = () => {
        setProfilePicPathFile(null);
        // setProgramConditionData([]);
        setSelectedOptions([]);
        setInputs([{ text: '', file: null }]);
        setFormData({
            imagePath: '',
            firstName: '',
            lastName: '',
            emailId: '',
            // dob: '',
            mobileNumber : '',
            language: '',
            phe: '', 
            yourSelf: '',
            // activities: '',
            skils: '',
        });
    }

    return (
        <>
        <section className='ContentCareNavigator'>
        <NotificationToast />
            <div className="container-fluid px-0">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    {/* <IconButton onClick={handleBackButton}>
                        <ArrowBackIcon />
                    </IconButton> */}
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i class="bi bi-chevron-left me-1"></i> Back</button>
                        {action === 'edit' ? (
                            <h1 className="main-title" style={{ margin: '0' }}>Edit Care Navigator</h1>
                        ) : (
                            <h1 className="main-title" style={{ margin: '0' }}>Add New Care Navigator</h1>
                        )}
                    </div>
                </div>
                <LoaderLoader isLoading={isLoading} />
                <form className='' onSubmit={submitFinalData}> 
                <div className='comntent-CareNavigator p-3 mb-3'>
                    <div className="row">
                        <div className="col-md-auto px-3">
                            <div className="DefaultLeaderBoardimage">
                                <img src={formData['imagePath']} className="profile-img" alt="" />
                                <button type="button" className="btn btn-secondary icon-edit-btn" onClick={handleButtonClick}>
                                    <i className="bi bi-pencil-fill"></i>
                                </button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    
                                />
                                {errors['imagePath'] && <div className="text-danger">{errors['imagePath']}</div>}
                            </div>
                        </div>
                        <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12 mb-3 ">
                                        <label htmlFor="emailId" className="form-label">Email Id</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <div className="d-flex">
                                            <input type="text" className="form-control input-field-ui" autoComplete="off" onChange={handleOnTypingEmail} value={formData['emailId'] || ''} name="emailId" id="emailId" placeholder="Enter Email Id" disabled={action === 'edit' ? true : false}/>
                                            {loading && <CustomFieldLevelLoader /> }
                                        </div>
                                        {errors['emailId'] && <div className="text-danger">{errors['emailId']}</div>}
                                    </div>
                                    {suggestions.length > 0 && formData['emailId'].length > 0 && (
                                        <ul className="suggestions-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion.givenName} {suggestion.surname} ({suggestion.mail})
                                            </li>
                                        ))}
                                        </ul>
                                    )}
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="Name" className="form-label">Name</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" className="form-control input-field-ui" id="Name" name="firstName" value={formData['firstName'] || ''} placeholder="Enter Name" disabled={true}/>
                                        {errors['firstName'] && <div className="text-danger">{errors['firstName']}</div>}
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" className="form-control input-field-ui" id="lastName" name="lastName" value={formData['lastName'] || ''} placeholder="Enter Last Name" disabled={true}/>
                                        {errors['lastName'] && <div className="text-danger">{errors['lastName']}</div>}
                                    </div>

                                    {/* <div className="col-md-6 mb-3">
                                        <label htmlFor="bod" className="form-label">Date of Birth</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <DatePicker
                                            selected={formData['dob'] || ''}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={setMaxDate()}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            placeholderText="dd/MM/yyyy"
                                            className="form-control input-field-ui"
                                            
                                        />
                                    </div> */}
                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Gender</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioGender" id="radioGenderMale" value="Male" checked={formData['gender'] === 'Male'} onChange={handleRadioChange}/>
                                            <label className="form-check-label" htmlFor='radioGenderMale'>Male</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioGender" id="radioGenderFemale" value="Female" checked={formData['gender'] === 'Female'} onChange={handleRadioChange}/>
                                            <label className="form-check-label" htmlFor='radioGenderFemale'>Female</label>
                                        </div>
                                        {errors['gender'] && <div className="text-danger">{errors['gender']}</div>}
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="mobileNumber" className="form-label">Mobile Number</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" className="form-control input-field-ui" id="mobileNumber" name="mobileNumber" onChange={(e) => onValueChange(e)} value={formData['mobileNumber'] || ''} placeholder="Enter Mobile Number"/>
                                        {errors['mobileNumber'] && <div className="text-danger">{errors['mobileNumber']}</div>}
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="selectProgram" className="form-label">Select Program </label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <TreeDropdown data={programConditionData} onChange={getDropdownData}/>
                                        {programDataError && <p style={{ color: "red" }}>Please select a program.</p> }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="Language" className="form-label">Language</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <MultiSelect
                                            options={options}
                                            value={selectedOptions}
                                            onChange={handleChange}
                                            labelledBy="Select"
                                            hasSelectAll={false}
                                            className="form-select select-input-ui select-input-ui-select"
                                            disableSearch={true}
                                            overrideStrings={{ selectAll: 'Select All', allItemsAreSelected: 'All items are selected', selectSomeItems: 'Select items' }}
                                            
                                        />
                                        {errors['language'] && <div className="text-danger">{errors['language']}</div>}
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="professionalHeadline" className="form-label">Professional Headline</label>
                                        <input type="text" className="form-control input-field-ui" id="professionalHeadline" name="phe" onChange={(e) => onValueChange(e)} value={formData['phe'] || ''} placeholder="Enter Professional Headline"/>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="yourSelf" className="form-label">Tell us about your self (to display on member app)</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <textarea className="form-control input-field-ui" id="yourSelf"  placeholder="Write" name="yourSelf" onChange={(e) => onValueChange(e)} value={formData['yourSelf'] || ''} rows="6"></textarea>
                                        {errors['yourSelf'] && <div className="text-danger">{errors['yourSelf']}</div>}
                                    </div>

                                    {/* <div className="col-md-6 mb-3">
                                        <label htmlFor="Activities" className="form-label">Activities</label>
                                        <input type="text" className="form-control input-field-ui" id="Activities" name="activities" onChange={(e) => onValueChange(e)} value={formData['activities'] || ''} placeholder="Enter Activities"/>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="Skils" className="form-label">Skills</label>
                                        <input type="text" className="form-control input-field-ui" id="Skils" placeholder="Enter Skills" name="skils" onChange={(e) => onValueChange(e)} value={formData['skils'] || ''}/>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="Certifications" className="form-label">Certificates</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <div className="row">
                                        
                                        {fileUploadError && <p style={{ color: "red" }}>Please add Certificate Title and Upload a Certificate.</p> }

                                        {action === 'edit' && (
                                            fetchInputs.length > 0 ? (fetchInputs.map((fetchInput, index) => (
                                                <div key={index} style={{
                                                    display: 'flex',
                                                    padding: '5px'
                                                }}>
                                                
                                                <input style={{width:"auto", margin: '0px 5px'}} className="form-control form-control-sm centered-placeholder" value={fetchInput.documentName} name="text" placeholder="Certificate Title"  type="text" disabled />                                                

                                                <button type="button" style={{width: "auto"}} className="btn btn-danger btn-sm" onClick={() => handleRemoveFields(fetchInput.fileId, 'removePerm')}><i className="bi bi-trash"></i></button>
                                                </div>
                                            ))) : (
                                                <p>No Certificates Found.</p>
                                            )
                                        )}
                                        

                                        {inputs.map((input, index) => (
                                                <div key={index} style={{
                                                    display: 'flex',
                                                    padding: '5px'
                                                }}>
                                                
                                                <input style={{width:"auto", margin: '0px 5px'}} className="form-control form-control-sm centered-placeholder" value={input.text} name="text" onChange={e => handleInputChange(index, e)} placeholder="Certificate Title"  type="text" />
                                                

                                                <input style={{width:"auto", margin: '0px 5px'}} className="form-control form-control-sm" type="file" data-index={index}  name="file" accept="application/pdf" onChange={(e) => handleFileChangeNew(index, e)} />
                                                

                                                {index > 0 ? <button type="button" style={{width: "auto"}} className="btn btn-danger btn-sm" onClick={() => handleRemoveFields(index, 'removeTemp')}><i className="bi bi-trash"></i></button> : null}
                                                </div>
                                            ))}
                                        <div className="col pe-0">
                                        {(action === 'create' && inputs.length < 3) && <a style={{width: "auto", color: "#03335b",textDecoration: "underline",cursor: "pointer"}} className="me-3 mb-2"  onClick={handleAddFields}><i className="bi bi-plus"></i> Add More Certificates</a>}
                                        {(action === 'edit' && (fetchInputs.length + inputs.length) < 3) && <a style={{width: "auto", color: "#03335b",textDecoration: "underline",cursor: "pointer"}} className="me-3 mb-2" onClick={handleAddFields}><i className="bi bi-plus"></i> Add More Certificates</a>}
                                        </div>
                                        
                                        </div>
                                    </div>


                                    <div className="col-md-12 mb-3"> 
                                        {action === 'edit' ? (
                                            <button type="submit" className="btn btn-primary btn-primary program-save-btn" disabled={isSubmitting ? true : false}>Update</button> 
                                        ) : (
                                            <>
                                                <button type="submit" className="btn btn-primary btn-primary program-save-btn" disabled={isSubmitting ? true : false}>Add</button> 
                                                {/* <button type="button" className="btn clear-btn" style={{width:"auto", margin: '0px 5px'}} onClick={handleClearForm}>Clear</button> */}
                                            </>
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            </section>
            
        </>
    );
}

export default AddNewCareNavigator;
