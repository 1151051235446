import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load the 3D module
require('highcharts/highcharts-3d')(Highcharts);

const SafetyLifestyleBar = () => {
    const columnGraphHeight = 180; // Adjust height to match design
    const colCategories = ['Sedentary', 'Active', 'Fit', 'Elite']; // Categories from the chart
    const yAxisJson = {
        title: {
            text: null // Remove Y-axis title
        },
        max: 150 // Adjust max value based on the chart
    };

    // Update data to match the values in the screenshot
    const columnData = [
        {
            name: 'People',
            data: [121, 61, 35, 47], // Data matching the image
            colorByPoint: true, // Use individual colors for each bar
            colors: ['#eb6b56', '#f1c04d', '#5ea97d', '#4572a7'], // Colors for each category
        }
    ];

    const optionsSL = {
        chart: {
            animation: false,
            type: 'bar',
            height: columnGraphHeight,
            options3d: {
                enabled: true,
                alpha: 1,
                beta: 10,
                depth: 250,
                viewDistance: 5
            },
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: colCategories,
            labels: {
                rotation: 0,
                style: {
                    fontSize: '14px',
                    fontWeight: 600
                }
            },
            gridLineWidth: 0
        },
        series: columnData, // Use static data
        credits: {
            enabled: false
        },
        yAxis: {
            tickInterval: 20,
            gridLineWidth: 0,
            startOnTick: false,
            min: 0,
            max: 150, // Adjust max to fit the highest value
            title: {
                text: ''
            },
            labels: {
                enabled: false
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                return `<b>${this.x}</b>: ${this.y}`;
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        plotOptions: {
            series: {
                animation: false,
                stacking: 'normal',
                showInLegend: false,
                pointPadding: 0.1,
                pointWidth: 30, // Set a static width for bars
                dataLabels: {
                    enabled: true,
                    align: 'left', // Align labels to the left of the bars
                    inside: false, // Ensure labels are outside the bars
                    x: 5, // Adjust x position to move it slightly away from the bar
                    style: {
                        textOutline: 'none',
                    }
                },
            }
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={optionsSL} />;
};

export default SafetyLifestyleBar;
