import React from 'react';
import './InsurePlusEmployeesOnboarded.css';


const InsurePlusEmployeesOnboarded = () => {
  const stats = [
    { count: '74', label: 'Total lives Covered', bgColor: 'bg-grey', icon: 'https://appcdn.goqii.com/storeimg/58523_1727935315.png' },
    { count: '11', label: 'Employees Covered', bgColor: 'bg-grey', icon: 'https://appcdn.goqii.com/storeimg/30457_1727935752.png' },
    { count: '0', label: 'Spouse Covered', bgColor: 'bg-grey', icon: 'https://appcdn.goqii.com/storeimg/95745_1727935825.png' },
    { count: '0', label: 'Children Covered', bgColor: 'bg-grey', icon: 'https://appcdn.goqii.com/storeimg/58223_1727935887.png' },
  ];

  return (
    <>
    <section className='InsurePlusEmployeesOnboarded'>
        <div className=" row text-center">
        {stats.map((stat, index) => (
                <div className="col" key={index}>
                    <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                        <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto ps-2">
                                <img src={stat.icon} alt={`${stat.label} icon`} className="card-icon mb-0" />
                            </div>
                            <div className="col px-0">
                                <h1 className="card-title">{stat.count}</h1>
                                <h5 className="card-text">{stat.label}</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </section>
    </>
    
  );
};

export default InsurePlusEmployeesOnboarded;
