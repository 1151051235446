import React, { useState, useEffect } from 'react';
import "./Dashboard.css"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { fetchHomeBasedServiceTracking } from "../components/services/Service";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Height, Padding } from '@mui/icons-material';

const ServiceTracking = () => {
    const [membershipNumber, setMembershipNumber] = useState("");
    const [requestType, setRequestType] = useState("");
    const [requestDate, setRequestDate] = useState("");
    const [requestStatus, setRequestStatus] = useState("");
    const [homeBasedServices, setHomeBasedServices] = useState([]);

    const getHomeBasedServiceTracking = async (membershipNumber, requestType, requestDate, requestStatus) => {
        setHomeBasedServices([]);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        const formattedDate = (requestDate !== "") ? moment(requestDate).format('YYYY-MM-DD') : "";
        await fetchHomeBasedServiceTracking(membershipNumber, requestType, formattedDate, requestStatus, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setHomeBasedServices(response.data.data.homebasedservices);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }
    return <>
    <div class="table-responsive" style={{ height: "480px", overflowX: "hidden", overflowY: "scroll" }}>
        <table className='table table-striped'>
            <thead style={{position:"sticky", top:"0", backgroundColor:'#fff'}}>
                <tr>
                    <th style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>Membership #</th>
                    <th style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>Request Type</th>
                    <th style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>Request Date</th>
                    <th style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>Status</th>
                </tr>
                <tr className='mb-2'>
                    <td style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>
                        <input type="input" name="membershipNumber" value={membershipNumber} onChange={(e) => {
                            if (!/^\d*\.?\d*$/.test(e.target.value)) {
                                return false;
                            }
                            setMembershipNumber(e.target.value);
                            getHomeBasedServiceTracking(e.target.value, requestType, requestDate, requestStatus);
                        }
                        } className='form-control control-input-ui-dashboard' />
                    </td>
                    <td style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>
                        <select name="requestType" className="form-select select-input-ui-dashboard" value={requestType} onChange={(e) => {
                            setRequestType(e.target.value);
                            getHomeBasedServiceTracking(membershipNumber, e.target.value, requestDate, requestStatus);
                        }}>
                            <option value="">Select</option>
                            <option value="all">All</option>
                            <option value="mr">Medication Requests</option>
                            <option value="lr">Lab Requests</option>
                            <option value="hcr">Home Care Requests</option>
                            <option value="hbv">Home Based Vaccines</option>
                        </select>
                    </td>
                    <td style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>
                        <DatePicker
                            selected={requestDate}
                            onChange={(date) => { setRequestDate(date); getHomeBasedServiceTracking(membershipNumber, requestType, date, requestStatus); }}
                            dateFormat="dd-MM-yyyy"
                            placeholderText=""
                            className='form-control control-input-ui-dashboard'
                            onKeyDown={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </td>
                    <td style={{backgroundColor:"transparent", padding:"2px", border:"none"}}>
                        <select name="requestStatus" className="form-select select-input-ui-dashboard" value={requestStatus} onChange={(e) => { setRequestStatus(e.target.value); getHomeBasedServiceTracking(membershipNumber, requestType, requestDate, e.target.value); }}>
                            <option value="">Select</option>
                            <option value="all">All</option>
                            <option value="delayed">Delayed</option>
                            <option value="inprogress">In Progress</option>
                            <option value="completed">Completed</option>
                        </select>
                    </td>
                </tr>
                {
                    homeBasedServices.length <= 0 &&
                    <tr><td colSpan={4} style={{border:"none"}}>No records found</td></tr>
                }
                
            </thead>
            <tbody >
                {
                    homeBasedServices.map((item, index) => {
                        return <tr style={{fontSize:"14px"}}>
                            <td className='py-2'>{item.membershipNumber}</td>
                            <td>{item.requestType}</td>
                            <td>{item.requestDate}</td>
                            <td className={item.requestStatus}>{item.requestStatus}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>
        </div>
    </>
}

export default ServiceTracking