// BloodReportPreviewMain.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import BloodReportIntro from './BloodReportIntro';
import SmartHealthReport from './SmartHealthReport';
import BloodReportPreview3 from './HealthAnalysis';
import BloodReportPreview4 from './HistoricalCharts';
import BloodReportLabTestResult from './BloodReportLabTestResult';
import BloodReportTestAdvice from './BloodReportTestAdvice';
import BloodReportHealthAdvisory from './BloodReportHealthAdvisory';
import { fetchSmartReport, fetchSmartReportV2 } from '../services/Service';

const previews = [
  BloodReportIntro,
  SmartHealthReport,
  // BloodReportPreview3,
  // BloodReportPreview4,
  BloodReportLabTestResult,
  BloodReportTestAdvice,
  BloodReportHealthAdvisory
];


const BloodReportPreviewMain = ({ fileId }) => {

  const [reportData, setReportData] = useState({});
  useEffect(() => {
    // fetchSmart(fileId);
    fetchSmartV2(fileId)
  }, [])
  const fetchSmart = async (fileId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchSmartReport(fileId, ct)
      .then((response) => {
        if (response.data.code === 200) {
          console.log("fetchSmartReport", JSON.stringify(response.data.data.summaryJson));
          setReportData(response.data.data.summaryJson)

        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }
  const fetchSmartV2 = async (fileId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchSmartReportV2(fileId, ct)
      .then((response) => {
        if (response.data.code === 200) {
          console.log("fetchSmartReportV2", JSON.stringify(response.data.data.summaryJson));
          setReportData(response.data.data.reportJsons[0])

        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }


  console.log(reportData);

  console.log("reportData",reportData);
  return (
    <div className=''>
      {previews.map((PreviewComponent, index) => (
        // Step 3: Pass JSON as a prop
        <PreviewComponent key={index} reportData1={reportData} />
      ))}
    </div>
  );
}

export default BloodReportPreviewMain;