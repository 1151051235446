import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Dashboard from "./Dashboard";
import BlogList from "./BlogList";
import ContentManagement from "./ContentManagement";
import BlogCreationOptions from "./BlogCreationOptions";
import BlogEditor from "./BlogEditor";
import ChooseTemplate from "./ChooseTemplate";
import CreateWithAI from "./CreateWithAI";
import BlogPreview from "./BlogPreview";
import VideoUpload from "./VideoUpload";
import ChooseVideoFromLibrary from "./ChooseVideoFromLibrary";
import UploadYouTubeVideo from "./UploadYouTubeVideo";
import VideoPreview from "./VideoPreview";
import { Link } from 'react-router-dom';
import { fetchTabData } from "../../../services/cms/Service";

const BlogTab = ({ selectedIndex }) => {
  const [contentCreationStep, setContentCreationStep] = useState('options');
  const [previousStep, setPreviousStep] = useState('');
  // const [selectedIndex, setSelectedIndex] = useState('');
  console.log(selectedIndex, "selectedIndex")
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [roleId, setRoleId] = useState(localStorage.getItem('roleId'));
  const [currentContent, setCurrentContent] = useState({
    type: '',
    title: '',
    description: '',
    content: '',
    author: '',
    publishedDate: new Date().toISOString().split('T')[0],
    categories: [],
    seoKeywords: [],
    headerImage: '',
    videoUrl: '',
    videoType: '',
    thumbnailUrl: ''
  });

  const [categories, setCategories] = useState([
    { id: 1, title: 'Technology', description: 'All about tech', image: 'tech-icon.png', publishedBlogs: 15 },
    { id: 2, title: 'Health', description: 'Health and wellness', image: 'health-icon.png', publishedBlogs: 10 },
    { id: 3, title: 'Fitness', description: 'Exercise and nutrition', image: 'fitness-icon.png', publishedBlogs: 8 },
  ]);

  const [tags, setTags] = useState([
    { id: 1, title: 'AI', description: 'Artificial Intelligence', publishedBlogs: 5 },
    { id: 2, title: 'Yoga', description: 'Mind and body practice', publishedBlogs: 7 },
    { id: 3, title: 'Nutrition', description: 'Food and diet', publishedBlogs: 6 },
  ]);

  const [creators, setCreators] = useState([
    { id: 1, firstName: 'John', lastName: 'Doe', email: 'john@example.com', image: 'https://example.com/john.jpg', description: 'Tech writer' },
    { id: 2, firstName: 'Jane', lastName: 'Smith', email: 'jane@example.com', image: 'https://example.com/jane.jpg', description: 'Fitness expert' },
  ]);

  const [images, setImages] = useState([
    { id: 1, url: 'https://example.com/image1.jpg', tags: ['nature', 'landscape'] },
    { id: 2, url: 'https://example.com/image2.jpg', tags: ['portrait', 'people'] },
  ]);

  const resetContentCreation = () => {
    setContentCreationStep('options');
    setPreviousStep('');
    setCurrentContent({
      type: '',
      title: '',
      description: '',
      content: '',
      author: '',
      publishedDate: new Date().toISOString().split('T')[0],
      categories: [],
      seoKeywords: [],
      headerImage: '',
      videoUrl: '',
      videoType: '',
      thumbnailUrl: ''
    });
  };

  const resetVideoData = () => {
    setCurrentContent(prev => ({
      ...prev,
      videoUrl: '',
      thumbnailUrl: '',
      videoType: '',
      title: '',
      description: ''
    }));
  };

  const handleSelectOption = (option, item = null) => {
    console.log("handleSelectOption called with:", option, item);
    setPreviousStep(contentCreationStep);
    setContentCreationStep(option);
    if (item) {
      console.log("Setting current content:", item);
      setCurrentContent(item);
    } else if (option === 'editor') {
      setCurrentContent(prev => ({ ...prev, type: 'blog' }));
    } else if (option === 'videoUpload' || option === 'chooseVideoFromLibrary' || option === 'uploadYouTubeVideo') {
      resetVideoData();
      setCurrentContent(prev => ({ ...prev, type: 'video' }));
    }
  };

  const handleBack = () => {
    if (previousStep === 'chooseVideoFromLibrary' || previousStep === 'uploadYouTubeVideo') {
      resetVideoData();
    }
    setContentCreationStep('options');
    setPreviousStep('');
  };

  const handleTemplateChosen = (content) => {
    setCurrentContent(prevContent => ({ ...prevContent, content, type: 'blog' }));
    setContentCreationStep('editor');
  };

  const handleVideoChosen = (videoData) => {
    setCurrentContent(prevContent => ({ ...prevContent, ...videoData, type: 'video' }));
    setContentCreationStep('videoUpload');
  };

  const handleEditAIContent = (content) => {
    setCurrentContent(prevContent => ({ ...prevContent, content, type: 'blog' }));
    setContentCreationStep('editor');
  };

  const handlePreview = (contentData) => {
    setCurrentContent(prevContent => ({ ...prevContent, ...contentData }));
    setContentCreationStep('preview');
  };

  const handleBackToEditor = () => {
    setContentCreationStep(currentContent.type === 'video' ? 'videoUpload' : 'editor');
  };

  const handleEditBlog = (blog) => {
    setCurrentContent({
      type: 'blog',
      ...blog
    });
    setContentCreationStep('editor');
  };

  const handleAddCategory = (newCategory) => {
    const newCategoryWithId = { ...newCategory, id: categories.length + 1, publishedBlogs: 0 };
    setCategories([...categories, newCategoryWithId]);
    setShowCategoryModal(false);
    return newCategoryWithId;
  };

  const handleEditCategory = (editedCategory) => {
    setCategories(categories.map(category =>
      category.id === editedCategory.id ? editedCategory : category
    ));
  };

  const handleDeleteCategory = (id) => {
    setCategories(categories.filter(category => category.id !== id));
  };

  const handleAddTag = (newTag) => {
    const newTagWithId = { ...newTag, id: tags.length + 1, publishedBlogs: 0 };
    setTags([...tags, newTagWithId]);
    return newTagWithId;
  };

  const handleEditTag = (editedTag) => {
    setTags(tags.map(tag =>
      tag.id === editedTag.id ? editedTag : tag
    ));
  };

  const handleDeleteTag = (id) => {
    setTags(tags.filter(tag => tag.id !== id));
  };

  const handleAddCreator = (newCreator) => {
    const newCreatorWithId = { ...newCreator, id: creators.length + 1 };
    setCreators([...creators, newCreatorWithId]);
  };

  const handleEditCreator = (editedCreator) => {
    setCreators(creators.map(creator =>
      creator.id === editedCreator.id ? editedCreator : creator
    ));
  };

  const handleDeleteCreator = (id) => {
    setCreators(creators.filter(creator => creator.id !== id));
  };

  const handleAddImage = (newImage) => {
    const newImageWithId = { ...newImage, id: images.length + 1 };
    setImages([...images, newImageWithId]);
  };

  const handleEditImage = (editedImage) => {
    setImages(images.map(image =>
      image.id === editedImage.id ? editedImage : image
    ));
  };

  const handleDeleteImage = (id) => {
    setImages(images.filter(image => image.id !== id));
  };

  const renderContentCreationStep = () => {
    switch (contentCreationStep) {
      case 'options':
        return <BlogCreationOptions
          onSelectOption={handleSelectOption}
          categories={categories}
          tags={tags}
          creators={creators}
          onAddCategory={handleAddCategory}
          onEditCategory={handleEditCategory}
          onDeleteCategory={handleDeleteCategory}
          onAddTag={handleAddTag}
          onEditTag={handleEditTag}
          onDeleteTag={handleDeleteTag}
          onAddCreator={handleAddCreator}
          onEditCreator={handleEditCreator}
          onDeleteCreator={handleDeleteCreator}
          onAddImage={handleAddImage}
          onEditImage={handleEditImage}
          onDeleteImage={handleDeleteImage}
        />;
      case 'editor':
        return <BlogEditor
          blogContent={currentContent}
          onPreview={handlePreview}
          onCancel={() => setContentCreationStep('options')}
          onCreateWithAI={() => setContentCreationStep('ai')}
          onBack={handleBack}
          categories={categories}
          tags={tags}
          onAddCategory={() => setShowCategoryModal(true)}
          onAddTag={handleAddTag}
          showCategoryModal={showCategoryModal}
          setShowCategoryModal={setShowCategoryModal}
        />;
      case 'template':
        return <ChooseTemplate onTemplateChosen={handleTemplateChosen} onBack={handleBack} />;
      case 'ai':
        return <CreateWithAI onEditAIContent={handleEditAIContent} onBack={handleBack} />;
      case 'preview':
        return currentContent.type === 'blog'
          ? <BlogPreview
            blog={currentContent}
            onBackToEditor={handleBackToEditor}
            onCancel={() => setContentCreationStep('options')}
          />
          : <VideoPreview
            video={currentContent}
            onBackToEditor={handleBackToEditor}
            onCancel={() => setContentCreationStep('options')}
          />;
      case 'videoUpload':
        return <VideoUpload
          videoData={currentContent}
          onPreview={handlePreview}
          onCancel={() => setContentCreationStep('options')}
          onBack={handleBack}
          categories={categories}
          tags={tags}
          onAddCategory={handleAddCategory}
          onAddTag={handleAddTag}
        />;
      case 'chooseVideoFromLibrary':
        return <ChooseVideoFromLibrary onVideoChosen={handleVideoChosen} onBack={handleBack} />;
      case 'uploadYouTubeVideo':
        return <UploadYouTubeVideo onVideoChosen={handleVideoChosen} onBack={handleBack} />;
      default:
        return <BlogCreationOptions onSelectOption={handleSelectOption} />;
    }
  };

  const fetch_Tab_Data = async () => {
    try {
      let resp = await fetchTabData();
      if (resp?.data?.code === 200) {
        setRoleId(resp.data.data.roleId);
        localStorage.setItem('roleId', resp.data.data.roleId);
      } else {
        console.error("Error fetching role ID:", resp.data.data.msg);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };



  useEffect(() => {
    if (!roleId || roleId == '') {
      fetch_Tab_Data();
    }
    // if (roleId && roleId == 25) {
    //   setSelectedIndex(0)
    // } else {
    //   setSelectedIndex(1)
    // }

  }, []);

  const renderTabs = () => {
    if (roleId && roleId != '') {
      const roleIdSwitch = parseInt(roleId, 10);
      switch (roleIdSwitch) {
        case 25:
          return (
            <TabList>
              <Link to="/content-management/create-content"><Tab>Content Creation</Tab></Link>
              <Link to="/content-management/content-status"><Tab>Content Approval</Tab></Link>
              <Link to="/content-management/manage-content"><Tab>Content Management</Tab></Link>
            </TabList>
          );
        case 26:
          return (
            <TabList>
              <Link to="/content-management"><Tab>Content Engagement</Tab></Link>
              <Link to="/content-management/create-content"><Tab>Content Creation</Tab></Link>
              <Link to="/content-management/content-status"><Tab>Content Approval</Tab></Link>
              <Link to="/content-management/manage-content"><Tab>Content Management</Tab></Link>
            </TabList>
          );
        case 27:
          return (
            <TabList>
              <Link to="/content-management"><Tab>Content Engagement</Tab></Link>
              <Link to="/content-management/create-content"><Tab>Content Creation</Tab></Link>
              <Link to="/content-management/content-status"><Tab>Content Approval</Tab></Link>
              <Link to="/content-management/manage-content"><Tab>Content Management</Tab></Link>
            </TabList>
          );
        default:
          return <p>No tabs available for your role.</p>;
      }
    }
  };

  return (
    <div className="tw-p-4 tw-bg-white tw-rounded-lg">
      <Tabs onSelect={resetContentCreation}
        selectedIndex={selectedIndex}
      >
        {roleId === null ? <p>Loading tabs...</p> : renderTabs()}

        {/* <TabPanel>
          <Dashboard />
        </TabPanel>
        <TabPanel>
          {renderContentCreationStep()}
        </TabPanel>
        <TabPanel>
          <BlogList onEditBlog={handleEditBlog} />
        </TabPanel>
        <TabPanel>  
          <ContentManagement />
        </TabPanel> */}
      </Tabs>
    </div>
  );
};

export default BlogTab;