import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useUserTabs } from '../components/common/UserTabsContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import HealthManagement from '../components/healthmanagement/HealthManagement';
import CarePlanChange from '../components/careplanchange/CarePlanChange';
import './Members.css'
import TopBar from '../components/topbar/TopBar';
import Chat from '../components/chat/Chat';
import { Modal } from 'react-bootstrap';
import {
    fetchUserProfile, fetchPathways, assignPathway, addWeight, disEnrollRequest, fetchMemberServices, bookMemberServices, updateMemberServicesStatus, cn_call_disposition, fetchServiceCategories, fetchServiceByCategory, addMemberAdditionalServices, data_disposition_list, updateAltMobile, fetchDisenrollmentReasons
} from "../components/services/Service";
import FormsModal from '../components/FormsModal/FormsModal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Column from '../components/graphs/Column';
import Line from '../components/graphs/Line';
import AddMember from '../components/addmember/AddMember';
import MemberServices from '../components/memberservices/MemberServices';
import HealthLocker from '../components/healthLocker/HealthLocker';
import PathwayChangeLogs from '../components/pathway/PathwayChangeLogs';
import EventHistory from '../components/memberservices/EventHistory';
import HomeBasedServicesHistory from '../components/memberservices/HomeBasedServicesHistory';
import MemberCalls from '../components/memberservices/MemberCalls';
import MemberCallHistory from '../components/memberservices/MemberCallHistory';


const Members = ({ openOutGoingCallWindow, showDisposition, setShowDisposition, showCallBlock }) => {


    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [refreshVar, setRefreshVar] = useState(new Date().getTime());

    const MemberServicesRef = useRef();
    const HomeBasedServicesHistoryRef = useRef();
    const MemberCallHistoryRef = useRef();
    const EventHistoryRef = useRef();
    const PathwayChangeLogsRef = useRef();

    //const [sharedMemberServices, setSharedMemberServices] = useState([]);

    const { userTabs, addUserTab, closeUserTab } = useUserTabs();
    const [memberBasicInfo, setMemberBasicInfo] = useState([]);
    const [memberPathways, setMemberPathways] = useState([]);
    const [pathways, setPathways] = useState([]);
    const [pwUpdateMessage, setPWUpdateMessage] = useState("");
    const [pwUpdateError, setPWUpdateError] = useState("");
    const [showPWUpdateForm, setShowPWUpdateForm] = useState(false);

    const [weightUpdateMessage, setWeightUpdateMessage] = useState("");
    const [weightUpdateError, setWeightUpdateError] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [bmi, setBMI] = useState("");
    const [bmiObservation, setBMIObservation] = useState("");

    const [disEnrollmentMessage, setDisEnrollmentMessage] = useState("");
    const [disEnrollmentError, setDisEnrollmentError] = useState("");
    const [disEnrollmentNote, setDisEnrollmentNote] = useState("");
    const [disEnrollmentReason, setDisEnrollmentReason] = useState("");
    const [showModal, setShowModal] = useState(false);

    const selectedRecordObject = { recordId: "", serviceName: "", serviceType: "", serviceRequestType: "" }
    const [selectedBulkServices, setSelectedBulkServices] = useState([]);
    const [selectedServiceRecord, setSelectedServiceRecord] = useState(selectedRecordObject);

    const [showAltMobileModal, setShowAltMobileModal] = useState(false);
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [altMobileUpdateError, setAltMobileUpdateError] = useState("");
    const [altMobileUpdateMessage, setAltMobileUpdateMessage] = useState("");


    const [disenrollmentReasons, setDisenrollmentReasons] = useState([]);

    useEffect(() => {
        if (weight !== "" && height !== "") {
            //const heightInInches = height / 2.54;
            //let BMI = ((weight * 703) / (heightInInches * heightInInches));
            const heightInMeters = height / 100;
            let BMI = (weight / (heightInMeters * heightInMeters));

            BMI = parseFloat(BMI).toFixed(1)
            let observation = (BMI < 18.5) ? "Underweight" : (BMI >= 18.5 && BMI <= 24.9) ? "Healthy" : (BMI >= 25 && BMI <= 29.9) ? "Overweight" : "Obese";

            setBMI(BMI);
            setBMIObservation(observation);
        } else {
            setBMI("");
            setBMIObservation("");
        }
    }, [weight, height])



    const pathwayObject = {
        programId: "",
        conditionId: "",
        oldPathwayId: "",
        pathwayId: "0",
        notes: "",
        hba1c: "",
        hba1cDate: ""
    }
    const [pathwayFormData, setPathwayFormData] = useState(pathwayObject);

    /*const addServiceObject = {
        pathwayId: (memberBasicInfo.memberPathways) ? memberBasicInfo.memberPathways[0].pathwayId : "",
        categoryId: '',
        serviceId: '',
        date: new Date(),
        time: null,
        notes: ""
    }
    const [addServiceData, setAddServiceData] = useState(addServiceObject);
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [addServiceError, setAddServiceError] = useState("");
    const [addServiceMessage, setAddServiceMessage] = useState("");

    const now = new Date();
    const isTodayAS = addServiceData.date instanceof Date && (addServiceData.date).toDateString() === now.toDateString();
    const minTimeAS = isTodayAS ? now : new Date().setHours(0, 0, 0, 0);*/



    useEffect(() => {
        getMemberBasicInfo();
        getDispositionReasonsList();
    }, [userId]);

    const [modalTitle, setModalTitle] = useState(false);
    const [showHRAModal, setShowHRAModal] = useState(false);
    const [showSmokingHRAModal, setShowSmokingHRAModal] = useState(false);
    const [showMedicationModal, setShowMedicationModal] = useState(false);
    const handleCloseHRAModal = () => setShowHRAModal(false);
    const handleCloseSmokingHRAModal = () => setShowSmokingHRAModal(false);
    const handleShowHRAModal = () => setShowHRAModal(true);
    const handleShowSmokingHRAModal = () => setShowSmokingHRAModal(true);
    const handleCloseMedicationModal = () => {
        setShowMedicationModal(false);
        setSelectedServiceRecord(selectedRecordObject);
        callMemberServicesMonthCategoryWiseFunc();
    }
    const handleShowMedicationModal = (header) => {
        setSelectedServiceRecord(selectedRecordObject);
        setModalTitle(header)
        setShowMedicationModal(true);
    }

    const handleMemberBulkHomeCareRequest = (bulkServices, header) => {
        setSelectedBulkServices(bulkServices);
        setModalTitle(header)
        setShowMedicationModal(true);
    }

    const handleShowMedicationModalServiceWise = (header) => {
        setModalTitle(header)
        setShowMedicationModal(true);
    }


    const [modalPathwayName, setModalPathwayName] = useState(false);
    const [showCarePlanModal, setShowCarePlanModal] = useState(false);
    const handleCloseCarePlanModal = () => setShowCarePlanModal(false);
    const handleShowCarePlanModal = (pathwayName) => {
        setShowCarePlanModal(true);
        setModalPathwayName(pathwayName)
    }

    const [showAddWeightModal, setShowAddWeightModal] = useState(false);
    const handleShowWeightModal = () => {
        setWeight("");
        setHeight("");
        setWeightUpdateError("");
        setWeightUpdateMessage("");
        setShowAddWeightModal(true);
    }

    const [showDisEnrollmentModal, setShowDisEnrollmentModal] = useState(false);
    const handleShowDisEnrollmentModal = () => {
        if (memberBasicInfo.memberPathways.length > 0) {
            getDisenrollmentReasons(memberBasicInfo.memberPathways[0]['programId'], memberBasicInfo.memberPathways[0]['conditionId']);
        }
        setDisEnrollmentNote("");
        setDisEnrollmentError("");
        setShowDisEnrollmentModal(true);
    }

    /*const selectedRecordObject = { recordId: "", serviceName: "", serviceType: "" }
    const bookingFormObject = {
        date: new Date(),
        time: "",
        notes: ""
    }

    const bookingStatusUpdateObject = {
        status: "",
        notes: "",
        files: [{
            id: new Date(),
            title: "",
            file: null,
            originalName: ""
        }]
    }
    const [bookingFormData, setBookingFormData] = useState(bookingFormObject);
    const [bookingStatusUpdateFormData, setBookingStatusUpdateFormData] = useState(bookingStatusUpdateObject);

    const [selectedServiceRecord, setSelectedServiceRecord] = useState(selectedRecordObject);
    const [showBookMemberServiceModal, setShowBookMemberServiceModal] = useState(false);
    const [showUpdateMemberServiceModal, setShowUpdateMemberServiceModal] = useState(false);
    const [bookingMessage, setBookingMessage] = useState("");
    const [bookingError, setBookingError] = useState("");

    const [msUpdateMessage, setMsUpdateMessage] = useState("");
    const [msUpdateError, setMsUpdateError] = useState("");*/

    // const [showDisposition, setShowDisposition] = useState(false);

    const [requestStatus, setRequestStatus] = useState({
        isDispositionFormLoading: false
    });

    const [dispositionForm, setDispositionForm] = useState({
        callStatus: "",
        notes: "",
        callbackDateAndTime: ""
    });

    const [dispositionErrors, setDispositionErrors] = useState({
        callStatus: "",
        notes: "",
        preferredChannel: "",
        prefferedTime: {
            from: "",
            to: ""
        },
        callbackDateAndTime: ""
    });

    const [dispositionSubmissionMessage, setDispositionSubmissionMessage] = useState({
        status: false,
        message: ""
    });

    const [confNumber, setConfNumber] = useState(null);


    const [dispositionReasons, setDispositionReasons] = useState([]);




    const getMemberBasicInfo = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchUserProfile(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setMemberBasicInfo(response.data.data.info);
                    setMemberPathways(response.data.data.info.memberPathways);

                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }



    const getPathways = async (programId, conditionId, oldPathwayId) => {
        setPWUpdateMessage("");
        setPWUpdateError("");
        setPathwayFormData(pathwayObject);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchPathways(userId, programId, conditionId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setPathwayFormData({
                        ...pathwayFormData,
                        programId: programId,
                        conditionId: conditionId,
                        oldPathwayId: oldPathwayId
                    })
                    setPathways(response.data.data.pathways);
                    setShowPWUpdateForm(true);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getDisenrollmentReasons = async (programId, conditionId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchDisenrollmentReasons(userId, programId, conditionId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setDisenrollmentReasons(response.data.data.reasons)
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handlePWForm = async (e) => {
        e.preventDefault()
        setPWUpdateError("");
        setPWUpdateMessage("");
        if (pathwayFormData.pathwayId === "0") {
            setPWUpdateError("Please select the pathway");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            const formattedDate = (moment(pathwayFormData.hba1cDate).isValid()) ? moment(pathwayFormData.hba1cDate).format('YYYY-MM-DD') : "";

            await assignPathway(userId, pathwayFormData.programId, pathwayFormData.conditionId, pathwayFormData.oldPathwayId, pathwayFormData.pathwayId, pathwayFormData.notes, pathwayFormData.hba1c, formattedDate, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        getMemberBasicInfo();
                        setPWUpdateMessage(response.data.data.message);
                        setPathwayFormData(pathwayObject);
                        //getMemberServices(userId);
                        setShowPWUpdateForm(false);
                        callPathwayChangeLogs();
                        setRefreshVar(new Date().getTime());
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleAddWeightForm = async () => {
        setWeightUpdateError("");
        setWeightUpdateMessage("");
        if (weight === "") {
            setWeightUpdateError("Please enter weight");
        } else if (height === "") {
            setWeightUpdateError("Please enter height");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addWeight(userId, weight, height, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setWeight("");
                        setHeight("");
                        setBMI("");
                        setBMIObservation("");
                        getMemberBasicInfo(userId);
                        setShowAddWeightModal(false);
                        setWeightUpdateMessage(response.data.data.message);
                        setRefreshVar(new Date().getTime());
                    } else {
                        setWeightUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleDisEnrollment = async () => {
        setDisEnrollmentError("");
        setDisEnrollmentMessage("");
        if (disEnrollmentNote === "" || disEnrollmentReason == "") {
            setDisEnrollmentError("Please enter your notes and select reason why the member wants de-enrollment.");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await disEnrollRequest(userId, disEnrollmentNote, disEnrollmentReason, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setDisEnrollmentNote("");
                        getMemberBasicInfo(userId);
                        setDisEnrollmentMessage(response.data.data.message);
                    } else {
                        setDisEnrollmentError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }


    const handleAltMobileUpdate = async () => {
        setAltMobileUpdateError("");
        setAltMobileUpdateMessage("");
        if (alternateMobileNumber === "") {
            setAltMobileUpdateError("Please enter the new alternate mobile number .");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await updateAltMobile(userId, alternateMobileNumber, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setAlternateMobileNumber("");
                        setAltMobileUpdateMessage(response.data.data.message);
                        getMemberBasicInfo(userId);
                        setShowAltMobileModal(false);
                    } else {
                        setAltMobileUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const formatDateTime = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };


    // submit disposition 
    async function submitDisposition(e) {
        e.preventDefault();
        setRequestStatus({ ...requestStatus, isDispositionFormLoading: true });
        try {
            await cn_call_disposition({ confNumber, userId, notes: dispositionForm.notes, nextScheduleDate: formatDateTime(dispositionForm.callbackDateAndTime), callStatus: dispositionForm.callStatus });
            setShowDisposition(false);
            setDispositionForm({
                callStatus: "",
                notes: "",
                callbackDateAndTime: ""
            });
        } catch (e) { }
        setRequestStatus({ ...requestStatus, isDispositionFormLoading: false });
    }

    const [showMemberProfileDetailsModal, setShowMemberProfileDetailsModal] = useState(false);
    const handleShowMemberProfileDetailsModal = () => {
        setShowModal(true);
    }


    const [showHealthLockerModal, setShowHealthLockerModal] = useState(false);
    const handleShowHealthLockerModal = () => {
        setShowHealthLockerModal(true);
    }
    const handleCloseHealthLockerModal = () => setShowHealthLockerModal(false);


    // get reasons list 
    async function getDispositionReasonsList() {
        let result;

        try {
            result = await data_disposition_list();
        } catch (e) { }

        if (result && result.data?.code === 200) {
            setDispositionReasons(result.data.data.disposition || []);
        }
    }

    const openRespectiveServiceModal = (sr) => {
        setSelectedServiceRecord(sr);
        handleShowMedicationModalServiceWise(sr.serviceRequestType);
    }

    const callMemberServicesMonthCategoryWiseFunc = () => {
        MemberServicesRef.current.getMemberServicesMonthCategoryWise(userId);
    };

    const callHomeBasedServicesHistory = () => {
        HomeBasedServicesHistoryRef.current.getHomeBasedServicesHistory(userId);
    }

    const callCallsHistory = () => {
        MemberCallHistoryRef.current.getCallsHistory(userId);
    }

    const callServiceEventHistory = () => {
        EventHistoryRef.current.getServiceEventHistory(userId);
    }

    const callPathwayChangeLogs = () => {
        PathwayChangeLogsRef.current.getPathwayChangeLogs(userId);
    }

    const handleAddWeightHeight = (e) => {
        const { name, value } = e.target;
        if (name == "weight" || name == "height") {
            if (!/^\d*\.?\d*$/.test(value)) {
                return false;
            }
            if (name === "weight") {
                setWeight(value);
            }
            if (name == "height") {
                setHeight(value)
            }
        }

    }

    return (
        <>
            <div id="content-wrapper" className="ContentDashboard">
                <div className="content-dashboard mb-3">
                    <div className="container-fluid CNDashboardSection">
                        <TopBar userTabs={userTabs} closeUserTab={closeUserTab} />
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <div className='col-md-12 mt-3'>
                                        <div >
                                            <div className="profile-card">
                                                <div className='row align-items-center'>
                                                    <div className='col-md-auto'>
                                                        <div className="profile-image">
                                                            <img src={memberBasicInfo.imageUrl} alt="Profile Image" />
                                                        </div>
                                                    </div>

                                                    <div className='col-md ps-2'>
                                                        <div className='row '>
                                                            <div className='col-md'>
                                                                <div className="profile-info">
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <Link onClick={handleShowMemberProfileDetailsModal} className="name">{memberBasicInfo.memberName}</Link>
                                                                            <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                                                {memberBasicInfo.city}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="membership-id"><b>Membership:</b> {memberBasicInfo.membershipNumber}</span>
                                                                            <span className="membership-id"><b>Care Navigator:</b> {(memberBasicInfo.carenavigatorName) ? memberBasicInfo.carenavigatorName : "-"}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="gender big-text me-3"><i className="" aria-hidden="true"><img src={memberBasicInfo.gender == "M" ? require("../../../images/cn/icon-gender-male.png") : require("../../../images/cn/icon-gender.png")} style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.gender == "M" ? "Male" : "Female"} {memberBasicInfo.age}</span>
                                                                            <span className="physical big-text me-3"><i className="" aria-hidden="true"><img src={require("../../../images/cn/icon-height.png")} style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.height} cms</span>
                                                                            <span className="physical big-text me-3"><i className="" aria-hidden="true"><img src={require("../../../images/cn/icon-weight.png")} style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.weight} kgs</span>
                                                                            <span className="physical big-text"><i className="" aria-hidden="true"><img src={require("../../../images/cn/icon-bmi.png")} style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.bmi}</span>
                                                                            <span className="physical big-text"><i className="" aria-hidden="true"><img src={require("../../../images/cn/icon-hba1c.png")} style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.hba1c}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="email"><i className="fa fa-envelope pad-right-10" aria-hidden="true"></i>{memberBasicInfo.email}</span>
                                                                            <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                                                                {memberBasicInfo.mobile}</span>
                                                                            <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                                                                {(memberBasicInfo.altMobile) ? memberBasicInfo.altMobile : "-"}</span>
                                                                            <span>
                                                                                {
                                                                                    memberBasicInfo.readonly === "N" && <i className="fa fa-pencil" aria-hidden="true" onClick={() => {
                                                                                        setAltMobileUpdateMessage("");
                                                                                        setAltMobileUpdateError("");
                                                                                        setAlternateMobileNumber("");
                                                                                        setShowAltMobileModal(true);
                                                                                    }}></i>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="dates"><b>Policy Start Date:</b> {memberBasicInfo.policyStartDate} - <b>Policy End Date:</b> {memberBasicInfo.policyEndDate} |</span>
                                                                            <span className="membership-id"><b>HOF:</b> {memberBasicInfo.hof}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="membership-id"><b>Contract No:</b> {memberBasicInfo.contractNumber} |</span>
                                                                            <span className="dates"><b>Contract Name:</b> {memberBasicInfo.contractName}</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            {
                                                                (memberBasicInfo.hraScore) ?
                                                                    <div className='col-md-2 ps-0 align-self-center'>
                                                                        <div className="profile-info">
                                                                            <div className="risk-badge">
                                                                                <div className="risk-circle" style={{ backgroundColor: memberBasicInfo.hraColor }}>{memberBasicInfo.hraScore}<br /><span>HRA</span></div>
                                                                                <div className="risk-label" style={{ color: memberBasicInfo.hraColor }}>{memberBasicInfo.hraObservation}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ""
                                                            }


                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md'>
                                                                {
                                                                    memberPathways.map((item, index) => {
                                                                        return <>
                                                                            <div className="profile-info mt-2">
                                                                                <div className="info-line">
                                                                                    <span className="program" ><b>{item.programName} /  {item.conditionName}</b></span>
                                                                                </div>
                                                                                <div className="info-line">
                                                                                    {
                                                                                        (item.pathwayName !== "Pending") ?
                                                                                            <>
                                                                                                <span className="status">Pathway: <Link style={{ textDecoration: "underline", fontWeight: "500" }} onClick={(e) => handleShowCarePlanModal(item.pathwayId)}>{item.pathwayName}</Link></span>
                                                                                                {
                                                                                                    memberBasicInfo.readonly === "N" && <i className="fa fa-pencil" aria-hidden="true" onClick={() => getPathways(item.programId, item.conditionId, item.pathwayId)}></i>
                                                                                                }

                                                                                                <br />
                                                                                                <span className={`${item.currentPathwayMonth === "" ? "hide" : "status"}`}>Current Pathway Month: {item.currentPathwayMonth}</span>
                                                                                            </> :
                                                                                            <>
                                                                                                <span className="status">Pathway: {item.pathwayName}</span>
                                                                                                {
                                                                                                    memberBasicInfo.readonly === "N" && <i className="fa fa-pencil" aria-hidden="true" onClick={() => getPathways(item.programId, item.conditionId, item.pathwayId)}></i>
                                                                                                }
                                                                                                <br />
                                                                                                <span className={`${item.currentPathwayMonth === "" ? "hide" : "status"}`}>Current Pathway Month: {item.currentPathwayMonth}</span>
                                                                                            </>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-12 mt-3'>
                                                        <div className="button-row">
                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle action-button blue-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src={require("../../../images/cn/icon-ViewServices.png")} style={{ height: "12px" }} className='me-2' alt="" />Phone Call
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {/* <li onClick={e => openOutGoingCallWindow({ userId, memberName: memberBasicInfo.memberName, mobileNo: memberBasicInfo.mobile })}
                                                                className={`${showCallBlock ? 'disabled' : ''}`}
                                                            ><a className="dropdown-item" style={{ "cursor": `${showCallBlock ? 'default' : 'pointer'}` }}
                                                            >Audio Call</a></li> */}
                                                                        <li className={`${showCallBlock ? 'disabled' : ''}`}
                                                                        ><a className="dropdown-item" style={{ "cursor": `${showCallBlock ? 'default' : 'pointer'}` }}
                                                                            data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"
                                                                        >Audio Call</a></li>
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"
                                                                        >Video Call</a></li>
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"
                                                                        >Phone call </a></li>

                                                                        {/* upcomingFeatureModal */}
                                                                    </ul>
                                                                </div>
                                                            }

                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <button className="action-button blue-btn" onClick={handleShowWeightModal}><img src={require("../../../images/cn/icon-AddWeight.png")} style={{ height: "12px" }} className='me-2' alt="" />Add Weight</button>
                                                            }


                                                            <button className="action-button blue-btn" onClick={handleShowHRAModal}><img src={require("../../../images/cn/icon-TakeHRA.png")} style={{ height: "12px" }} className='me-2' alt="" />Health Assessment</button>

                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle action-button blue-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src={require("../../../images/cn/icon-TakeHRA.png")} style={{ height: "12px" }} className='me-2' alt="" />Home Based Services
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Medication Request")}>Medication Request</a></li>
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Lab Request")}>Lab Request</a></li>
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Home Care Request")}>Home Care Request</a></li>
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Home Based Vaccine")}>Home Based Vaccine</a></li>
                                                                    </ul>
                                                                </div>
                                                            }

                                                            <button className="action-button blue-btn" onClick={handleShowHealthLockerModal}><img src={require("../../../images/cn/icon-TakeHRA.png")} style={{ height: "12px" }} className='me-2' alt="" />Health Locker </button>
                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <button className="action-button red-btn" onClick={handleShowDisEnrollmentModal}><img src={require("../../../images/cn/icon-De-enroll.png")} style={{ height: "12px" }} className='me-2' alt="" />De-enroll</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <MemberCalls memberBasicInfo={memberBasicInfo} userId={userId} callCallsHistory={callCallsHistory} />
                                    <MemberServices openRespectiveServiceModal={openRespectiveServiceModal} memberBasicInfo={memberBasicInfo} userId={userId} ref={MemberServicesRef} handleMemberBulkHomeCareRequest={handleMemberBulkHomeCareRequest} callServiceEventHistory={callServiceEventHistory} />
                                    <HomeBasedServicesHistory memberBasicInfo={memberBasicInfo} ref={HomeBasedServicesHistoryRef} />
                                    <MemberCallHistory ref={MemberCallHistoryRef} />
                                    <EventHistory memberBasicInfo={memberBasicInfo} ref={EventHistoryRef} />
                                    <PathwayChangeLogs ref={PathwayChangeLogsRef} />
                                    {
                                        memberBasicInfo.showHRAGraph === "Y" &&
                                        <div className='col-md-12 mt-3'>
                                            <Column columnGraphTitle="HRA History" columnGraphType="hra_history" userId={userId} />
                                        </div>
                                    }
                                    {
                                        memberBasicInfo.showWeightGraph === "Y" &&
                                        <div className='col-md-12 mt-3'>
                                            <Line lineGraphTitle="Weight History" lineGraphType="weight_history" userId={userId} refreshVar={refreshVar} />
                                        </div>
                                    }
                                    {
                                        memberBasicInfo.showHBA1CGraph === "Y" &&
                                        <div className='col-md-12 mt-3'>
                                            <Line lineGraphTitle="HBA1C History" lineGraphType="hba1c_history" userId={userId} refreshVar={refreshVar} />
                                        </div>
                                    }


                                </Row>
                            </Col>
                            <Col md={4}>
                                <Chat />
                            </Col>
                        </Row >
                        <Row>
                            <Col>
                                <HealthManagement memberBasicInfo={memberBasicInfo} showHRAModal={showHRAModal} handleShowHRAModal={handleShowHRAModal} handleCloseHRAModal={handleCloseHRAModal} getMemberBasicInfo={getMemberBasicInfo} callServiceEventHistory={callServiceEventHistory} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormsModal header={modalTitle} memberBasicInfo={memberBasicInfo} showHRAModal={showMedicationModal} handleShowMedicationModal={handleShowMedicationModal} selectedServiceRecord={selectedServiceRecord} handleCloseHRAModal={handleCloseMedicationModal} callHomeBasedServicesHistory={callHomeBasedServicesHistory} selectedBulkServices={selectedBulkServices} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    showCarePlanModal && <CarePlanChange pathwayId={modalPathwayName} showCarePlanModal={showCarePlanModal} handleShowCarePlanModal={handleShowCarePlanModal} handleCloseCarePlanModal={handleCloseCarePlanModal} />
                                }
                            </Col>
                        </Row>




                        {showModal && <AddMember status="view" heading="View Member Details" hideModal={() => setShowModal(false)} />}


                        {showHealthLockerModal && <HealthLocker memberBasicInfo={memberBasicInfo} showHealthLockerModal={showHealthLockerModal} onHide={handleShowHealthLockerModal} handleCloseHealthLockerModal={handleCloseHealthLockerModal} />}




                        <Modal id="pathwayUpdate" show={showPWUpdateForm} centered onHide={() => setShowPWUpdateForm(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Pathway Status</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Select Pathway: <span className='required'>*</span></label>
                                        <select name="pathwayId" className="form-select select-input-ui" value={pathwayFormData.pathwayId} onChange={(e) => setPathwayFormData({
                                            ...pathwayFormData,
                                            ['pathwayId']: e.target.value
                                        })}>
                                            <option value="0">Select</option>
                                            {
                                                pathways.map((item, index) => {
                                                    return <option key={index} value={item.pathwayId}>{item.pathwayName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label className="form-label">HBA1c:</label>
                                        <input type='text' name="hba1c" value={pathwayFormData.hba1c} className='form-control control-input-ui' placeholder='Enter HBA1c' onChange={(e) => setPathwayFormData({
                                            ...pathwayFormData,
                                            ['hba1c']: e.target.value
                                        })} />
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className="col-auto">
                                            <label className="form-label">Date:</label>

                                        </div>
                                        <div className="col">

                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                //minDate={new Date()}
                                                className='form-control control-input-ui'
                                                selected={pathwayFormData.hba1cDate || null} // Ensure null when no date is selected
                                                placeholderText="Enter Date" // Use placeholderText for DatePicker
                                                name="hba1cDate"
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    setPathwayFormData({
                                                        ...pathwayFormData,
                                                        hba1cDate: date,
                                                    });
                                                }}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />

                                        </div>
                                    </div>

                                    <div className='col-12 mb-3'>

                                        <label className="form-label">Remarks:</label>
                                        <textarea name="notes" className='form-control control-input-ui' placeholder='Enter Remarks' onChange={(e) => setPathwayFormData({
                                            ...pathwayFormData,
                                            ['notes']: e.target.value
                                        })} value={pathwayFormData.notes}></textarea>
                                    </div>

                                </div>


                                <div className='col-12 text-center'>
                                    <div className={`${pwUpdateError != "" ? "errorDiv" : "hide"} `}>{pwUpdateError}</div>
                                    <div className={`${pwUpdateMessage != "" ? "messageDiv" : "hide"}`}>{pwUpdateMessage}</div>
                                    <button type="button" className="btn btn-primary save-btn me-3" onClick={handlePWForm}>Save</button>

                                </div>

                            </Modal.Body>
                        </Modal>

                        {/*Weight Modal*/}
                        <Modal id="weightUpdate" show={showAddWeightModal} onHide={() => setShowAddWeightModal(false)} centered size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Add Weight</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <label className="form-label">Weight (in kgs):</label>
                                        <input type="text" value={weight} name="weight" className='form-control control-input-ui' onChange={handleAddWeightHeight} />
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label className="form-label">Height (in cms):</label>
                                        <input type="text" value={height} name="height" className='form-control control-input-ui' onChange={handleAddWeightHeight} />
                                    </div>
                                    <div className='col-12'>
                                        <span className={`${bmiObservation !== "" ? "" : "hide"}`}>BMI: {bmi}  ({bmiObservation})</span>
                                    </div>
                                    <div className='col-12 text-center'>
                                        <div className={`${weightUpdateError != "" ? "errorDiv" : "hide"}`}>{weightUpdateError}</div>
                                        <div className={`${weightUpdateMessage != "" ? "messageDiv" : "hide"}`}>{weightUpdateMessage}</div>

                                        <button className="btn btn-primary save-btn me-3" onClick={handleAddWeightForm}>Save</button>

                                    </div>
                                </div>


                            </Modal.Body>
                        </Modal>
                        <Modal id="disEnrollment" centered show={showDisEnrollmentModal} onHide={() => setShowDisEnrollmentModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>De-enroll</h1>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    (memberBasicInfo.pendingDisEnrollmentRequest == "Y") ?
                                        <div className='text-center'>
                                            The request you raised is pending for approval.<br /><br />
                                            <button className="btn btn-primary cancel-btn" onClick={() => {
                                                setShowDisEnrollmentModal(false);
                                            }}>Close</button>
                                        </div>
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>

                                                    <label className="form-label">Reason:<span className='text-danger'>*</span></label>
                                                    {/*<select className="form-select select-input-ui" aria-label="Default select example" value={disEnrollmentReason} onChange={(e) => setDisEnrollmentReason(e.target.value)}>
                                                        <option value="">Select</option>
                                                        <option value="No Longer Interested">No Longer Interested</option>
                                                        <option value="Health Deteriorated">Health Deteriorated</option>
                                                        <option value="Relocated">Relocated</option>
                                                        <option value="Financial Reasons">Financial Reasons</option>
                                                        <option value="Others">Others</option>
                                                    </select>*/}
                                                    <select className="form-select select-input-ui" aria-label="Default select example" value={disEnrollmentReason} onChange={(e) => setDisEnrollmentReason(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {
                                                            disenrollmentReasons.map((item, index) => {
                                                                return <option value={item.recordId}>{item.reason}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <label>Remarks:<span className='text-danger'>*</span></label>
                                                    <textarea name="disEnrollmentNote" className='form-control control-input-ui' onChange={(e) => setDisEnrollmentNote(e.target.value)} value={disEnrollmentNote}></textarea>
                                                </div>
                                                <div className='col-12 text-center'>
                                                    <div className={`${disEnrollmentError != "" ? "errorDiv" : "hide"}`}>{disEnrollmentError}</div>
                                                    {/* <div className={`${disEnrollmentMessage != "" ? "messageDiv" : "hide"}`}>{disEnrollmentMessage}</div> */}
                                                    <button className="btn btn-primary save-btn me-3" onClick={handleDisEnrollment}>Save</button>
                                                </div>
                                            </div>



                                        </>
                                }


                            </Modal.Body >
                        </Modal >


                        <Modal id="memberCallDispositionModal" show={showDisposition} centered onHide={() => setShowDisposition(false)} size="md" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title' style={{ width: "90%" }}>{/*selectedServiceRecord.serviceName*/}</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <               div className='member-engagement mb-3 coach-call-box-1'>
                                    <div className='row align-items-center'>
                                        <div className='col-auto'>
                                            <h5 className="user-card-title" >{memberBasicInfo.memberName}</h5>
                                            <h5 className="user-card-subtitle">Member ID: {memberBasicInfo.membershipNumber}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='member-engagement mb-3'>
                                    <div className="row align-items-center">
                                        <div className="col-md-12  mb-3">
                                            <h6 className="call-disposition-title">Call Status</h6>

                                            <select
                                                className="form-select input-field-ui"
                                                id="SelectProgram"
                                                aria-label="Default select example"
                                                value={dispositionForm.callStatus}
                                                onChange={e => setDispositionForm({ ...dispositionForm, callStatus: e.target.value })}
                                            >
                                                <option>Select</option>
                                                {
                                                    dispositionReasons.map((reason) => (
                                                        <option value={reason}>{reason}</option>
                                                    ))
                                                }
                                            </select>


                                            <div className="form-group mt-3">
                                                <h6 className="call-disposition-title">Remarks</h6>
                                                <textarea
                                                    name="notes"
                                                    placeholder="Remarks"
                                                    className='form-control control-input-ui'
                                                    value={dispositionForm.notes}
                                                    onChange={e => setDispositionForm({ ...dispositionForm, notes: e.target.value })}
                                                >
                                                </textarea>
                                            </div>


                                        </div>

                                        <div className="col-auto">
                                            <label htmlFor='inputdate' className="col-form-label">Schedule a Callback:</label>
                                        </div>
                                        <div className="col">
                                            <DatePicker
                                                selected={dispositionForm.callbackDateAndTime}
                                                onChange={update => setDispositionForm({ ...dispositionForm, callbackDateAndTime: update })}
                                                showTimeSelect
                                                timeIntervals={15}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                placeholderText="Select Date and Time"
                                                className='form-control control-input-ui'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <button type="button" className="btn btn-primary save-btn"
                                            onClick={e => submitDisposition(e)}
                                            disabled={requestStatus.isDispositionFormLoading ? "Disabeld" : ""}
                                        >{requestStatus.isDispositionFormLoading ? "Saving..." : "Save"}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal id="altMobileModal" centered show={showAltMobileModal} onHide={() => setShowAltMobileModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Update Alternate Mobile Number</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Alternate Mobile Number:</label>
                                        <input type="text" value={alternateMobileNumber} name="alternateMobileNumber" className='form-control control-input-ui' onChange={(e) => setAlternateMobileNumber(e.target.value)} />
                                    </div>
                                    <div className='col-12 text-center'>
                                        <div className={`${altMobileUpdateError != "" ? "errorDiv" : "hide"}`}>{altMobileUpdateError}</div>
                                        <div className={`${altMobileUpdateMessage != "" ? "messageDiv" : "hide"}`}>{altMobileUpdateMessage}</div>
                                        <button className="btn btn-primary save-btn me-3" onClick={handleAltMobileUpdate}>Save</button>
                                    </div>
                                </div>
                            </Modal.Body >
                        </Modal >
                    </div>
                </div>
            </div>
        </>
    )
}

export default Members