import React, { useState } from 'react';
import './CorporateDetail.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import CorporatesManageDetails from './component/ManageCorp/CorporatesManageDetails';
import DemographicsGender from './component/ManageCorp/DemographicsGender';
import DemographicsAgeGroup from './component/ManageCorp/DemographicsAgeGroup';
import HealthGoalsChart from './component/ManageCorp/HealthGoalsChart';
import StepsChart from './component/ManageCorp/StepsChart';
import WaterChart from './component/ManageCorp/WaterChart';
import SleepChart from './component/ManageCorp/SleepChart';


function CorporateDetail () {

  const handleBackButton = () => {
    navigate('/manage-corporates');
  }

  const navigate = useNavigate();

  const corporateManageCorporate = [
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/79550_1727348575.jpg',
      corpTItleManageCorporate: 'Indian Oil Corporation',
    },
  ];
  
  return (
    <>
      <section className='CorporateDetail '>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                  <h1 className="main-title" style={{ margin: '0' }}>Manage Corporates Details</h1>
              </div>
              <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn me-2" to="/create-groups">Create Groups</Link> <Link className="btn btn-primary add-new-program-btn" to="/create-challenges">Create Challenge</Link></div>

          </div>

          <div className='corporate-detail p-3 mb-3'>

            <div className="row align-items-center">
            {corporateManageCorporate.map((statEmp, index) => (
                <>
                <div className="col-md-auto">
               
                        <div  className="corporate-profile-imageContainer" key={index} >
                                <img src={statEmp.corpImgManageCorporate} className="corporate-profile-img mb-0" alt="" />
                        </div>
                
                </div>
                <div className="col-md-3">
                  <h1 className="dashboard-title">{statEmp.corpTItleManageCorporate}</h1>
                </div>
                </>
                ))}
                <div className="col-md">
                    <CorporatesManageDetails/>
                </div>

            </div>
          </div>

          <div className='row'>
              <div>
                <h1 className="main-title mt-2">Demographics</h1>
              </div>
              <div className='col-md-4'>
                  <div className='corporate-detail p-3 mb-3'>
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="corporate-detail-title">Gender</h1>
                        </div>
                        <div className='col-md-12'>
                          <DemographicsGender/>
                        </div>
                    </div>
                  </div>
              </div>
              <div className='col-md-8'>
                  <div className='corporate-detail p-3 mb-3'>
                      <div className="row">
                        <div className="col-md-12">
                          <h1 className="corporate-detail-title">Age Group</h1>
                        </div>
                        <div className='col-md-12'>
                          <DemographicsAgeGroup/>
                        </div>
                    </div>
                  </div>
              </div>
              </div>


              <div className='row'>
                <div>
                  <h1 className="main-title mt-2">Health Goals</h1>
                </div>
                <div className='col-md-12'>
                    <div className='corporate-detail p-3 mb-3'>
                        <div className="row">
                          <div className='col-md-12'>
                            <HealthGoalsChart/>
                          </div>
                      </div>
                    </div>
                </div>
              </div>


              <div className='row'>
                <div>
                  <h1 className="main-title mt-2">Daily Target Goals</h1>
                </div>

                <div className='col-md-4'>
                    <div className='corporate-detail p-3 mb-3'>
                        <div className="row">
                          <div className="col-md-12">
                              <h1 className="dashboard-corpwell-title mb-0">Steps</h1>
                              <h2 className="dashboard-corpwell-subtitle">Average Steps: <span>8,930</span></h2>
                          </div>
                          <div className='col-md-12'>
                            <StepsChart/>
                          </div>
                      </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className='corporate-detail p-3 mb-3'>
                        <div className="row">
                          <div className="col-md-12">
                              <h1 className="dashboard-corpwell-title mb-0">Water</h1>
                              <h2 className="dashboard-corpwell-subtitle">Average Ltr: <span>3.5</span></h2>
                          </div>
                          <div className='col-md-12'>
                            <WaterChart/>
                          </div>
                      </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className='corporate-detail p-3 mb-3'>
                        <div className="row">
                          <div className="col-md-12">
                              <h1 className="dashboard-corpwell-title mb-0">Sleep</h1>
                              <h2 className="dashboard-corpwell-subtitle">Average Sleep: <span>8hr</span></h2>
                          </div>
                          <div className='col-md-12'>
                            <SleepChart/>
                          </div>
                      </div>
                    </div>
                </div>

              </div>

              


        </div>
      </section>

    </>
  );
}

export default CorporateDetail ;
