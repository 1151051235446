import React from 'react';
import './CorporatesEmployeesOnboarded.css';


const CorporatesEmployeesOnboarded = () => {
  const stats = [
    { count: '248', label: 'Total Corporates Onboarded', bgColor: 'bg-grey', icon: 'https://appcdn.goqii.com/storeimg/97789_1717060338.png' },
    { count: '1,378', label: 'Employees Onboarded', bgColor: 'bg-grey', icon: 'https://appcdn.goqii.com/storeimg/50061_1717060546.png' },
  ];

  return (
    <>
    <section className='CorporatesEmployeesOnboarded'>
        <div className=" row text-center">
        {stats.map((stat, index) => (
                <div className="col px-1" key={index}>
                    <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                        <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto ps-2">
                                <img src={stat.icon} alt={`${stat.label} icon`} className="card-icon mb-0" />
                            </div>
                            <div className="col px-0">
                                <h1 className="card-title">{stat.count}</h1>
                                <h5 className="card-text">{stat.label}</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </section>
    </>
    
  );
};

export default CorporatesEmployeesOnboarded;
