import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import iconTickGreen from "../../../images/icon-tick-green.png";
import iconTickGrey from "../../../images/icon-tick-grey.png";
import iconTickBlueD from "../../../images/icon-tick-blue-d.png";
import iconCrossRed from "../../../images/icon-cross-red.png";
import iconTickGreyD from "../../../images/icon-tick-grey-d.png";
import { Link } from 'react-router-dom';
import { fetch_uploaded_user_batch_list, fetch_user_attempt_call_list } from '../../../services/met/Service';
import { LoaderLoader, formatDate,exportTableData,exportExcelTableData,exportPdfTableData } from "../../utils/CommonLibrary";
import MemberCallAttemptList from '../../met/components/MemberCallAttemptList';




function MemberEngagementListTable({startDate,endDate}) {
    //const { migrationId } = useParams(); 
    const [searchQuery, setSearchQuery] = useState('');
    const[isLoading,setIsLoading]= useState(false);
    const [userBatchList, setUserBatchList] = useState([]);
    const [showMemberList, setShowMemberList] = useState([]);
    const [showModalNew, setShowModalNew] = useState(false);
    const handleCloseNew = () =>setShowModalNew(false);

    useEffect(() => {
        fetchUploadedUserBatchList();
    }, [startDate,endDate]);

    const openListModal = async(userid) =>{
        setIsLoading(true); //to show loader
            try {
                let sdate = formatDate(startDate);
                let edate = formatDate(endDate);
                let resp = await fetch_user_attempt_call_list(sdate,edate,userid);
                setShowModalNew(true);
                if (resp.data.code == 200) {
                    setShowMemberList(resp.data.data);
                }else{
                    setShowMemberList(resp.data.data);
                }
            } catch (error) {
                console.error(error);
            }finally {
              setIsLoading(false); // Will be called regardless of success or failure
            }
    }

const fetchUploadedUserBatchList = async() =>{
    setIsLoading(true); //to show loader
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let resp = await fetch_uploaded_user_batch_list(sdate,edate);
        if (resp.data.code == 200) {
          setUserBatchList(resp.data.data);
        }else{
            setUserBatchList(resp.data.data);
        }
    } catch (error) {
        console.error(error);
    }finally {
      setIsLoading(false); // Will be called regardless of success or failure
    }
}
    const columns = [
        // {
        //     name: 'Created Date',
        //     selector: row => row.createdTime,
        //     sortable: true,
        //     width: '150px',
        // },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            sortable: true,
        },
       
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
            width: '150px', // Set width for Member ID column
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
            
        },
        {
            name: 'Member Mobile No',
            selector: row => row.memberMobile,
            sortable: true,
            
        },
        {
            name: 'Network Type',
            selector: row => row.networkType,
            sortable: true,
            
        },

        {
            name: 'Contract No',
            selector: row => row.contractNumber,
            sortable: true,
        },
        {
            name: 'Contract Name',
            selector: row => row.contractName,
            sortable: true,
        },
        {
            name: 'Policy Start Date',
            selector: row => row.policyStartDate,
            sortable: true,
        },

        {
            name: 'Policy End Date',
            selector: row => row.policyEndDate,
            sortable: true,
        },

        {
            name: 'Contract Termination Date',
            selector: row => row.policyEndDate,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.policyStatus,
            sortable: true,
        },
        {
            name: 'Requested Medication',
            selector: row => row.requestedMedication,
            sortable: true,
        },

        {
            name: 'Member Gender',
            selector: row => row.gender,
            sortable: true,
        },

        {
            name: 'Age',
            selector: row => row.dob,
            sortable: true,
        },

        {
            name: 'Chronic',
            selector: row => row.chronic,
            sortable: true,
        },

        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
       
        {
            name: 'HOF',
            selector: row => row.HOF,
            sortable: true,
        },

        {
            name: 'Dependant',
            selector: row => row.memberDependant,
            sortable: true,
        },
        {
            name: 'Member Response',
            selector: row => row.enrollStatus,
            sortable: true,
        },
        {
            name: 'Enrollment Date',
            selector: row => row.enrollmentDate ? <span>{row.enrollmentDate}</span> :  <>-</>,
            sortable: true,
        },
        {
            name: 'Care navigator Booking Date',
            selector: row => row.bookingDate ? <span>{row.bookingDate}</span> :  <>-</>,
            sortable: true,
        },
        {
            name: 'Data Quality',
            selector: row => row.dataRating,
            sortable: true,
        },

        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
        },
        {
            name: 'CE- Feedback',
            selector: row => row.ceReason,
            sortable: true,
        },
        {
            name: 'Migration Type',
            selector: row => row.migrationType,
            sortable: true,
        },
        {
            name: 'Last Action Date',
            selector: row => row.lastPoolSentTime,
            sortable: true,
        },
    ];

    const data = userBatchList;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const filteredData = data.filter(item => {
        const matchesSearchQuery = 
        (item.memberName && item.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.membershipNo && item.membershipNo.toLowerCase().includes(searchQuery.toLowerCase()))
   
        return matchesSearchQuery;
    });

    const handleExport = (downloadType) => {
        if(downloadType === 'csv'){
            const modifiedData = filteredData.map(item => {
                return {
                    'Member ID': item.membershipNo,
                    'Member Name': item.memberName,
                    'Member Age': item.dob,
                    'Member Gender': item.gender,
                    'Agent Name': item.agentName,
                    'Last Contacted Date': item.agentContactDate,
                    // 'No. Of Attempts': item.callAttempt,
                    'Member Response': item.enrollStatus,
                    'Enrollment Date': item.enrollmentDate,
                    'Care Navigator Booking Date': item.bookingDate,
                    'Call Notes': item.notes,
                    'Contract Name': item.contractName,
                    'Contract Number': item.contractNumber,
                    'Polciy Start Date': item.policyStartDate,
                    'Polciy End Date': item.policyEndDate,
                    'Member City': item.status
                };
            });
            modifiedData.forEach(function(v){ delete v.userId; });
            exportTableData("Member_Engagement",modifiedData);
        }else if(downloadType === 'excel'){
            const modifiedData = filteredData.map(item => {
                return {
                    'Agent Name': item.agentName,
                    'Membership No.': item.membershipNo,
                    'Member Name': item.memberName,
                    'Member Mobile No': item.memberMobile,
                    'Network Type': item.networkType,
                    'Contract No': item.contractNumber,
                    'Contract Name': item.contractName,
                    'Policy Start Date': item.policyStartDate,
                    'Policy End Date': item.policyEndDate,
                    'Contract Termination Date': item.contractTerminationDate,
                    'Status': item.policyStatus,
                    'Requested Medication': item.requestedMedication,
                    'Gender': item.gender,
                    'Age': item.dob,
                    'Chronic': item.chronic,
                    'City': item.city,
                    'HOF': item.HOF,
                    'Dependant': item.memberDependant,
                    'Member Response': item.enrollStatus,
                    'Enrollment Date': item.enrollmentDate,
                    'Care Navigator Booking Date': item.bookingDate,
                    'Data Quality': item.dataRating,
                    'Reason': item.reason,
                    'CE - Feedback': item.ceReason,
                    'Migration Type': item.migrationType,
                    'Action Date': item.lastPoolSentTime
                };
            });
            modifiedData.forEach(function(v){ 
                delete v.userId;
              });
            exportExcelTableData("member_engagement_manual",modifiedData);

        }else if(downloadType === 'pdf'){
            let i=1;
            const dataArray = filteredData.map(item => [
                i++,
                item.membershipNo,
                item.memberName,
                item.dob,
                item.gender,
                item.agentName,
                item.agentContactDate,
                item.callAttempt,
                item.enrollStatus,
                item.enrollmentDate,
                item.bookingDate,
                item.notes,
                item.contractName,
                item.contractNumber,
                item.policyStartDate,
                item.policyEndDate,
                item.city
              ]);
            
            const columns = [
                'Sr. No',
                'Member ID',
                'Member Name',
                'Member Age',
                'Member Gender',
                'Agent Name',
                'Last Contacted Date',
                'No. Of Attempts',
                'Member Response',
                'Enrollment Date',
                'Care Navigator Booking Date',
                'Call Notes',
                'Contract Name',
                'Contract Number',
                'Policy Start Date',
                'Policy End Date',
                'Member City'
              ];
             exportPdfTableData("Member_Engagement",dataArray,columns);
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3 align-items-center'>
                    {/* <div className='col-md px-1'>
                        <h3 className='StatusOngoing mb-0'>
                            <i className="bi bi-record-fill dot-ongoing"></i> Status Ongoing 
                            <span>20th March 2024 Uploaded Members 5302</span>
                        </h3>
                    </div> */}
                   
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                            <input
                                type="text"
                                placeholder="Search By Member Name or Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                                style={{width:'500px'}}
                            />
                            <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="Search Icon" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1 text-end'>
                        <div className="dropdown">
                            <button className="btn btn-primary btn-primary clear-search-btn " type="button"  onClick={(e)=>handleExport('excel')}>
                                <i className="bi bi-download"></i> Export </button>
                            {/* <ul className="dropdown-menu dropdown-menu-end">
                                <li><button className="dropdown-item" onClick={(e)=>handleExport('excel')}>Excel</button></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
            <LoaderLoader isLoading={isLoading}/>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                customStyles={customStyles}
            />
            



            {/* <!-- Modal --> */}
            <div className="modal fade" id="CallModal" tabindex="1" aria-labelledby="CallModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                
                <div className="modal-content">
                    <div className='coach-call-box'>
                        <div className='row align-items-center'>
                            <div className='col-auto'>
                                <div className="coach-pics-box">
                                    <img src={require("../../../images/icon-profile-image.png")} className="coach-pics-img" alt="" />
                                </div>
                            </div>
                            <div className='col'>
                                    <h2 className='coach-card-title'>Azma Nadaf</h2>
                                    <h3 className='coach-card-subtitle'>00:05</h3>
                            </div>
                            <div className='col'>
                                <div className="coach-call-btn-box">
                                    <button type="button" className="btn btn-dark coach-call-btn"><i className="bi bi-mic-mute"></i></button>
                                    <button type="button" className="btn btn-dark coach-call-btn"><i className="bi bi-volume-up"></i></button>
                                    <button type="button" className="btn btn-dark coach-call-btn"><i className="bi bi-camera-video"></i></button>
                                    <button type="button" className="btn btn-dark coach-call-btn"><i className='bx bx-dialpad-alt'></i></button>
                                </div>
                            </div>
                            <div className='col-auto pe-4'>
                                <button type="button" className="btn btn-dark coach-call-close-btn"><i className='bx bxs-phone'></i></button>
                            </div>
                        </div>
                    </div>
                {/* <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
                <div className="modal-body coach-call-box-1">
                    <div className='row align-items-center border-bottom pb-3'>
                        <div className='col-auto'>
                        <div className="user-pics-box">
                                    <img src={require("../../../images/icon-profile-image.png")} className="user-pics-img" alt="" />
                                </div>
                        </div>
                        <div className='col'>
                            <h5 className="user-card-title" >Fatima Aaira</h5>
                        </div>
                        <div className='col-auto'>
                            <h3 className="user-card-subtitle">Member ID: B65L23</h3>
                            <h3 className="user-card-subtitle">Invited Date: 23 Mar 2024</h3>
                        </div>
                    </div>

                    <div className="row mt-4 justify-content-center">
                        <div className="col-auto text-center px-4">
                            <h2 className='user-card-text'>Email</h2>
                            <img src={iconCrossRed} className="p-2" alt="" />
                        </div>
                        <div className="col-auto text-center px-4">
                            <h2 className='user-card-text'>SMS</h2>
                            <img src={iconTickGreyD} className="p-2" alt="" />
                            <h2 className='user-card-text'><span>23 Mar 2024 <br/> 03:42 PM</span></h2>
                        </div>
                        <div className="col-auto text-center px-4">
                            <h2 className='user-card-text'>Whatsapp</h2>
                            <img src={iconTickBlueD} className="p-2" alt="" />
                            <h2 className='user-card-text'><span>23 Mar 2024 <br/> 03:42 PM</span></h2>
                        </div>
                        <div className="col-auto text-center px-4">
                            <h2 className='user-card-text'>App Login</h2>
                            <img src={iconTickGrey} className="p-2" alt="" />
                            <h2 className='user-card-text'><span>23 Mar 2024 <br/> 03:42 PM</span></h2>
                        </div>
                    </div>

                    <div className="row mt-4 justify-content-center">
                        <div className="col-auto text-center px-4">
                            <h2 className='user-card-text mb-2'>Enrollment Status</h2>
                            <button type='button' className='btn btn-dark coach-call-enroll-btn'>Enroll Now</button>
                            <h2 className='user-card-text mt-2'><span>15 Mar 2024 03:42 PM</span></h2>
                        </div>
                        <div className="col-auto text-center px-4">
                            <h2 className='user-card-text mb-2'>Care Navigator consultation</h2>
                            <button type='button' className='btn btn-dark coach-call-enroll-btn'>Book Now</button> <button type='button' className='btn btn-dark transfer-call-btn'>Enroll Now</button>
                        </div>
                    </div>

                    <div className="row mt-4 justify-content-center">
                        <div className="col-12 text-center">
                            <div className="care-navigator-consultation-box">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="consultation-pics-box">
                                            <img src={require("../../../images/icon-profile-image.png")} className="consultation-pics-img" alt="" />
                                        </div>
                                        <h2 className='user-card-text mt-2 mb-0'>Amira Shaikh</h2>
                                    </div>
                                    <div className="col px-3">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-auto">
                                                <label htmlFor='inputdate' className="col-form-label">Date</label>
                                            </div>
                                            <div className="col-auto">
                                                <input type="date" id="inputdate" className="form-control input-field-ui" />
                                            </div>

                                            <div className="col-12 mt-3">
                                                <label htmlFor='inputtime' className="col-form-label mb-0">Slot:</label>
                                            </div>

                                            <div className="col-12">
                                                <button type='button' className='btn btn-dark slot-btn m-1'>10:30 am</button>
                                                <button type='button' className='btn btn-dark slot-active-btn m-1'>02:00 pm</button>
                                                <button type='button' className='btn btn-dark slot-btn m-1'>04:15 pm</button>
                                                <button type='button' className='btn btn-dark slot-btn m-1'>05:00 pm</button>
                                                <button type='button' className='btn btn-dark slot-btn m-1'>06:00 pm</button>
                                            </div>
                                            
                                            <div className="col-12 mt-3">
                                                <button type='button' className='btn btn-dark transfer-call-btn'>Schedule Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <MemberCallAttemptList
                        showModal={showModalNew}
                        handleClose={handleCloseNew}
                        showMemberList={showMemberList}
                        customStyles={customStyles}
                    />
        </>
    );
}

export default MemberEngagementListTable;
