import React from "react";

const ChallengeTaskHra = ({ recordNum, singleHraData, handleHraInputChange }) => {
  return (
    <div className="tw-max-w-lg tw-bg-gray-200 tw-shadow-md tw-rounded-lg tw-p-6 tw-mb-4">
      <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mb-4">
        <div>
          <label htmlFor={`startDate-${recordNum}`} className="tw-block tw-text-gray-700 tw-mb-1">
            Start Date
          </label>
          <input
            type="date"
            id={`startDate-${recordNum}`}
            name="startDate"
            value={singleHraData.startDate || ""}
            onChange={handleHraInputChange}
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div>

        <div>
          <label
            htmlFor={`endDate-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            End Date
          </label>
          <input
            type="date"
            id={`endDate-${recordNum}`}
            name="endDate"
            value={singleHraData.endDate || ""}
            onChange={handleHraInputChange}
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div>
      </div>
    </div>
  );
};

export default ChallengeTaskHra;
