import React, { useState } from 'react';
import './ManageCorporates.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import ManageCorporatesList from './component/ManageCorporatesList';


function ManageCorporates () {

  const handleBackButton = () => {
    navigate('/manage-corporates');
  }

  const navigate = useNavigate();


  
  return (
    <>
      <section className='ManageCorporates '>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                {/* <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button> */}
                  <h1 className="main-title" style={{ margin: '0' }}>Manage Corporates</h1>
              </div>
              {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testManageCorporates">Test Blocker PopUps</Link></div> */}

          </div>

          <div className='manage-corporates p-3 mb-3'>
          <div className='row mb-3'>
                    
                    <div className='col-md d-flex'>
                    <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Corporate"
                            className="form-control input-field-ui ps-5"
                        />
                        <img src={require("../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-5 px-0'>
                        <select
                            className='form-select input-field-ui'>
                            <option value="All">--Select Corporate--</option>
                            <option value="Call">1</option>
                            <option value="Whatsapp">2</option>
                            <option value="SMS">3</option>
                            <option value="Email">4</option>
                            <option value="Push Notification">5</option>
                        </select>
                    </div>
                    <div className='col-md-auto'>
                        <button className='btn btn-primary btn-primary clear-search-btn'>Submit</button>
                    </div>
                </div>
              
              <div className="row">
                  <ManageCorporatesList/>
              </div>
              <div className="row mt-5 align-items-center">
                  <div className="col">
                    <h1 className="dashboard-corpwell-title mb-0">Showing 1 to 5 of 5 entries</h1>
                  </div>
                  <div className="col-auto text-end">
                    <div className="dashboard-corpwell-title mb-0"><a href="">Previous</a> <a href="" className='px-3'>1</a> <a href="">Next</a></div>
                  </div>
              </div>
          </div>

         


        </div>
      </section>

    </>
  );
}

export default ManageCorporates ;
