import React, { useState, useEffect } from 'react';
import styles from "./BloodReportTestAdvice.module.css";

const TestSection = ({ title, description, icon, children }) => (
  <div className={`${styles.section} tw-border tw-mb-8 tw-rounded-lg tw-overflow-hidden`}>
    <div className={`tw-bg-gradient-to-r tw-from-[#ffd6c7] tw-to-white tw-p-4 tw-flex tw-items-center`}>
      <div className={`${styles.iconWrapper} tw-mr-4`}>{icon}</div>
      <div>
        <h2 className={`tw-text-orange-400 tw-text-2xl tw-font-bold tw-mb-2`}>{title}</h2>
        <p className={`${styles.sectionDescription} tw-text-sm`}>{description}</p>
      </div>
    </div>
    <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-p-4 tw-bg-white">
      {children}
    </div>
  </div>
);

const TestResult = ({ name, description, value, unit, range, status, reason, remedies }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'low': return 'tw-bg-blue-50 tw-border-blue-200 tw-text-blue-700';
      case 'high': return 'tw-bg-orange-50 tw-border-orange-200 tw-text-orange-700';
      case 'danger': return 'tw-bg-red-50 tw-border-red-200 tw-text-red-700';
      case 'good': return 'tw-bg-green-50 tw-border-green-200 tw-text-green-700';
      default: return 'tw-bg-gray-50 tw-border-gray-200 tw-text-gray-700';
    }
  };

  const isAbnormal = ['low', 'high', 'danger'].includes(status);

  return (
    <div className={`${styles.testResult} ${getStatusColor(status)} tw-p-4 tw-rounded-lg tw-border tw-shadow-sm tw-text-sm`}>
      <div className="tw-flex tw-justify-between tw-items-start tw-mb-2">
        <div className="tw-flex-1">
          <h3 className="tw-font-bold tw-text-lg tw-mb-0.5">{name}</h3>
          <p className="tw-text-xs tw-text-gray-600">{description}</p>
        </div>
        <div className={`tw-px-2 tw-py-0.5 tw-rounded-full tw-border ${getStatusColor(status)} tw-text-xs tw-font-medium tw-ml-2`}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </div>
      </div>

      <div className="tw-flex tw-items-baseline tw-mb-2">
        <span className="tw-text-2xl tw-font-bold tw-mr-1">{value}</span>
        <span className="tw-text-sm tw-text-gray-600">{unit}</span>
        <span className="tw-text-xs tw-text-gray-500 tw-ml-2">Normal Range: {range}</span>
      </div>

      {isAbnormal && (
        <div className="tw-mt-2">
          <div className="tw-bg-white tw-rounded-md tw-p-2 tw-mb-2">
            <h4 className="tw-font-semibold tw-mb-1 tw-text-orange-600 tw-text-xs">Why this result is concerning:</h4>
            <p className="tw-text-xs">{reason}</p>
          </div>

          <div className="tw-bg-white tw-rounded-md tw-p-2">
            <h4 className="tw-font-semibold tw-mb-1 tw-text-green-600 tw-text-xs">How to improve:</h4>
            <ul className="tw-text-xs tw-list-disc tw-list-inside tw-space-y-0.5">
              {remedies.map((remedy, index) => (
                <li key={index}>{remedy}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const Header = ({ name: name,patientId:patientId }) => (
  <div className={styles.header}>
    <div className={styles.title}>03 Lab Test Results</div>
    <div className={styles.patient_info}>
      {name} |  {patientId}
    </div>
  </div>
);


const BloodReportTestAdvice = ({ reportData1: reportData }) => {


  const [data, setData] = useState(reportData);

  // Use useEffect to update state when reportData changes
  useEffect(() => {
    if (reportData) {
      setData(reportData);

    }
  }, [reportData]); // Re-run effect when reportData changes

  return (
    <div className={styles.report}>
      <div className={styles.page}>
        <Header name={data?.name} patientId={data?.patientId}/>
        <div className={styles.pageContent}>
  {data?.resultsOutsideNormalRange?.map((category, index) => (
    <TestSection
      key={index}
      title={category.category} // Dynamically setting the category as the title
      description={category?.descriptions}
      icon={<span className="tw-text-3xl">🩸</span>}
    >
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
        {category.results.map((result, resultIndex) => (
          <TestResult
            key={`${index}-${resultIndex}`}
            name={result.testName}
            description={`The ${result.testName} measures your body's ability to manage glucose levels.`}
            value={result.latestResult}
            unit={result.unit}
            range={`${result.minRange} - ${result.maxRange}`}
            status={result.status} // Assuming it's marked as "high" since the value exceeds the max range
            reason={result.abnormal_reason}
            remedies={result.how_to_improve}
          />
        ))}
      </div>
    </TestSection>
  ))}
</div>
      </div>
    </div>
  );
};

export default BloodReportTestAdvice;