import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaPlus, FaArrowLeft, FaBlog, FaVideo } from 'react-icons/fa';
import Modal from './Modal';
import { fetchCategoryCount, addNewCategoryTag } from "../../../services/cms/Service";

const CategoryManagement = ({ categories, onAddCategory, onEditCategory, onDeleteCategory, onBack, roleId }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ title: '', description: '', image: null });
  const [editingCategory, setEditingCategory] = useState(null);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [dummyCategories, setDummyCategories] = useState([]);

  // Dummy data with blog and video counts
  // const [dummyCategories, setDummyCategories] = useState([
  //   { id: 1, title: 'Fitness', description: 'General fitness topics', publishedBlogs: 15, blogCount: 25, videoCount: 10 },
  //   { id: 2, title: 'Nutrition', description: 'Diet and nutrition advice', publishedBlogs: 10, blogCount: 20, videoCount: 5 },
  //   { id: 3, title: 'Yoga', description: 'Yoga and meditation', publishedBlogs: 8, blogCount: 15, videoCount: 12 },
  //   { id: 4, title: 'Weightlifting', description: 'Strength training', publishedBlogs: 12, blogCount: 18, videoCount: 8 },
  // ]);


  async function fetch_Category_Count() {
    try {

      let response = await fetchCategoryCount({ type: "category" });
      if (response) {
        const updatedCategories = response.data.data.pId.map((category, index) => ({
          id: index + 1,  // You can adjust this as needed
          title: category.name,
          description: `${category.name} related topics`,  // Assuming a general description based on name
          publishedBlogs: 0,  // Assuming publishedBlogs is the same as blog_count
          blogCount: category.blog_count,
          videoCount: category.video_count
        }));

        setDummyCategories(updatedCategories);
      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function add_New_Category_Tag(name) {
    try {

      let response = await addNewCategoryTag({ type: "category", name: name });
      if (response) {
        console.log(response, "response")

      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetch_Category_Count()
    // add_New_Category_Tag()
  }, []);



  const handleCreateCategory = () => {
    if (newCategory.title) {
      const createdCategory = {
        ...newCategory,
        id: dummyCategories.length + 1,
        publishedBlogs: 0,
        blogCount: 0,
        videoCount: 0
      };
      console.log(createdCategory, "createdCategory")
      add_New_Category_Tag(createdCategory.title)
      setDummyCategories([...dummyCategories, createdCategory]);
      // onAddCategory(createdCategory);
      setNewCategory({ title: '', description: '', image: null });
      setShowModal(false);
    }
  };

  const handleEditCategory = () => {
    if (editingCategory) {
      setDummyCategories(dummyCategories.map(cat =>
        cat.id === editingCategory.id ? editingCategory : cat
      ));
      onEditCategory(editingCategory);
      setEditingCategory(null);
      setShowModal(false);
    }
  };

  const openEditModal = (category) => {
    setEditingCategory(category);
    setShowModal(true);
  };

  const openDeleteModal = (category) => {
    setDeletingCategory(category);
    setShowDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    if (deletingCategory) {
      setDummyCategories(dummyCategories.filter(cat => cat.id !== deletingCategory.id));
      onDeleteCategory(deletingCategory.id);
      setDeletingCategory(null);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl">
      <div className="tw-relative tw-mb-6">
        <h2 className="tw-text-3xl tw-pb-1 tw-font-bold tw-text-gray-800 tw-text-center tw-w-full">Category Management</h2>
        <button
          className="tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
          onClick={onBack}
        >
          <FaArrowLeft className="tw-mr-2" /> Back
        </button>
        {roleId != 25 ?
          <button
            className="tw-absolute tw-right-0 tw-top-0 tw-bottom-0 tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
            onClick={() => setShowModal(true)}
          >
            <FaPlus className="tw-inline tw-mr-2" /> Create Category
          </button> : ""}



      </div>
      <table className="tw-w-full tw-mt-4">
        <thead>
          <tr className="tw-bg-gray-100">
            <th className="tw-p-2">Title</th>
            <th className="tw-p-2">Description</th>
            <th className="tw-p-2">Published Blogs</th>
            <th className="tw-p-2">Blog Count</th>
            <th className="tw-p-2">Video Count</th>
            <th className="tw-p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {dummyCategories.map(category => (
            <tr key={category.id} className="tw-border-b">
              <td className="tw-p-2">{category.title}</td>
              <td className="tw-p-2">{category.description}</td>
              <td className="tw-p-2">{category.publishedBlogs}</td>
              <td className="tw-p-2">
                <span className="tw-flex tw-items-center">
                  <FaBlog className="tw-mr-1 tw-text-blue-500" /> {category.blogCount}
                </span>
              </td>
              <td className="tw-p-2">
                <span className="tw-flex tw-items-center">
                  <FaVideo className="tw-mr-1 tw-text-green-500" /> {category.videoCount}
                </span>
              </td>
              <td className="tw-p-2">
                <button className="tw-text-blue-500 hover:tw-text-blue-700 tw-mr-2" onClick={() => openEditModal(category)}><FaEdit /></button>
                <button className="tw-text-red-500 hover:tw-text-red-700" onClick={() => openDeleteModal(category)}><FaTrash /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingCategory(null);
        }}
        title={editingCategory ? "Edit Category" : "Create New Category"}
      >
        <input
          type="text"
          placeholder="Category Title (max 20 characters)"
          maxLength={20}
          value={editingCategory ? editingCategory.title : newCategory.title}
          onChange={(e) => editingCategory
            ? setEditingCategory({ ...editingCategory, title: e.target.value })
            : setNewCategory({ ...newCategory, title: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <textarea
          placeholder="Category Description (max 100 characters)"
          maxLength={100}
          value={editingCategory ? editingCategory.description : newCategory.description}
          onChange={(e) => editingCategory
            ? setEditingCategory({ ...editingCategory, description: e.target.value })
            : setNewCategory({ ...newCategory, description: e.target.value })}
          className="tw-w-full tw-mb-4 tw-p-2 tw-border tw-rounded"
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => editingCategory
            ? setEditingCategory({ ...editingCategory, image: e.target.files[0] })
            : setNewCategory({ ...newCategory, image: e.target.files[0] })}
          className="tw-w-full tw-mb-4"
        />
        <button
          onClick={editingCategory ? handleEditCategory : handleCreateCategory}
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300"
        >
          {editingCategory ? "Update Category" : "Create Category"}
        </button>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setDeletingCategory(null);
        }}
        title="Confirm Deletion"
      >
        <p className="tw-mb-4">Are you sure you want to delete the category "{deletingCategory?.title}"?</p>
        <div className="tw-flex tw-justify-end tw-space-x-2">
          <button
            onClick={() => setShowDeleteModal(false)}
            className="tw-bg-gray-300 tw-text-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-gray-400 tw-transition-colors tw-duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteCategory}
            className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CategoryManagement;