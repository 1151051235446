// src/components/Dashboard/Dashboard.js
import React, { useState, useEffect } from 'react';
// import { useBlogManagement } from '../../hooks/useBlogManagement';
import ContentEngagementDashboard from './ContentEngagementDashboard';
import HeaderDesc from './HeaderDesc';
import BlogTab from './BlogTab';
import { fetchEngagementData } from "../../../services/cms/Service";

const Dashboard = () => {
  const [engagementCount, setEngagementCount] = useState([]);
  console.log(engagementCount, "engagementCount")
  // const { blogs } = useBlogManagement();

  // const totalBlogs = blogs.length;
  // const publishedBlogs = blogs.filter(blog => blog.status === 'published').length;
  // const pendingApproval = blogs.filter(blog => blog.status === 'pending').length;
  useEffect(() => {
    // Fetch the roleId from the API


    fetch_Engagement_Data();
  }, []);

  const fetch_Engagement_Data = async () => {
    try {
      let resp = await fetchEngagementData();
      console.log(resp.data, "resp ")
      setEngagementCount(resp.data.data)

    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  return (
    <div className='tw-grid tw-grid-cols-1 tw-gap-8 tw-p-4 tw-bg-white tw-rounded-lg tw-mb-16'>
      <BlogTab selectedIndex={0} />
      <HeaderDesc
        image={'https://media.licdn.com/dms/image/D5612AQFvJE3IlOdJOw/article-cover_image-shrink_720_1280/0/1681278919474?e=2147483647&v=beta&t=6rOv5oTouM2-Kd7TziGJM4VTgroCrSF0wxF0YtMuMpU'}
        desc={'Track and analyze engagement metrics like views, clicks, and shares. This section provides insights for refining content strategies to maximize impact and audience engagement'}
      />
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
        <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg">
          <h2 className="tw-text-xl tw-font-bold tw-mb-2">Published Blogs</h2>
          <p className="tw-text-3xl">
            {/* {totalBlogs} */}
            {engagementCount.blog_count}
          </p>
        </div>
        <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg">
          <h2 className="tw-text-xl tw-font-bold tw-mb-2">Published Videos</h2>
          <p className="tw-text-3xl">
            {/* {publishedBlogs} */}
            {engagementCount.published_library_video_count}
          </p>
        </div>
        <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg">
          <h2 className="tw-text-xl tw-font-bold tw-mb-2">Content Pending for Approval</h2>
          <p className="tw-text-3xl">
            {/* {pendingApproval} */}
            {engagementCount.approved_count}
          </p>
        </div>
      </div>

      <ContentEngagementDashboard />
    </div>
  );
};

export default Dashboard;