import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeightScale, faHeartPulse, faFaceSadTear, faVialCircleCheck } from '@fortawesome/free-solid-svg-icons';

import './CorporateInsights.css';

function CorporateInsights() {
  const corporateInsights = [
    {
        corpInsightsBg: '#FDA4B1',
        corpLabelInsights: 'Habits Followed',
        corpCountInsights: '12,534',
        corpIconInsights: 'https://appcdn.goqii.com/storeimg/75655_1727259536.png',
    },
    {
        corpInsightsBg: '#D7EFCC',
        corpLabelInsights: 'Healthy Nutrition',
        corpCountInsights: '534',
        corpIconInsights: 'https://appcdn.goqii.com/storeimg/99629_1727262469.png',
    },
    {
        corpInsightsBg: '#C6DCF1',
        corpLabelInsights: 'Karma Donated',
        corpCountInsights: '468',
        corpIconInsights: 'https://appcdn.goqii.com/storeimg/49730_1727262506.png',
    },
    {
        corpInsightsBg: '#F6EFD2',
        corpLabelInsights: 'Coach Interaction',
        corpCountInsights: '211',
        corpIconInsights: 'https://appcdn.goqii.com/storeimg/95152_1727262557.png',
    },
    {
        corpInsightsBg: '#F6D0B5',
        corpLabelInsights: 'Activity Time',
        corpCountInsights: '2,673',
        corpIconInsights: 'https://appcdn.goqii.com/storeimg/64823_1727262612.png',
    },
  ];

  return (
    <>
      {corporateInsights.map((statP, index) => (
        <div 
          className="box1insights col-md" 
          style={{ backgroundColor: statP.corpInsightsBg }} 
          key={index}
        >
              <img src={statP.corpIconInsights} className="css-ImgIcon" alt="" />
                <h1 className="insights-title">
                   {statP.corpCountInsights}
                </h1>
                <h2 className="insights-subtitle">{statP.corpLabelInsights}</h2>
              </div>
      ))}
    </>
  );
}

export default CorporateInsights;
