import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeightScale, faHeartPulse, faFaceSadTear, faVialCircleCheck } from '@fortawesome/free-solid-svg-icons';

import './CorporatePrograms.css';

function CorporatePrograms () {

  const corporatePrograms = [
    {
      corpProgramsBg: 'https://appcdn.goqii.com/storeimg/10215_1727247954.jpg',
      corpLabel: 'Lifestyle',
      corpUrl: 'program-lifestyle',
      corpCount: '30',
      corpIcon: faHeartPulse,
    },
    {
      corpProgramsBg: 'https://appcdn.goqii.com/storeimg/14468_1727248446.jpg',
      corpLabel: 'Stress Management',
      corpUrl: 'program-stress-management',
      corpCount: '30',
      corpIcon: faFaceSadTear,
    },
    {
      corpProgramsBg: 'https://appcdn.goqii.com/storeimg/48590_1727248653.jpg',
      corpLabel: 'Weight Management',
      corpUrl: 'program-weight-management',
      corpCount: '30',
      corpIcon: faWeightScale,
    },
    {
      corpProgramsBg: 'https://appcdn.goqii.com/storeimg/63244_1727248744.jpg',
      corpLabel: 'Diabetic Care',
      corpUrl: 'program-diabetic-care',
      corpCount: '30',
      corpIcon: faVialCircleCheck,
    },
  ];
  
  
  return (
            <>
                {corporatePrograms.map((statP, index) => (
                      <div className="boxCorp1 prog col-md" style={{ backgroundImage: `url(${statP.corpProgramsBg})` }} key={index}>
                          <a href={statP.corpUrl}>
                            <div className="fullwidthdiv">
                              <div className="text-div">
                                <h1 className="programs-title">
                                  <FontAwesomeIcon icon={statP.corpIcon} className="css-fa-Icon" /> {statP.corpCount}
                                </h1>
                                <h2 className="programs-subtitle">{statP.corpLabel}</h2>
                              </div>
                            </div>
                          </a>
                      </div>
                ))}
            </>
  );
}

export default CorporatePrograms ;
