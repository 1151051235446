import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { agent_configuration_setting,fetch_program_data } from '../../../services/met/Service';


function ConfigurationSettingsCareNavigatorCallsDetails() {
    const [programData, setprogramData] = useState([]);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [language, setLanguage] = useState('Eng');
    const [script, setScript] = useState({
      DiabetesMellitus: { Eng: '', Arb: '' },
      Hypertension: { Eng: '', Arb: '' },
      Dyslipidemia: { Eng: '', Arb: '' },
    });
  
    const onSubmit = async(formData) => {
      console.log(formData);

      setFormSubmitted(true);
      setIsLoading(true); //show loader
      try {
            const response = await agent_configuration_setting(formData);
            if(response.data.code == 200){
                
            }else{
                //setErrorMessage(response.data.data.message);
            }
          
        }catch (error) {
            console.error(error);
            }
            finally{
            setIsLoading(false); //show loader
            }
    };
  
    const handleLanguageChange = (condition, lang) => {
      setLanguage(lang);
      // Trigger re-render to show the correct textarea content
      setScript({ ...script });
    };
  
    const handleScriptChange = (condition, lang, value) => {
      setScript(prevState => ({
        ...prevState,
        [condition]: {
          ...prevState[condition],
          [lang]: value,
        },
      }));
    };

    //fetch program data
const fetchProgramData = async () => {
    try {
        const resp = await fetch_program_data();
        if(resp.data.code == 200){
            console.log(resp.data);
            setprogramData(resp.data.data);
        }    
    } catch (error) {
        console.error(error);
    }
}

useEffect(() => {
    fetchProgramData();
},[]);

    const shiftStartTime = watch('shiftStartTime');
    const shiftEndTime = watch('shiftEndTime');
    const breakStartTime = watch('breakStartTime');
    const breakEndTime = watch('breakEndTime');
    const breakStartTime1 = watch('breakStartTime1');
    const breakEndTime1 = watch('breakEndTime1');

   
  return (
    <>
        <section className='ConfigurationSettingsAgentCall'>
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md-12">
                    <h1 className="main-title">Configuration Settings for Care Navigator Calls</h1>
                </div>
            </div>

            <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://appcdn.goqii.com/storeimg/83766_1720161406.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The Communication Configuration module allows you to easily manage all settings for agents making calls to enroll members in programs. You can configure call batches, retries, target enrollments, and specific scripts for various programs and conditions.</p>
                        </div>
                    </div>
                </div>

            <div className="container-fluid">
                <div className="row align-items-center pb-3">
                    <div className='comntent-ConfigurationSettingsAgentCall p-3'>
                        
                        <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center">
                        
                        <div className="col-md-3 pe-4">
                          <div className="row">
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-2">Care Navigator Disposition</h1>
                              </div>
                              <div className="col-12 mb-3">
                                <input className="form-check-input" type="checkbox" name="Completed"/>
                                <label className="form-check-label pt-1">Completed</label>
                              </div>
                              <div className="col-12 mb-3">
                                <input className="form-check-input" type="checkbox" name="NoAnswer"/>
                                <label className="form-check-label pt-1">No Answer</label>
                              </div>
                              <div className="col-12 mb-3">
                                <input className="form-check-input" type="checkbox" name="MemberBusy"/>
                                <label className="form-check-label pt-1">Member Busy</label>
                              </div>
                              <div className="col-12 mb-3">
                                <input className="form-check-input" type="checkbox" name="Disconnected"/>
                                <label className="form-check-label pt-1">Disconnectedy</label>
                              </div>
                              <div className="col-12 mb-3">
                                <input className="form-check-input" type="checkbox" name="CallBackRequested"/>
                                <label className="form-check-label pt-1">Call Back Requested</label>
                              </div>
                              <div className="col-12 mb-3">
                                <input className="form-check-input" type="checkbox" name="Not Reachable"/>
                                <label className="form-check-label pt-1">Not Reachable</label>
                              </div>
                              <div className="col-12 mt-2">
                              <a class="btn btn-primary devision-add-btn" href="###"><i class="bi bi-plus-circle me-2"></i> Add New Disposition</a>
                              </div>
                          </div>
        
                  </div>
                  <div className="col-md-4 mb-3 ps-4 border-start">
                    <div className='row mb-2'>
                            <div className="col-12 mb-2">
                              <h1 className="ConfigurationSettings-title mb-0">Script</h1>
                            </div>
                            <div className="col-12">
                            <select className="form-select input-field-ui" {...register('programId', { required: true })}>
                                {programData.map(option => (
                                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                                            ))
                                }
                              </select>
                            {/* {errors.programId && <p className="text-danger">This field is required</p>} */}
                            </div>
                </div>
          <div className='row mb-1 align-items-center'>
            <div className='col'>
              <label className="ConfigurationSettings-title mb-0">Langauage</label>
            </div>
            <div className='col-auto'>
              <div className="form-check form-check-inline mb-0">
                <input className="form-check-input" type="radio" name="diabetesLanguage" onClick={() => handleLanguageChange('DiabetesMellitus', 'Eng')} />
                <label className="form-check-label pt-1">English</label>
              </div>
              <div className="form-check form-check-inline mb-0">
                <input className="form-check-input" type="radio" name="diabetesLanguage" onClick={() => handleLanguageChange('DiabetesMellitus', 'Arb')} />
                <label className="form-check-label pt-1">Arebic</label>
              </div>
            </div>
          </div>
          <textarea
            className="form-control input-field-ui"
            placeholder="Program/Condition Specific Script"
            rows="12"
            value={script.DiabetesMellitus[language]}
            {...register(`DiabetesMellitus.${language}`, { required: true })}
            onChange={(e) => handleScriptChange('DiabetesMellitus', language, e.target.value)}
          ></textarea>
          {errors.DiabetesMellitus && errors.DiabetesMellitus[language] && (
            <span className="text-danger">This field is required</span>
          )}
      </div>
      <div className="col-12 mb-2 text-center">
        <button type="submit" className="btn btn-dark program-save-btn">Submit</button>
      </div>

    </form>
                        


                        </div>
                    </div>
            </div>
        </section>      
    </>
  );
}

export default ConfigurationSettingsCareNavigatorCallsDetails;
