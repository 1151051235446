import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

export const createChallenge = (formData) => {
    // var dataPass = common_axios.post( baseURL + 'challenge/create_challenge',{
    //     "uidStore" : ''
    // });
    var dataPass = common_axios.post(baseURL + 'challenge/create_challenge', formData);
    return dataPass;
};

export const getTypes = (uidStore) => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_activity_types',{
        "uidStore" : uidStore
    });
    return dataPass;
};

export const getAllChallenges = () => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_all_challenges');
    return dataPass;
};

export const getChallenge = (formData) => {
    var dataPass = common_axios.post(baseURL + 'challenge/fetch_challenge', formData)
    return dataPass;
};

export const updateChallenge = (formData) => {
    // var dataPass = common_axios.post( baseURL + 'challenge/create_challenge',{
    //     "uidStore" : ''
    // });
    var dataPass = common_axios.post(baseURL + 'challenge/update_challenge', formData);
    return dataPass;
};

export const deleteChallenge = (formData) => {
    var dataPass = common_axios.post(baseURL + 'challenge/remove_challenge', formData)
    return dataPass;
};

export const getAllCorporates = () => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_all_corporates');
    return dataPass;
};

export const getAllTaskLists = () => {
    var dataPass = common_axios.get( baseURL + 'challenge/fetch_task_lists');
    return dataPass;
};