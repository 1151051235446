import React, { useState } from 'react';
import './CreatePushNotifications.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

function CreatePushNotifications() {

  const handleBackButton = () => {
    navigate('/communications');
    }

    const navigate = useNavigate();
    
  return (
    <>
      <section className='CreatePushNotifications'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                  <h1 className="main-title" style={{ margin: '0' }}>Create Push Notification</h1>
              </div>
              {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testPushNotification">Test Notification</Link></div> */}

          </div>

          <div className='create-push-notifications p-4'>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Select Action<span className='text-danger'>*</span></label>
                    <select name="" className="form-select input-field-ui">
                        <option value="">Select Action</option>
                        <option value="">1</option>
                        <option value="">2</option>
                    </select>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Notification Title<span className='text-danger'>*</span></label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Notification Title"/>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Notification Content<span className='text-danger'>*</span> </label>
                    <textarea rows="4" type="text" className="form-control input-field-ui" id="" placeholder="Enter Notification Content"></textarea>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                  <div className='row'>
                    <label className="form-label sub-title">Notification Image</label>
                    <div className='col pe-1'>
                      <input type="file" className="form-control input-field-ui" id="" placeholder="Enter Image Url"/>
                    </div>
                    <div className='col-auto ps-0'>
                      <button type="button" id="xlsxSubmit" className="btn btn-success uplaod-file-btn">Upload File</button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7 mb-2">
                <div className="row">
                    <div className="col-md">
                        <label className="form-label sub-title">Time to Send</label>
                        <input type="time" className="form-control input-field-ui" id="" placeholder="Start Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">Date to Send</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="End Date"/>
                    </div>
                </div>
              </div>
              <div className="col-md-12">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" name="templateTrackable-1" id="templateTrackable3" type="radio" value="All Users" defaultChecked/>
                        <label htmlFor='templateTrackable3'  className="form-check-label pt-1">All Users</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" name="templateTrackable-1" id="templateTrackable4" type="radio" value="Listed Users"/>
                        <label htmlFor='templateTrackable4'  className="form-check-label pt-1">Listed Users</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" name="templateTrackable-1" id="templateTrackable5" type="radio" value="Expired Users"/>
                        <label htmlFor='templateTrackable5'  className="form-check-label pt-1">Expired Users</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Submit</button> <button type="button" className="btn clear-btn" data-bs-toggle="modal" data-bs-target="#testPushNotification">Test Notification</button>
                </div>
            </div>
            
          </div>
        </div>
      </section>


      <div className="modal fade" id="testPushNotification" tabindex="-1" aria-labelledby="testPushNotificationLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="testPushNotificationLabel">Test Push Notification</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="row">
                <div className="col-md-12 mb-4">
                    <label className="form-label sub-title">Email<span className='text-danger'>*</span></label>
                    <input type="email" className="form-control input-field-ui" id="" placeholder="Enter Email Id"/>
                </div>
                <div className="col-md-12">
                  <button type="button" className="btn program-save-btn me-2">Send</button> <button type="button" className="btn clear-btn"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CreatePushNotifications;
