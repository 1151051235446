import React from 'react';
import DataTable from 'react-data-table-component';
import DropDownActionNew from "./../../../pages/componenents/ContentPrograms/DropDownActionNew";

function ReportsDateTable({ logData }) {
    const columns = [
        {
            name: 'Date Uploaded',
            selector: row => row.dateUploaded,
            sortable: true,
        },
        {
            name: 'Uploaded Member Records',
            selector: row => row.uploadedMembers,
            sortable: true,
        },
        {
            name: 'Successful Migration',
            selector: row => row.successfullyUploaded,
            sortable: true,
        },
        {
            name: 'Migration Failed',
            selector: row => row.failed,
            sortable: true,
        },
        {
            name: 'Onboarded Members',
            selector: row => row.enrolledMembers,
            sortable: true,
        },
        {
            name: 'Not Onboarded Members',
            selector: row => row.notEnrolled,
            sortable: true,
        },
        {
            name: 'Condition Name',
            selector: row => row.programName,
            sortable: true,
        },
        {
            name: 'Uploaded By',
            selector: row => row.uploadedBy,
            sortable: true,
        },
        {
            name: 'Download',
            selector: row => row.uploadId,
            sortable: true,
            cell: row => <DropDownActionNew uploadId={row.uploadId}/>,
        },
        // <i className="bi bi-download"><a target="_blank" href={row.download} style={{ fontSize: '20px' }}></a>
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    return (
        <DataTable
            columns={columns}
            data={logData}
            pagination
            paginationPerPage={10}
            striped
            responsive
            noHeader
            customStyles={customStyles}
        />
    );
}

export default ReportsDateTable;
