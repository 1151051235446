import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './IntegrationDashboardLayout.css'; // Make sure this file exists and is imported

const IntegrationDashboardLayout = () => {
  const location = useLocation();
  const tabNames = ['ALL-INTEGRATIONS', 'AUTHENTICATION', 'EMAILS', 'MESSAGING','WHATSAPP', 'TELEPHONIC', 'SIEM', 'EHR', 'STORAGE', 'BILLING-AND-CLAIMS-TOOLS'];

  const currentTabIndex = tabNames.findIndex(
    (tab) => location.pathname === `/integration-dashboard/${tab.toLowerCase()}`
  );

  return (
    <div className="tw-max-w-full tw-overflow-x-auto">
      <h1 className='tw-text-gray-500 tw-text-2xl tw-mb-4'>Integration Dashboard</h1>
      <Tabs selectedIndex={currentTabIndex === -1 ? 0 : currentTabIndex} className="custom-tabs">
        <TabList className="tw-flex tw-gap-2 tw-flex-wrap tw-border-b tw-border-gray-200">
          {tabNames.map((tab, index) => (
            <Tab 
              key={index} 
              className="custom-tab tw-px-3 tw-py-2 tw-rounded-t-xl tw-text-base tw-font-medium tw-text-[#03335b] tw-bg-white tw-border-b-2 tw-border-transparent hover:tw-bg-[#03335b] hover:tw-text-white focus:tw-outline-none tw-whitespace-nowrap"
              selectedClassName="active-tab"
            >
              <Link 
                to={`/integration-dashboard/${tab.toLowerCase()}`}
                className="tw-no-underline tw-text-inherit"
              >
                {tab}
              </Link>
            </Tab>
          ))}
        </TabList>

        {tabNames.map((tab, index) => (
          <TabPanel key={index} className="tw-bg-transparent ">
            <Outlet />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default IntegrationDashboardLayout;