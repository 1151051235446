import React, { useState } from 'react';
import './CreateSMSCampaigns.css';
import { Link } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import {useNavigate} from "react-router-dom";

function CreateSMSCampaigns() {

  const [html, setHtml] = useState('');

  function onChange(content) {
      setHtml(content);
  }

  const modules = {
      toolbar: {
          container: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, 
              {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']                                         
          ],
      }
  };

  const editorWrapperStyle = {
      height: '160px',
      marginBottom:'40px'
  };

  const handleBackButton = () => {
    navigate('/communications');
    }

    const navigate = useNavigate();
  return (
    <>
      <section className='CreateSMSCampaigns'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i class="bi bi-chevron-left me-1"></i> Back</button>
                  <h1 className="main-title" style={{ margin: '0' }}>Create SMS Template</h1>
              </div>
          </div>

          <div className='create-sms-campaigns p-4'>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Campaign Name</label>
                    <input type="text" className="form-control input-field-ui" id="" placeholder="Enter Campaign Name"/>
                </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7 mb-2">
                <div className="row">
                    <div className="col-md">
                        <label className="form-label sub-title">Time to Send</label>
                        <input type="time" className="form-control input-field-ui" id="" placeholder="Start Date"/>
                    </div>
                    <div className="col-md">
                        <label className="form-label sub-title">Date to Send</label>
                        <input type="date" className="form-control input-field-ui" id="" placeholder="End Date"/>
                    </div>
                </div>
              </div>
              <div className="col-md-12">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" name="templateTrackable-1" id="templateTrackable3" type="radio" value="All Users" defaultChecked/>
                        <label htmlFor='templateTrackable3'  className="form-check-label pt-1">All Users</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" name="templateTrackable-1" id="templateTrackable4" type="radio" value="Listed Users"/>
                        <label htmlFor='templateTrackable4'  className="form-check-label pt-1">Listed Users</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" name="templateTrackable-1" id="templateTrackable5" type="radio" value="Expired Users"/>
                        <label htmlFor='templateTrackable5'  className="form-check-label pt-1">Expired Users</label>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-7">
                    <label className="form-label sub-title">Message</label>
                    <textarea rows="4" type="text" className="form-control input-field-ui" id="" placeholder="Enter Message"></textarea>
                </div>
            </div>

            <div className="row">
                <div className="col-md-7">
                <button type="button" className="btn program-save-btn me-2">Submit</button> <button type="button" className="btn clear-btn" data-bs-toggle="modal" data-bs-target="#SMSCampaigns">Test SMS</button>
                </div>
            </div>
            
          </div>
        </div>
      </section>

      <div className="modal fade" id="SMSCampaigns" tabindex="-1" aria-labelledby="SMSCampaignsLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="SMSCampaignsLabel">Test SMS</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="row">
                <div className="col-md-12 mb-4">
                    <label className="form-label sub-title">Mobile Number<span className='text-danger'>*</span></label>
                    <input type="number" className="form-control input-field-ui" id="" placeholder="Enter Mobile Number"/>
                </div>
                <div className="col-md-12">
                  <button type="button" className="btn program-save-btn me-2">Send</button> <button type="button" className="btn clear-btn"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CreateSMSCampaigns;
