import React, { useState, useRef, useEffect } from 'react';
import styles from "./BloodReportPreview6.module.css";

const BloodReportHealthAdvisory = ({ reportData1:reportData }) => {
    // const { healthAdvisory } = reportData;
      // Initialize state with reportData1
      const [data, setData] = useState(reportData);

      // Use useEffect to update state when reportData1 (reportData) changes
      useEffect(() => {
        if (reportData) {
          setData(reportData);
        }
      }, [reportData]); // Re-run effect when reportData changes
    
  return (
    <>
      <body className="tw-p-[20px]">
      <div className={styles.header}>
          <div className={styles.title}>{data?.title || 'Health Advisory'}</div>
          <div className={styles.patient_info}>
            {data.name} | Patient ID: {data.patientId}
          </div>
        </div>

        <div className={styles.main_stats}>
          <div className={styles.stat_item}>
            <div className={styles.stat_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/26450_1726658227.png"
                alt="Body Mass Index Logo"
                className={styles.stat_img}
              />
            </div>
            <div className={styles.stat_info}>
              <div>Body Mass Index</div>
              <div className={styles.stat_value}>
                {data.BodyMassIndex}
              </div>
            </div>
          </div>
          <div className={styles.stat_item}>
            <div className={styles.stat_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/56328_1726658413.png"
                alt="Height Logo"
                className={styles.stat_img}
              />
            </div>
            <div className={styles.stat_info}>
              <div>Height</div>
              <div className={styles.stat_value}>
                {data.height}
              </div>
            </div>
          </div>
          <div className={styles.stat_item}>
            <div className={styles.stat_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/7086_1726658446.png"
                alt="Weight Logo"
                className={styles.stat_img}
              />
            </div>
            <div className={styles.stat_info}>
              <div>Weight</div>
              <div className={styles.stat_value}>
                {data.weight}
              </div>
            </div>
          </div>
        </div>

        
        <div className={styles.health_grid}>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/82528_1726658528.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Physical Activity</div>
            <div className={styles.health_value}>4 days</div>
          </div>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/50458_1726658614.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Smoke</div>
            <div className={styles.health_value}>No</div>
          </div>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/26075_1726658650.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Food Preference</div>
            <div className={styles.health_value}>Vegetables</div>
          </div>
          <div className={styles.health_item}>
            <span className={styles.health_label}>Low</span>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/81508_1726658716.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Blood Pressure</div>
            <div className={styles.health_value}>120/60</div>
          </div>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/15989_1726658755.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Medication</div>
            <div className={styles.health_value}>No Data</div>
          </div>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/54337_1726658789.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Alcohol</div>
            <div className={styles.health_value}>No</div>
          </div>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/11301_1726658844.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Family History</div>
            <div className={styles.health_value}>No Data</div>
          </div>
          <div className={styles.health_item}>
            <div className={styles.health_icon}>
              <img
                src="https://appcdn.goqii.com/storeimg/42023_1726658877.png"
                alt="Health Logo"
                className={styles.health_img}
              />
            </div>
            <div className={styles.health_label}>Sugar levels</div>
            <div className={styles.health_value}>No Data</div>
          </div>
        </div>

        <div style={{ backgroundColor: "#e9feeb" }} className={styles.advisory_section}>
          <div className={styles.advisory_title}>
            <img
              src="https://appcdn.goqii.com/storeimg/67300_1726660220.png"
              alt="Advisory Logo"
              className={styles.advisory_title_img}
            />
            <div className={styles.advisory_title_small}>SUGGESTED</div>
            <div className={styles.advisory_title_big}>NUTRITION</div>
          </div>
          <div className={styles.advisory_content}>
            <div className={styles.advisory_column}>
              <h4>Do's</h4>
              <ul className={styles.advisory_list}>
                <li>
                  • Have a balanced diet that includes whole grains, pulses,
                  dairy, fruits, vegetables, nuts and healthy fats
                </li>
                <li>• Have dates and figs</li>
                <li>
                • Take vitamin C rich foods like citrus fruits, strawberries and
                  green, leafy vegetables
                </li>
                <li>
                • Include fruits like apples, berries and melons in your diet
                </li>
                <li>
                • Include calcium rich foods like milk, yoghurt, cheese and
                  green, leafy vegetables
                </li>
                <li>• Include Brazil nuts, sesame seeds, sunflower seeds</li>
                <li>
                • Have cruciferous vegetables like broccoli, cauliflower and
                  cabbage
                </li>
                <li>• Take fresh fruit and vegetable juices</li>
              </ul>
            </div>
            <div className={styles.advisory_column}>
              <h4>Don'ts</h4>
              <ul className={styles.advisory_list}>
                <li>• Limit intake of salt</li>
                <li>• Avoid refined carbs, processed foods</li>
                <li>• Decrease intake of colas and sugary drinks</li>
                <li>• Avoid the use of oil and avoid sauces and dressings</li>
                <li>• Avoid flavoured and seasoned foods</li>
                <li>
                • Avoid saturated fats, transfats, oily and greasy foods like
                  cakes, creamy or fried foods
                </li>
                <li>• Limit sugar intake</li>
                <li>• Reduce caffeine intake</li>
                <li>• Avoid red meat and organ meats</li>
              </ul>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#ebfffe" }} className={styles.advisory_section}>
          <div className={styles.advisory_title}>
            <img
              src="https://appcdn.goqii.com/storeimg/85798_1726661116.png"
              alt="Advisory Logo"
              className={styles.advisory_title_img}
            />
            <div className={styles.advisory_title_small}>SUGGESTED</div>
            <div className={styles.advisory_title_big}>LIFESTYLE</div>
          </div>
          <div className={styles.advisory_content}>
            <div className={styles.advisory_column}>
              <h4>Do's</h4>
              <ul className={styles.advisory_list}>
                <li>• Maintain ideal weight</li>
                <li>• Have regular exposure to sunlight</li>
                <li>• Lose weight gradually and stay active</li>
              </ul>
            </div>
            <div className={styles.advisory_column}>
              <h4>Don'ts</h4>
              <ul className={styles.advisory_list}>
                <li>• Avoid overexertion without having food or drink</li>
                <li>• Avoid strenuous exercises</li>
                <li>• Avoid smoking and alcohol</li>
                <li>• Avoid long periods of inactivity</li>
                <li>• Avoid late night heavy meals</li>
                <li>• Avoid overeating or calorie rich food</li>
                <li>• Limit dining out</li>
              </ul>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#fefaee" }} className={styles.future_tests}>
          <div className={styles.advisory_title}>
            <img
              src="https://appcdn.goqii.com/storeimg/64029_1726661555.png"
              alt="Advisory Logo"
              className={styles.advisory_title_img}
            />
            <div className={styles.advisory_title_small}>SUGGESTED</div>
            <div
              style={{ textWrap: "wrap", textAlign: "center" }}
              className={styles.advisory_title_big}
            >
              FUTURE TESTS
            </div>
          </div>
          <ul className={styles.advisory_list}>
            <li>• Complete Hemogram - Every 1 Month</li>
            <li>• Iron Studies - Every 1 Month</li>
            <li>• Peripheral Smear Examination By Pathologist - Every 2 Month</li>
            <li>• Vitamin D Total-25 Hydroxy - Every 2 Month</li>
            <li>• Calcium Total, Serum - Every 2 Month</li>
            <li>• Liver Function Test - Every 1 Month</li>
          </ul>
        </div>
      </body>
    </>
  );
};

export default BloodReportHealthAdvisory;
