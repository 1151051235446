import React from 'react'

const HeaderDesc = ({image, desc}) => {
  return (
    <div className='tw-flex tw-flex-row tw-justify-start tw-gap-4 tw-items-center tw-w-full tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-4 tw-mt-4'>
        <img src={image} alt="image" className=' tw-h-28 tw-rounded-full' />
        <div className='tw-ml-4'>
            <h1 className='tw-text-xl tw-w-[80%] tw-mx-auto tw-leading-loose tw-text-center'>{desc}</h1>
        </div>
    </div>
  )
}

export default HeaderDesc