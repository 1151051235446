import React, { useState, useMemo, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FiInfo } from 'react-icons/fi';

const ContentEngagementDashboard = () => {
  const [contentTypeFilter, setContentTypeFilter] = useState('All');
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const [ageFilter, setAgeFilter] = useState('All');
  const [genderFilter, setGenderFilter] = useState('All');
  const [programFilter, setProgramFilter] = useState('All');
  const [treatmentFilter, setTreatmentFilter] = useState('All');
  const [pathwayFilter, setPathwayFilter] = useState('All');
  const [modalInfo, setModalInfo] = useState({ isOpen: false, content: '' });
  const roleId = localStorage.getItem('roleId')

  const generateDummyData = (count) => {
    const contentTypes = ['Blog', 'Workout Video', 'Awareness Video'];
    const creators = ['John Doe', 'Jane Smith', 'Alex Johnson', 'Emily Brown', 'Chris Lee'];

    return Array.from({ length: count }, (_, i) => ({
      id: i + 1,
      title: `${contentTypes[Math.floor(Math.random() * contentTypes.length)]} Title ${i + 1}`,
      contentType: contentTypes[Math.floor(Math.random() * contentTypes.length)],
      creator: creators[Math.floor(Math.random() * creators.length)],
      totalViews: Math.floor(Math.random() * 10000) + 1000,
      totalLikes: Math.floor(Math.random() * 5000) + 500,
      totalComments: Math.floor(Math.random() * 1000) + 100,
      avgMinsViewed: (Math.random() * 20 + 1).toFixed(1),
      engagementRate: (Math.random() * 0.1 + 0.01).toFixed(2),
      shareCount: Math.floor(Math.random() * 500) + 50,
      completionRate: (Math.random() * 0.5 + 0.3).toFixed(2)
    }));
  };

  const allContentData = useMemo(() => generateDummyData(100), []);

  const filteredData = useMemo(() => {
    return contentTypeFilter === 'All'
      ? allContentData
      : allContentData.filter(item => item.contentType === contentTypeFilter);
  }, [allContentData, contentTypeFilter]);

  const sortedData = useMemo(() => {
    if (!sortColumn) return filteredData;
    return [...filteredData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortColumn, sortDirection]);

  const top10ViewedData = useMemo(() =>
    [...sortedData].sort((a, b) => b.totalViews - a.totalViews).slice(0, 10),
    [sortedData]
  );

  const top10LikedData = useMemo(() =>
    [...sortedData].sort((a, b) => b.totalLikes - a.totalLikes).slice(0, 10),
    [sortedData]
  );

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedData, currentPage]);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handleAskAI = () => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setIsModalOpen(true);
  //   }, 2000);
  // };

  const InfoModal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    return (
      <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-p-4 tw-z-50">
        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-max-w-md">
          <p className="tw-mb-4">{content}</p>
          <button
            className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const renderInfoButton = (explanation) => (
    <button
      onClick={() => setModalInfo({ isOpen: true, content: explanation })}
      className="tw-ml-2 tw-p-1 tw-rounded-full  tw-transition-colors tw-duration-200"
    >
      <FiInfo className="" size={16} />
    </button>
  );

  const renderKPI = (title, value, explanation) => (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
        <h4 className="tw-font-bold">{title}</h4>
        {renderInfoButton(explanation)}
      </div>
      <p className="tw-text-2xl">{value}</p>
    </div>
  );

  const renderTable = (data, title, explanation) => (
    <div className="tw-mb-8">
      <div className="tw-flex tw-items-center tw-mb-4">
        <h3 className="tw-text-xl tw-font-bold">{title}</h3>
        {renderInfoButton(explanation)}
      </div>
      <div className="tw-overflow-x-auto">
        <table className="tw-text-sm tw-text-left rtl:tw-text-right tw-text-gray-500  tw-w-full">
          <thead className='tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50 '>
            <tr>
              {Object.keys(data[0]).map((header) => (
                <th key={header} onClick={() => handleSort(header.toLowerCase().replace(/ /g, ''))} className='tw-px-6 tw-py-3'>
                  <button className="tw-font-bold">{header}</button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className='tw-bg-white tw-border-b'>
                {Object.values(item).map((value, i) => (
                  <td key={i} className="tw-px-6 tw-py-4">{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {title === "All Content Performance" && (
        <div className="tw-flex tw-justify-center tw-mt-4">
          <div className="tw-inline-flex tw-rounded-md tw-shadow-sm" role="group">
            <button
              className="tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-l-lg hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-blue-700 focus:tw-text-blue-700"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              «
            </button>
            <button className="tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border-t tw-border-b tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-blue-700 focus:tw-text-blue-700">
              Page {currentPage} of {totalPages}
            </button>
            <button
              className="tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-r-lg hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-blue-700 focus:tw-text-blue-700"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              »
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const renderChart = (options, explanation) => (
    <div className="tw-mb-8">
      <div className="tw-flex tw-items-center tw-mb-4">
        <h3 className="tw-text-xl tw-font-bold">{options.title.text}</h3>
        {renderInfoButton(explanation)}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );

  const engagementByContentTypeChartOptions = {
    chart: { type: 'column' },
    title: { text: 'Engagement Metrics by Content Type' },
    xAxis: { categories: ['Blog', 'Workout Video', 'Awareness Video'] },
    yAxis: { title: { text: 'Average Count' } },
    credits: { enabled: false },
    series: [{
      name: 'Views',
      data: [
        Math.round(sortedData.filter(item => item.contentType === 'Blog').reduce((sum, item) => sum + item.totalViews, 0) / sortedData.filter(item => item.contentType === 'Blog').length),
        Math.round(sortedData.filter(item => item.contentType === 'Workout Video').reduce((sum, item) => sum + item.totalViews, 0) / sortedData.filter(item => item.contentType === 'Workout Video').length),
        Math.round(sortedData.filter(item => item.contentType === 'Awareness Video').reduce((sum, item) => sum + item.totalViews, 0) / sortedData.filter(item => item.contentType === 'Awareness Video').length)
      ]
    }, {
      name: 'Likes',
      data: [
        Math.round(sortedData.filter(item => item.contentType === 'Blog').reduce((sum, item) => sum + item.totalLikes, 0) / sortedData.filter(item => item.contentType === 'Blog').length),
        Math.round(sortedData.filter(item => item.contentType === 'Workout Video').reduce((sum, item) => sum + item.totalLikes, 0) / sortedData.filter(item => item.contentType === 'Workout Video').length),
        Math.round(sortedData.filter(item => item.contentType === 'Awareness Video').reduce((sum, item) => sum + item.totalLikes, 0) / sortedData.filter(item => item.contentType === 'Awareness Video').length)
      ]
    }, {
      name: 'Comments',
      data: [
        Math.round(sortedData.filter(item => item.contentType === 'Blog').reduce((sum, item) => sum + item.totalComments, 0) / sortedData.filter(item => item.contentType === 'Blog').length),
        Math.round(sortedData.filter(item => item.contentType === 'Workout Video').reduce((sum, item) => sum + item.totalComments, 0) / sortedData.filter(item => item.contentType === 'Workout Video').length),
        Math.round(sortedData.filter(item => item.contentType === 'Awareness Video').reduce((sum, item) => sum + item.totalComments, 0) / sortedData.filter(item => item.contentType === 'Awareness Video').length)
      ]
    }]
  };

  const completionRateChartOptions = useMemo(() => {
    const getAverageRate = (contentType, rateType) => {
      const filteredData = sortedData.filter(item => item.contentType === contentType);
      return filteredData.reduce((sum, item) => sum + parseFloat(item[rateType]), 0) / filteredData.length;
    };

    return {
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Arial, sans-serif'
        }
      },
      title: {
        text: 'Content Performance Metrics by Type',
        style: { fontSize: '18px', fontWeight: 'bold' }
      },
      xAxis: {
        categories: ['Blog', 'Workout Video', 'Awareness Video'],
        title: { text: 'Content Type' }
      },
      yAxis: [{
        title: {
          text: 'Rate (%)',
          style: { color: Highcharts.getOptions().colors[0] }
        },
        labels: { format: '{value}%' },
        max: 100
      }, {
        title: {
          text: 'Average Time',
          style: { color: Highcharts.getOptions().colors[1] }
        },
        labels: { format: '{value} mins' },
        opposite: true
      }],
      legend: {
        shadow: false,
        verticalAlign: 'top'
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Completion Rate',
        color: 'rgba(165,170,217,1)',
        data: [
          { y: getAverageRate('Blog', 'completionRate') * 100, tooltip: 'Blog: {point.y:.1f}%' },
          { y: getAverageRate('Workout Video', 'completionRate') * 100, tooltip: 'Workout Video: {point.y:.1f}%' },
          { y: getAverageRate('Awareness Video', 'completionRate') * 100, tooltip: 'Awareness Video: {point.y:.1f}%' }
        ],
        pointPadding: 0.3,
        pointPlacement: -0.2
      }, {
        name: 'Engagement Rate',
        color: 'rgba(126,86,134,.9)',
        data: [
          { y: getAverageRate('Blog', 'engagementRate') * 100, tooltip: 'Blog: {point.y:.1f}%' },
          { y: getAverageRate('Workout Video', 'engagementRate') * 100, tooltip: 'Workout Video: {point.y:.1f}%' },
          { y: getAverageRate('Awareness Video', 'engagementRate') * 100, tooltip: 'Awareness Video: {point.y:.1f}%' }
        ],
        pointPadding: 0.4,
        pointPlacement: -0.2
      }, {
        name: 'Avg. Time Viewed',
        color: 'rgba(248,161,63,1)',
        data: [
          { y: getAverageRate('Blog', 'avgMinsViewed'), tooltip: 'Blog: {point.y:.1f} mins' },
          { y: getAverageRate('Workout Video', 'avgMinsViewed'), tooltip: 'Workout Video: {point.y:.1f} mins' },
          { y: getAverageRate('Awareness Video', 'avgMinsViewed'), tooltip: 'Awareness Video: {point.y:.1f} mins' }
        ],
        tooltip: {
          valueSuffix: ' mins'
        },
        pointPadding: 0.3,
        pointPlacement: 0.2,
        yAxis: 1
      }],
      credits: { enabled: false }
    };
  }, [sortedData]);

  const engagementOverTimeChartOptions = {
    chart: { type: 'line' },
    title: { text: 'Engagement Trends Over Time' },
    xAxis: { categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8'] },
    yAxis: { title: { text: 'Engagement Rate' } },
    credits: { enabled: false },
    series: [{
      name: 'Blog',
      data: Array.from({ length: 8 }, () => parseFloat((Math.random() * 0.1 + 0.05).toFixed(2)))
    }, {
      name: 'Workout Video',
      data: Array.from({ length: 8 }, () => parseFloat((Math.random() * 0.15 + 0.1).toFixed(2)))
    }, {
      name: 'Awareness Video',
      data: Array.from({ length: 8 }, () => parseFloat((Math.random() * 0.12 + 0.08).toFixed(2)))
    }],
    credits: { enabled: false }
  };

  const monthlyEngagementChartOptions = {
    chart: { type: 'line' },
    title: { text: 'Monthly Engagement Trends' },
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: { title: { text: 'Engagement Rate' } },
    credits: { enabled: false },
    series: [{
      name: 'Blog',
      data: Array.from({ length: 12 }, () => parseFloat((Math.random() * 0.1 + 0.05).toFixed(2)))
    }, {
      name: 'Workout Video',
      data: Array.from({ length: 12 }, () => parseFloat((Math.random() * 0.15 + 0.1).toFixed(2)))
    }, {
      name: 'Awareness Video',
      data: Array.from({ length: 12 }, () => parseFloat((Math.random() * 0.12 + 0.08).toFixed(2)))
    }],
    credits: { enabled: false }
  };

  const dailyEngagementChartOptions = {
    chart: { type: 'area' },
    title: { text: 'Daily Engagement (Last 30 Days)' },
    xAxis: { categories: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`) },
    yAxis: { title: { text: 'Engagement Rate' } },
    credits: { enabled: false },
    series: [{
      name: 'Blog',
      data: Array.from({ length: 30 }, () => parseFloat((Math.random() * 0.1 + 0.05).toFixed(2)))
    }, {
      name: 'Workout Video',
      data: Array.from({ length: 30 }, () => parseFloat((Math.random() * 0.15 + 0.1).toFixed(2)))
    }, {
      name: 'Awareness Video',
      data: Array.from({ length: 30 }, () => parseFloat((Math.random() * 0.12 + 0.08).toFixed(2)))
    }],
    credits: { enabled: false }
  };

  const overallSummaryData = [
    { contentType: 'Workout Videos', averageTimeViewed: '5 mins', averageLength: '30 mins', publishedContent: 2302 },
    { contentType: 'Awareness Videos', averageTimeViewed: '5 mins', averageLength: '35 mins', publishedContent: 3592 },
    { contentType: 'Blogs', averageTimeRead: '2 mins', averageLength: '5 mins', publishedContent: 1203 },
  ];

  const workoutVideoCategories = [
    { category: 'Strength', averageViews: 1228, averageConsumptionTime: '2.4 mins' },
    { category: 'Yoga', averageViews: 734, averageConsumptionTime: '3.2 mins' },
    { category: 'Dance', averageViews: 1177, averageConsumptionTime: '4.5 mins' },
    { category: 'Running', averageViews: 1492, averageConsumptionTime: '1.2 mins' },
  ];

  const awarenessVideoCategories = [
    { category: 'Physical Activities', averageViews: 1228, averageConsumptionTime: '2.4 mins' },
    { category: 'Nutrition', averageViews: 734, averageConsumptionTime: '3.2 mins' },
    { category: 'Sleep', averageViews: 1177, averageConsumptionTime: '4.5 mins' },
    { category: 'Stress', averageViews: 1492, averageConsumptionTime: '1.2 mins' },
  ];

  const blogCategories = [
    { category: 'Physical Activities', averageViews: 1228, averageReadTime: '5.4 mins' },
    { category: 'Nutrition', averageViews: 734, averageReadTime: '2.4 mins' },
    { category: 'Sleep', averageViews: 1177, averageReadTime: '3.2 mins' },
    { category: 'Stress', averageViews: 1492, averageReadTime: '4.5 mins' },
    { category: 'Woman Care', averageViews: 1282, averageReadTime: '1.2 mins' },
    { category: 'User Stories', averageViews: 2340, averageReadTime: '4.5 mins' },
  ];

  const topVideoCreators = [
    { name: 'John Doe', totalViews: 50000, totalLikes: 2500, engagementRate: '5.0%' },
    { name: 'Jane Smith', totalViews: 45000, totalLikes: 2200, engagementRate: '4.9%' },
    // Add more creators as needed
  ];

  const topBlogAuthors = [
    { name: 'Alice Johnson', totalViews: 30000, totalLikes: 1500, engagementRate: '5.0%' },
    { name: 'Bob Williams', totalViews: 28000, totalLikes: 1400, engagementRate: '5.0%' },
    // Add more authors as needed
  ];

  const renderFilters = () => {
    const selectClass = "tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5";
    return (
      <div className="tw-mb-8 tw-flex tw-flex-wrap tw-gap-4">
        <select
          className={selectClass}
          value={contentTypeFilter}
          onChange={(e) => setContentTypeFilter(e.target.value)}
        >
          <option value="All">All Content Types</option>
          <option value="Workout Video">Workout Video</option>
          <option value="Awareness Video">Awareness Video</option>
          <option value="Blog">Blog</option>
        </select>
        <select
          className={selectClass}
          value={ageFilter}
          onChange={(e) => setAgeFilter(e.target.value)}
        >
          <option value="All">All Ages</option>
          {/* Add age range options */}
        </select>
        <select
          className={selectClass}
          value={genderFilter}
          onChange={(e) => setGenderFilter(e.target.value)}
        >
          <option value="All">All Genders</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
        <select
          className={selectClass}
          value={programFilter}
          onChange={(e) => setProgramFilter(e.target.value)}
        >
          <option value="All">All Programs</option>
          {/* Add program options */}
        </select>
        <select
          className={selectClass}
          value={treatmentFilter}
          onChange={(e) => setTreatmentFilter(e.target.value)}
        >
          <option value="All">All Treatments</option>
          {/* Add treatment options */}
        </select>
        <select
          className={selectClass}
          value={pathwayFilter}
          onChange={(e) => setPathwayFilter(e.target.value)}
        >
          <option value="All">All Pathways</option>
          {/* Add pathway options */}
        </select>

        <button
          className="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 focus:tw-outline-none dark:focus:tw-ring-blue-800 tw-w-20"
        // onClick={handleApplyFilters}
        >
          Apply Filters
        </button>
        <button
          className="tw-border tw-border-black tw-text-black tw-bg-white hover:tw-bg-black hover:tw-text-white tw-font-medium tw-rounded-lg tw-text-sm tw-w-20"
        // onClick={handleResetFilters}
        >
          Reset Filters
        </button>
      </div>
    );
  }

  const workoutVideoCategoryChart = {
    chart: { type: 'column' },
    title: { text: 'Workout Video Categories' },
    xAxis: { categories: workoutVideoCategories.map(item => item.category) },
    yAxis: [
      { title: { text: 'Average Views' } },
      { title: { text: 'Average Consumption Time (mins)' }, opposite: true }
    ],
    series: [
      { name: 'Average Views', data: workoutVideoCategories.map(item => item.averageViews) },
      { name: 'Average Consumption Time', data: workoutVideoCategories.map(item => parseFloat(item.averageConsumptionTime)), yAxis: 1 }
    ],
    credits: { enabled: false }
  };

  const awarenessVideoCategoryChart = {
    chart: { type: 'column' },
    title: { text: 'Awareness Video Categories' },
    xAxis: { categories: awarenessVideoCategories.map(item => item.category) },
    yAxis: [
      { title: { text: 'Average Views' } },
      { title: { text: 'Average Consumption Time (mins)' }, opposite: true }
    ],
    series: [
      { name: 'Average Views', data: awarenessVideoCategories.map(item => item.averageViews) },
      { name: 'Average Consumption Time', data: awarenessVideoCategories.map(item => parseFloat(item.averageConsumptionTime)), yAxis: 1 }
    ],
    credits: { enabled: false }
  };

  const blogCategoryChart = {
    chart: { type: 'column' },
    title: { text: 'Blog Categories' },
    xAxis: { categories: blogCategories.map(item => item.category) },
    yAxis: [
      { title: { text: 'Average Views' } },
      { title: { text: 'Average Read Time (mins)' }, opposite: true }
    ],
    series: [
      { name: 'Average Views', data: blogCategories.map(item => item.averageViews) },
      { name: 'Average Read Time', data: blogCategories.map(item => parseFloat(item.averageReadTime)), yAxis: 1 }
    ],
    credits: { enabled: false }
  };

  const timeOfDayReadingChart = {
    chart: { type: 'line' },
    title: { text: 'Time of Day - Reading Blogs' },
    xAxis: { categories: ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'] },
    yAxis: { title: { text: 'Number of Readers' } },
    series: [{ name: 'Readers', data: [10, 5, 15, 50, 80, 65, 90, 70] }],
    credits: { enabled: false }
  };

  const timeOfDayWorkoutVideoChart = {
    chart: { type: 'line' },
    title: { text: 'Time of Day - Watching Workout Videos' },
    xAxis: { categories: ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'] },
    yAxis: { title: { text: 'Number of Viewers' } },
    series: [{ name: 'Viewers', data: [5, 2, 30, 60, 40, 55, 100, 80] }],
    credits: { enabled: false }
  };

  const timeOfDayAwarenessVideoChart = {
    chart: { type: 'line' },
    title: { text: 'Time of Day - Watching Awareness Videos' },
    xAxis: { categories: ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'] },
    yAxis: { title: { text: 'Number of Viewers' } },
    series: [{ name: 'Viewers', data: [15, 8, 25, 70, 85, 75, 95, 60] }],
    credits: { enabled: false }
  };

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-lg">
      <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Content Engagement Metrics Dashboard</h2>

      {renderFilters()}

      <div className="tw-mb-8">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4">Key Performance Indicators</h3>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
          {renderKPI("Total Views", "1,234,567", "The total number of views across all content types.")}
          {renderKPI("Total Likes", "98,765", "The total number of likes across all content types.")}
          {renderKPI("Average Engagement Rate", "8.2%", "The average percentage of users who interact with the content (e.g., like, comment, share).")}
          {renderKPI("Average Completion Rate", "76%", "The average percentage of content that users consume before leaving.")}
        </div>
      </div>


      <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4'>
        {renderChart(engagementByContentTypeChartOptions, "This chart shows the average engagement metrics for each content type.")}
        {renderChart(completionRateChartOptions, "This chart compares completion rate, engagement rate, and average time viewed across different content types.", "contentPerformance")}
        {renderChart(engagementOverTimeChartOptions, "This chart shows the engagement trends over the past 8 weeks.")}
        {renderChart(monthlyEngagementChartOptions, "This chart shows the monthly engagement trends for the past year.")}
        {renderChart(dailyEngagementChartOptions, "This chart shows the daily engagement trends for the past 30 days.")}

        {renderChart(workoutVideoCategoryChart, "This chart shows the average views and consumption time for different categories of workout videos.")}
        {renderChart(awarenessVideoCategoryChart, "This chart shows the average views and consumption time for different categories of awareness videos.")}
        {renderChart(blogCategoryChart, "This chart shows the average views and read time for different categories of blog posts.")}

        {renderChart(timeOfDayReadingChart, "This chart shows when users are most likely to read blog posts throughout the day.")}
        {renderChart(timeOfDayWorkoutVideoChart, "This chart shows when users are most likely to watch workout videos throughout the day.")}
        {renderChart(timeOfDayAwarenessVideoChart, "This chart shows when users are most likely to watch awareness videos throughout the day.")}
      </div>

      {renderTable(overallSummaryData, "Content Type Overview", "This table provides an overview of the average time viewed/read, average content length, and total published content for each content type.")}
      {/* {renderTable(workoutVideoCategories, "Workout Video Categories", "This table breaks down the performance of different workout video categories.")}
      {renderTable(awarenessVideoCategories, "Awareness Video Categories", "This table breaks down the performance of different awareness video categories.")}
      {renderTable(blogCategories, "Blog Categories", "This table breaks down the performance of different blog post categories.")} */}


      <div className="tw-mb-8">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4">Content Creators and Authors</h3>
        {renderTable(topVideoCreators, "Top Video Creators", "This table shows the most popular video content creators based on views and engagement.")}
        {renderTable(topBlogAuthors, "Top Blog Authors", "This table shows the most popular blog authors based on views and engagement.")}
      </div>

      {renderTable(top10ViewedData, "Top 10 Most Viewed Content", "This table shows the top 10 most viewed content pieces across all types.")}
      {renderTable(top10LikedData, "Top 10 Most Liked Content", "This table shows the top 10 most liked content pieces across all types.")}

      {/* <div className="mb-8">
        <button 
          className={`btn btn-primary ${isLoading ? 'loading' : ''}`} 
          onClick={handleAskAI}
          disabled={isLoading}
        >
          Ask AI for Analysis and Recommendation
        </button>
      </div> */}

      {renderTable(paginatedData, "All Content Performance", "This table shows all content performance data with pagination.")}

      {/* {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg max-w-5xl max-h-[65vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">AI Analysis and Recommendations</h3>
            <div className="mb-4">
              <h4 className="font-bold">Content Performance Analysis:</h4>
              <ul className="list-disc list-inside">
                <li>Workout videos consistently outperform other content types in terms of engagement rate (15% on average), suggesting a strong user interest in fitness-related content.</li>
                <li>Blogs have the highest completion rate (85% on average), indicating that users are more likely to read articles in full compared to watching entire videos.</li>
                <li>Awareness videos show a steady increase in engagement over the past 3 months, with a 20% growth in viewership.</li>
                <li>The top 10 most viewed content pieces generate 45% of total likes, highlighting the importance of creating high-quality, shareable content.</li>
                <li>There's a strong correlation (0.78) between the number of shares and the engagement rate, emphasizing the impact of social sharing on content performance.</li>
                <li>Mobile engagement is 30% higher than desktop engagement across all content types, suggesting a need for mobile-optimized content.</li>
                <li>User-generated content, such as workout progress videos, receives 25% more comments than professionally produced content, indicating a preference for authentic, relatable material.</li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold">Recommendations:</h4>
              <ul className="list-disc list-inside">
                <li>Increase production of workout videos, focusing on beginner to intermediate levels to capture a wider audience.</li>
                <li>Optimize blog content for mobile devices and consider implementing a "read time" feature to set user expectations.</li>
                <li>Develop a content series around awareness topics that have shown increasing engagement, such as mental health or nutrition.</li>
                <li>Implement a user-generated content campaign to encourage more authentic, community-driven content creation.</li>
                <li>Enhance social sharing features across all content types to leverage the strong correlation between shares and engagement.</li>
                <li>Create more bite-sized content (&lt;5 minutes) for awareness videos to improve completion rates while maintaining engagement.</li>
                <li>Implement a recommendation system that suggests related content based on user viewing history to increase overall engagement across content types.</li>
                <li>Develop a loyalty program that rewards users for consistent engagement, encouraging higher completion rates and repeat visits.</li>
                <li>Collaborate with influencers in the fitness and wellness space to create exclusive content and tap into their engaged audiences.</li>
                <li>Implement A/B testing for content titles and thumbnails to optimize click-through rates and initial engagement.</li>
              </ul>
            </div>
            <button className="btn btn-primary mt-4" onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )} */}

      <InfoModal
        isOpen={modalInfo.isOpen}
        onClose={() => setModalInfo({ isOpen: false, content: '' })}
        content={modalInfo.content}
      />
    </div>
  );
};

export default ContentEngagementDashboard;