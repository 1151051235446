import React, { useEffect, useState } from 'react';
import './ValidatePage.css';
import { Link, useHistory, useNavigate } from "react-router-dom";
import LanguageSetting from '../componenents/LanguageSetting';
import axios from "axios";
import { decruptCipherString, decruptCipherJson, CustomLoader } from './CommonLibrary';
import moment from "moment";
import { encryptData } from './Utils';

function ValidatePage() {

    const [dashboardList, setDashboardList] = useState('');
    const [tokenValue, setTokenValue] = useState('');
    const [notAccess, setNotAccess] = useState(false);
    const [userName, setUserName] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accessLevel, setAccessLevel] = useState(true);
    const [hideUserName, setHideUserName] = useState(true);

    const navigate = useNavigate();

    var setToken;
    const queryParams = new URLSearchParams(window.location.search);

    setToken = queryParams.get("token");

    useEffect(() => {
        localStorage.clear();
        const fetchData = async () => {
            axios.post(process.env.REACT_APP_BASE_URL + 'sso/validate_user', {
                token: setToken,
            })
                .then(function (response) {

                    if (response.data.code == 200) {
                        let loginData = decruptCipherJson(response.data.data);

                        if (loginData.data.status == 1) {
                            const redirectUrl = decruptCipherString(loginData.data.redirectUrl);
                            if (redirectUrl) {
                                setRedirectTo(redirectUrl);
                            }
                            localStorage.setItem('uid', loginData.data.userAdminId);
                            localStorage.setItem('user', loginData.data.username);
                            localStorage.setItem('authToken', loginData.data.verificationToken);
                            localStorage.setItem('isLoggedIn', loginData.data.redirectUrl);
                            localStorage.setItem('tabs', loginData.data.tabs);
                            localStorage.setItem('dashboard', loginData.data.dashboard);
                            localStorage.setItem("cnId", loginData.data.cnId);

                            const selectedDates = decruptCipherJson(loginData.data.selectedDates);
                            localStorage.setItem('selectedDates', encryptData(JSON.stringify([moment(selectedDates.startDate).toDate(), moment(selectedDates.endDate).toDate(), moment(selectedDates.ytdStartDate).toDate(), moment(selectedDates.ytdEndDate).toDate()])));

                        } else if (loginData.data.status == 2) {
                            localStorage.setItem('switch', response.data.switch);
                            localStorage.setItem('user', loginData.data.username);
                            localStorage.setItem("cnId", loginData.data.cnId);
                            setUserName(decruptCipherString(loginData.data.username));
                            setTokenValue(loginData.data.token);
                            setDashboardList(loginData.data.dashboardArray);
                            setLoading(false);
                        } else if (loginData.data.status == 304) {
                            setUserName(decruptCipherString(loginData.data.username));
                            setNotAccess(true);
                            setLoading(false);
                        } else {
                            setHideUserName(false);
                            setNotAccess(true);
                            setLoading(false);
                        }

                    } else {
                        alert('something went wrong');
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        fetchData();
    }, []);


    const loginUser = async (dashboardValue) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_BASE_URL + 'sso/login_dashboard', {
            dashboard: dashboardValue,
            token: tokenValue,
            accesslevel: accessLevel
        })
            .then(function (response) {

                if (response.data.code == 200) {
                    let loginData = decruptCipherJson(response.data.data);
                    console.log(loginData, "loginData")
                    const redirectUrl = decruptCipherString(loginData.data.redirectUrl);
                    if (redirectUrl) {
                        setRedirectTo(redirectUrl);
                    }

                    localStorage.setItem('uid', loginData.data.userAdminId);
                    localStorage.setItem('user', loginData.data.username);
                    localStorage.setItem('authToken', loginData.data.verificationToken);
                    localStorage.setItem('isLoggedIn', loginData.data.redirectUrl);
                    localStorage.setItem('tabs', loginData.data.tabs);
                    localStorage.setItem('dashboard', loginData.data.dashboard);
                    localStorage.setItem('accesslevel', loginData.data.accesslevel);
                    localStorage.setItem("cnId", loginData.data.cnId);
                    setAccessLevel(loginData.data.accesslevel);
                    const selectedDates = decruptCipherJson(loginData.data.selectedDates);
                    localStorage.setItem('selectedDates', encryptData(JSON.stringify([moment(selectedDates.startDate).toDate(), moment(selectedDates.endDate).toDate(), moment(selectedDates.ytdStartDate).toDate(), moment(selectedDates.ytdEndDate).toDate()])));

                } else {
                    setNotAccess(true);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    };


    if (redirectTo) {
        window.location.href = redirectTo;
    }

    if (loading) {
        return <div><CustomLoader /></div>;
    }


    return (
        <>
            <section className='ValidatePage'>
                {/* <div className='language-setting'>
                <LanguageSetting/>
            </div> */}
                <div className="card-login">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row gap-4">
                                <div className="col-md-auto ps-0">
                                    <img src="https://storecdn.goqii.com/media/store-img/goqii-logo_store.png" className="" alt="" />
                                </div>
                                <div className="col-md">
                                    {/* <h1 className="login-title mb-4 "><span>GOQii</span> HealthEngage</h1> */}
                                    <h1 className="login-title mb-4 "><img src="https://appcdn.goqii.com/storeimg/81058_1728039751.jpg" style={{ height: "60px" }} className="" alt="" /></h1>
                                </div>
                                {hideUserName &&
                                    <div className='col-auto pe-0'>
                                        <div className="dropdown-center">
                                            <button className="btn btn-secondary dropdown-toggle profile-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={require("../../images/icon-profile-image.png")} className="profile-images" alt="Profile" />{userName}
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-light"   >
                                                <li><a className="dropdown-item" href='/logout'><i className='bx bx-power-off' style={{ color: "#FF0000" }}></i> Log out</a></li>
                                            </ul>
                                        </div>
                                    </div>}
                            </div>

                            <div className="row">

                                <div className="col-md-12 px-0 mb-2">
                                    <div className="form-group">

                                        {
                                            notAccess ?
                                                <>
                                                    <div className="row mb-5">

                                                        <div className="col-md-12 text-center">
                                                            <img src="https://appcdn.goqii.com/storeimg/59682_1719301169.png" alt="" style={{ height: "240px" }} />
                                                            <h1 className="login-subtitle my-3" style={{ fontWeight: "400" }}>It seems like you're on a secret mission without the right access.
                                                                <br /> Better call your system administrator to unlock the doors!</h1>
                                                            <Link to="/login" className="btn btn-primary btn-login">
                                                                Ok
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </> :
                                                <>
                                                    {dashboardList ? (
                                                        <>
                                                            <div className='row justify-content-center'>
                                                                <div className="col-md-12">
                                                                    <h1 className="login-subtitle">Select your App</h1>
                                                                </div>
                                                                {dashboardList.map((data, index) => (
                                                                    <div className='col-md-auto px-4 text-center my-2' key={index}>
                                                                        <button className="btn btn-light btn-login-box my-1" onClick={(e) => loginUser(data.value)}>
                                                                            <img src={data.img} className="img-fluid box-ksa-icon m-auto" alt="" />
                                                                        </button>
                                                                        <h2 className='mt-1 login-box-title text-center'>{data.text}</h2>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                        }


                                    </div>
                                    {/* <div className='row mb-5 pb-3'>
                                    <div className='col-md text-center'>
                                        <button className="btn btn-light btn-login-box my-1" >
                                            <img src="https://appcdn.goqii.com/storeimg/81977_1719239020.png" className="img-fluid box-ksa-icon" alt="" />
                                        </button>
                                        <h2 className='mt-2 login-box-title'>Data Migrate</h2>
                                    </div>
                                    <div className='col-md text-center'>
                                        <button className="btn btn-light btn-login-box my-1" >
                                            <img src="https://appcdn.goqii.com/storeimg/26779_1719239363.png" className="img-fluid box-ksa-icon" alt="" />
                                        </button>
                                        <h2 className='mt-2 login-box-title'>Member Connect</h2>
                                    </div>
                                    <div className='col-md text-center'>
                                        <button className="btn btn-light btn-login-box my-1" >
                                            <img src="https://appcdn.goqii.com/storeimg/80275_1719239403.png" className="img-fluid box-ksa-icon" alt="" />
                                        </button>
                                        <h2 className='mt-2 login-box-title'>Medi Care Manage</h2>
                                    </div>
                                    <div className='col-md text-center'>
                                        <button className="btn btn-light btn-login-box my-1" >
                                            <img src="https://appcdn.goqii.com/storeimg/9404_1719239454.png" className="img-fluid box-ksa-icon" alt="" />
                                        </button>
                                        <h2 className='mt-2 login-box-title'>MedEngage</h2>
                                    </div>
                                    <div className='col-md text-center'>
                                        <button className="btn btn-light btn-login-box my-1" >
                                            <img src="https://appcdn.goqii.com/storeimg/7239_1719239511.png" className="img-fluid box-ksa-icon" alt="" />
                                        </button>
                                        <h2 className='mt-2 login-box-title'>Navigate Health</h2>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='powered-by-v mt-2'>

                    {/* <img src="https://appcdn.goqii.com/storeimg/91809_1719235434.png" style={{height:"42px"}} className="" alt="" /> */}
                    <p className='text-white'>©2024 GOQii. All Rights Reserved.</p>
                </div>
            </section>
        </>
    );
}

export default ValidatePage;
