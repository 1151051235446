import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Commingsoon } from '../../utils/CommonLibrary';
import { fetchCategoryData } from '../../../services/mtp/Service';

const TableAddNewServices = ({ data: initialData,key }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedService, setSelectedService] = useState('All');
    const [data, setData] = useState(initialData);
    const [selectedData, setSelectedData] = useState('');

    useEffect(() => {
        fetchData();
    }, [key]);

    const fetchData = async () => {
        fetchCategoryData()
        .then((response) => {   
            setSelectedData(response.data.data);
        });
    }

    const columns = [
        {
            name: 'Category',
            selector: row => row.categoryName,
            sortable: true,
        },
        {
            name: 'Services',
            selector: row => row.serviceName,
            sortable: true,
            
        },
        {
            name: 'Service Description',
            selector: row => row.description,
            sortable: true,
        },
        // {
        //     name: 'Associated Pathways',
        //     selector: row => row.pathwayName,
        //     sortable: true,
        // },
        // {
        //     name: 'Associated conditions',
        //     selector: row => row.conditionName,
        //     sortable: true,
        // },
        {
            name: 'Action',
            selector: row => row.action,
            cell: row => (
                <div><button data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal" class="btn btn-Edit">Edit</button> / <button data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal" class="btn btn-Cancel">Delete</button></div>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCategory('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery = 
            (user.fromCareNavigator && user.fromCareNavigator.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.toCareNavigator && user.toCareNavigator.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.categoryName && user.categoryName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.serviceName && user.serviceName.toLowerCase().includes(searchQuery.toLowerCase()));

        const matchesCategory = selectedCategory === 'All' || user.categoryName.includes(selectedCategory);

        return matchesSearchQuery && matchesCategory;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                            <input
                                type="text"
                                placeholder="Search By Category or Service Name"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-4 px-1'>
                        <select
                            value={selectedCategory}
                            onChange={e => setSelectedCategory(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Category--</option>
                            {selectedData.length > 0 && selectedData.map((option) => (
                                <option key={option.categoryId} value={option.categoryName}>
                                    {option.categoryName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
             <Commingsoon />
        </>

        
    );
}

export default TableAddNewServices;
