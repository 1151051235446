import React, { useState } from 'react';

const MemberEngagmentChallengePage = ()=>{

        return(
            <section className='ContentMemberEngagement'>
                 <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md ">
                        <h1 className="main-title">Member Challenges</h1>
                    </div>
                  
                </div>

               
                <div className='member-engagement mb-3'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header mb-4">
                                <h4 className="modal-subtitle">Available in Phase 2!</h4>
                                <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                                <img src="https://appcdn.goqii.com/storeimg/98520_1719229340.png" style={{height:"260px"}} alt="" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </section>

        );
    
}

export default MemberEngagmentChallengePage;