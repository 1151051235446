import React  from 'react';
import './Footer.css';
import { decruptCipherJson } from '../utils/CommonLibrary';

function Protected() {
  
    const menuArr = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : '';
    const dashboard = decruptCipherJson(menuArr);
  
    return (
      <>
        <section className='footer'>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src="https://appcdn.goqii.com/storeimg/1174_1719242636.png" style={{height:"42px"}} alt="" />
                    </div>
                    <div className="col-md-6">
                        <h6 className="footer-right">
                            Last active {dashboard.timeActive}
                        </h6>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default Protected;