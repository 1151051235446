import React from 'react';

import './CorporateActiveEmployees.css';

function CorporateActiveEmployees() {
  const corporateEmployee = [
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm10099/l_63504_1701514725.jpg',
        corpTItleEmployee: 'Harpreet G',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm60654/l_10289_1673181309.jpg',
        corpTItleEmployee: 'Ankit Rathod',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://goqii-app.s3.amazonaws.com/user/usercon/rdm10266/l_75080_1604669470.jpg',
        corpTItleEmployee: 'Hui Shang Hsue',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm63391/o_20579_1722945690.jpg',
        corpTItleEmployee: 'Vandana Juneja',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm56143/l_90384_1673495140.jpg',
        corpTItleEmployee: 'Dinesh Thakur',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm52333/l_97078_1709203109.jpg',
        corpTItleEmployee: 'PARWAGE ALAM',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm69898/l_65136_1709287989.jpg',
        corpTItleEmployee: 'Shazia Sadruddin',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm7772/l_52401_1719496414.jpg',
        corpTItleEmployee: 'Tufail QURESHI',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm2080/o_17596_1665039336.jpg',
        corpTItleEmployee: 'Kamal Chandran',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://goqii-app.s3.amazonaws.com/user/usercon/rdm96807/l_89019_1518003014.jpg',
        corpTItleEmployee: 'Roopa Tandur',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm30880/o_25698_1671011109.jpg',
        corpTItleEmployee: 'Yogita Sharma',
        corpUrlEmployee: '/users/',
    },
    {
        corpImgEmployee: 'https://appcdn.goqii.com/user/usercon/rdm64061/l_23873_1703220495.jpg',
        corpTItleEmployee: 'Vishal Gondal',
        corpUrlEmployee: '/users/',
    },
  ];

  return (
    <>
      {corporateEmployee.map((statEmp, index) => (
            <div  className="imageContainer col-2 mt-3" key={index} >
                <a href={statEmp.corpUrlEmployee}>
                    <img src={statEmp.corpImgEmployee} className="profile-img" alt="" />
                    <h1 className="Employees-title mb-0"> {statEmp.corpTItleEmployee} </h1>
                </a>
            </div>
      ))}
    </>
  );
}

export default CorporateActiveEmployees;
