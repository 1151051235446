import React, { useState, useEffect, useRef } from 'react';
import { FaUpload, FaArrowLeft, FaEye, FaPlus, FaPlay, FaVideo } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import Modal from './Modal';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {
    fetch_all_categories, fetch_all_tags, fetch_all_programs, fetch_all_conditions, fetch_all_pathways, save_post, fetch_single_article,
    update_post, getPresignedUrl, generateviewurl, sendApproval, log_data
} from "../../../services/cms/Service";
import axios from 'axios';

const LibraryVideoUpload = ({ videoData, onPreview, onCancel, onBack, categoriesList = [], tagsList = [], onAddCategory, onAddTag }) => {

    const { videoId } = useParams();
    const navigate = useNavigate();

    const [video, setVideo] = useState({
        title: '',
        description: '',
        videoUrl: "",
        thumbnailUrl: '',
        contentType: 'Workout Video',
        program: '',
        condition: '',
        pathway: '',
        language: 'English',
        author: '',
        categories: [],
        tags: [],
        publishedDate: new Date().toISOString().split('T')[0],
        videoType: 'library',
    });



    const [programs, setPrograms] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [pathways, setPathways] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState(tagsList);

    const [file, setFile] = useState(null);
    const [uploadUrl, setUploadUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    // Word limits
    const TITLE_LIMIT = 60;
    const DESCRIPTION_LIMIT = 300;

    const getWordCount = (text) => {
        return text.trim().split(/\s+/).length;
    };

    useEffect(() => {
        if (videoData) {
            setVideo(prevVideo => ({ ...prevVideo, ...videoData }));
        }
    }, [videoData]);

    useEffect(() => {
        fetchAllConditions();
        fetchAllPathways();
        fetchCategories();
        fetchTags();
        fetchPrograms();
    }, []);

    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [newSubCategory, setNewSubCategory] = useState('');
    const videoRef = useRef(null);

    const suggestedCategories = ['Lifestyle', 'Nutrition', 'Mental Health', 'Exercise'];

    const handleInputChange = (field, value) => {
        if (field === 'title' && getWordCount(value) > TITLE_LIMIT) return;
        if (field === 'description' && getWordCount(value) > DESCRIPTION_LIMIT) return;
        if (field === 'categories' || field === 'tags') {
            console.log(`Updating ${field} with:`, value);
            setVideo(prevVideo => ({ ...prevVideo, [field]: value }));
        } else if (field === "program") {
            setVideo(prevBlog => ({ ...prevBlog, program: value }));
            fetchAllConditions(value);
        } else if (field === "condition") {
            setVideo(prevBlog => ({ ...prevBlog, condition: value }));
            fetchAllPathways(video.program, value);
        }
        else {
            console.log(`Updating ${field} with:`, value);
            setVideo(prevVideo => ({ ...prevVideo, [field]: value }));
        }
    };

    // const handleVideoUpload = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         const videoUrl = URL.createObjectURL(file);
    //         handleInputChange('videoUrl', videoUrl);
    //         handleInputChange('videoType', 'local');
    //         handleInputChange('thumbnailUrl', ''); // Clear thumbnail for local videos
    //     }
    // };



    const handleUpload = async (e) => {
        try {
            setFile(e.target.files[0]);
            const file = e.target.files[0]
            // Send the file URL to the backend to get the presigned URL
            const response = await getPresignedUrl();

            const url = response.data.data;

            if (url) {
                setUploadUrl(url);
                setUploading(true);

                if (url) {
                    const result = await axios.put(url, file, {
                        headers: {
                            'Content-Type': file.type || 'application/octet-stream',
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        },
                    });
                    setUploading(false);
                    setVideoUrl(result.config.url);
                    if (result.status === 200) {
                        const res = await generateviewurl({ presignedUrl: result.config.url });
                        const videoUrl = res.data.data
                        setVideo((prev) => ({
                            ...prev,
                            videoUrl: videoUrl,
                            videoType: 'library', // or 'youtube' if it's a YouTube link
                        }));
                        alert('File uploaded successfully');
                    } else {
                        alert('Failed to upload file');
                    }
                }
            } else {
                alert('Failed to get upload URL');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('An error occurred during file upload');
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmit = async () => {

        const { title, description, program, pathway, condition } = video;
        let categoryIds = video.categories.map((category) => category.value);
        let tagIds = video.tags.map((tag) => tag.value);
        let videoURL = `<iframe
              width="100%"
              height="100%"
              src=${video.videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="tw-w-full tw-aspect-video"
            ></iframe>`;

        let data = { title, description, contentType: "library_video", author: 1, content: videoURL, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };


        try {
            const saveResp = await save_post(data);
            console.log(saveResp, "saveResp")
            if (saveResp?.data?.code === 200) {
                let id = saveResp?.data?.data?.pId;
                log_data({ pid: id, action: "send for Draft" })
                // Second API call: sendApproval using the post ID
                const approvalResp = await sendApproval({ pid: id });
                log_data({ pid: id, action: "send for Approval" })
                // Check response for the second API call
                if (approvalResp?.data?.data?.message === "Article Pending Approval") {
                    setIsModalOpen(true);
                    navigate('/content-management/content-status');
                }
            }
        } catch (e) {
            console.error("Error during approval process:", e);
        }

    };

    const handleCreateCategory = () => {
        if (newCategory) {
            const newCategoryObject = {
                value: newCategory.toLowerCase(),
                label: newCategory,
                subCategories: newSubCategory ? [newSubCategory] : []
            };
            setCategories([...categories, newCategoryObject]);
            setVideo(prevVideo => ({
                ...prevVideo,
                categories: [...prevVideo.categories, newCategoryObject]
            }));
            setNewCategory('');
            setNewSubCategory('');
            setShowCategoryModal(false);
        }
    };

    const handlePreview = () => {
        // onPreview(video);
    };

    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.style.display = 'block'; // Make the video visible
            videoRef.current.play(); // Play the video
        }
    };

    // fetch blog content categories 
    async function fetchCategories() {
        try {
            let response = await fetch_all_categories();

            if (response.data?.code === 200) {
                setCategories(response?.data?.data?.categories || []);
            } else {
                throw new Error("Failed to fetch categories");
            }
        } catch (e) {

        }
    }

    // fetch list of all tags 
    async function fetchTags() {
        try {
            let response = await fetch_all_tags();

            if (response.data?.code === 200) {
                setTags(response?.data?.data?.tags || []);
            } else {
                throw new Error("Failed to fetch tags");
            }
        } catch (e) {

        }
    }

    // fetch list of all programs 
    async function fetchPrograms() {
        try {
            let response = await fetch_all_programs();

            if (response.data?.code === 200) {
                setPrograms(response?.data?.data?.programs || []);
            } else {
                throw new Error("Failed to fetch programs");
            }
        } catch (e) {

        }
    }

    // fetch all conditions 
    async function fetchAllConditions(programId) {
        try {
            let response = await fetch_all_conditions(programId);

            if (response.data?.code === 200) {
                setConditions(response?.data?.data?.conditions || []);
            } else {
                throw new Error("Failed to fetch conditions");
            }
        } catch (e) {

        }
    }

    // fetch all pathways 
    async function fetchAllPathways(programId, conditionId) {
        try {
            let response = await fetch_all_pathways({ programId, conditionId });

            if (response.data?.code === 200) {
                setPathways(response?.data?.data?.pathways || []);
            } else {
                throw new Error("Failed to fetch conditions");
            }
        } catch (e) {

        }
    }

    return (
        <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl">
            <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-8 tw-text-gray-900">Upload Video</h2>

            {/* Form fields */}
            <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-my-4 tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                    <span className="tw-text-sm tw-mr-2">Title</span>
                </label>
                <input
                    type="text"
                    placeholder="Enter title"
                    className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-pr-16"
                    value={video.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                />
                <span className="tw-absolute tw-bottom-2 tw-right-4 tw-text-sm tw-text-gray-500">
                    {getWordCount(video.title)}/{TITLE_LIMIT}
                </span>
            </div>

            <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-my-4 tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                    <span className="tw-text-sm tw-mr-2">Description</span>
                </label>
                <textarea
                    placeholder="Enter description"
                    className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-pr-16"
                    value={video.description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                />
                <span className="tw-absolute tw-bottom-2 tw-right-4 tw-text-sm tw-text-gray-500">
                    {getWordCount(video.description)}/{DESCRIPTION_LIMIT}
                </span>
            </div>

            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-mb-6">
                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Content Type</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.contentType}
                        onChange={(e) => handleInputChange('contentType', e.target.value)}
                    >
                        <option>Workout Video</option>
                        <option>Awareness Video</option>
                    </select>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Creator</span>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter creator name"
                        className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full"
                        value={video.author}
                        onChange={(e) => handleInputChange('author', e.target.value)}
                    />
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Categories</span>
                    </label>
                    <div className="tw-flex tw-items-center tw-space-x-2">
                        <Select
                            isMulti
                            options={categories.map(cat => ({ value: cat.term_id, label: cat.name }))}
                            value={video.categories}
                            onChange={(selectedOptions) => handleInputChange('categories', selectedOptions)}
                            className="tw-flex-grow"
                            placeholder="Select categories"
                        />
                        <Tooltip title="Add new category" position="top" animation="scale">
                            <button className="tw-p-2 tw-bg-blue-100 tw-rounded-full hover:tw-bg-blue-200 tw-cursor-pointer tw-transition-all tw-duration-300" onClick={() => onAddCategory()}>
                                <FaPlus />
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Tags</span>
                    </label>
                    <Select
                        isMulti
                        options={tags.map(tag => ({ value: tag.term_id, label: tag.name }))}
                        value={video.tags}
                        onChange={(selectedOptions) => handleInputChange('tags', selectedOptions)}
                        className="tw-w-full"
                        placeholder="Add Tags"
                        onCreateOption={(inputValue) => {
                            const newTag = { title: inputValue, description: '' };
                            onAddTag(newTag);
                            return { value: newTag.id, label: newTag.title };
                        }}
                        isCreatable={true}
                    />
                </div>
            </div>

            <div className='tw-grid tw-grid-cols-3 tw-gap-2 tw-mb-6'>
                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Program</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.program}
                        onChange={(e) => handleInputChange('program', e.target.value)}
                    >
                        <option disabled value="">Select Program</option>
                        {
                            programs.map((program) => (
                                <option key={program.programId} value={program.programId}>{program.programName}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Condition</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.condition}
                        onChange={(e) => handleInputChange('condition', e.target.value)}
                    >
                        <option disabled value="">Select Condition</option>
                        {
                            conditions.map((condition) => (
                                <option key={condition.conditionId} value={condition.conditionId}>{condition.conditionName}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Pathway</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.pathway}
                        onChange={(e) => handleInputChange('pathway', e.target.value)}
                    >
                        <option disabled value="">Select Pathway</option>
                        {
                            pathways.map((pathway) => (
                                <option key={pathway.pathwayId} value={pathway.pathwayId}>{pathway.pathwayName}</option>
                            ))
                        }

                    </select>
                </div>
            </div>

            {/* Video upload and preview section */}
            <div className="tw-mb-6">
                {video.videoUrl ? (
                    <div className="tw-relative tw-w-full tw-aspect-video tw-mb-4">
                        {video.videoType === 'youtube' ? (
                            <iframe
                                width="100%"
                                height="100%"
                                src={video.videoUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="tw-w-full tw-aspect-video"
                            ></iframe>
                        ) : video.videoType === 'library' ? (
                            <>
                                <img
                                    src={video.thumbnailUrl}
                                    alt={video.title}
                                    className="tw-w-full tw-h-full tw-object-cover tw-rounded"
                                />
                                <button
                                    className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-text-white"
                                    onClick={handlePlayVideo}
                                >
                                    <FaPlay className="tw-text-6xl" />
                                </button>
                                <video
                                    ref={videoRef}
                                    src={video.videoUrl} // Use the presigned URL here
                                    controls
                                    className="tw-w-full tw-aspect-video tw-rounded"
                                    preload="auto"
                                >
                                    Your browser does not support the video tag.
                                </video>
                            </>
                        ) : (
                            // Local video
                            <video
                                ref={videoRef}
                                src={video.videoUrl}
                                controls
                                className="tw-w-full tw-h-full tw-object-cover"
                            />
                        )}
                    </div>
                ) : (
                    <div className="tw-border-dashed tw-border-2 tw-border-gray-300 tw-p-12 tw-text-center tw-mb-4 tw-aspect-video tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50">
                        <FaVideo className="tw-text-4xl tw-mb-4 tw-text-gray-400" />
                        <p className="tw-text-lg tw-text-gray-600">No video uploaded yet</p>
                        <p className="tw-text-xs tw-text-gray-500 tw-mt-1 tw-mb-4">(Video File, Upto 500 MB or 45 minutes.)</p>
                        <label htmlFor="video-upload" className="tw-flex tw-items-center hover:tw-scale-105 tw-cursor-pointer tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" disabled={uploading}>
                            <FaUpload className="tw-mr-2" /> {uploading ? 'Uploading...' : 'Upload'} Video
                        </label>
                        <input
                            id="video-upload"
                            type="file"
                            accept="video/*"
                            onChange={handleUpload}
                            className="tw-hidden"
                        />
                        {uploading && (
                            <div>
                                <progress value={uploadProgress} max="100"></progress>
                                <span>{uploadProgress} %</span>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="tw-flex tw-justify-between tw-items-center">
                <button className="tw-flex tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={onBack}>
                    <FaArrowLeft className="tw-mr-2" /> Back
                </button>
                <div className="tw-space-x-2 tw-flex tw-flex-row">
                    <button className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-4 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" onClick={handleSubmit}>Submit for Approval</button>
                    <button className="tw-flex tw-items-center hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-4 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" onClick={handlePreview}>
                        <FaEye className="tw-mr-2" /> Preview
                    </button>
                </div>
                <button className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={onCancel}>Cancel</button>
            </div>

            <Modal
                title="Success"
                content="Video uploaded successfully"
                action="success"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />

            {showCategoryModal && (
                <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
                    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-96">
                        <h3 className="tw-text-xl tw-font-bold tw-mb-4">Create New Category</h3>
                        <input
                            type="text"
                            placeholder="New Category"
                            className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-mb-4"
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Sub-category (optional)"
                            className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-mb-4"
                            value={newSubCategory}
                            onChange={(e) => setNewSubCategory(e.target.value)}
                        />
                        <div className="tw-mb-4">
                            <h4 className="tw-font-semibold tw-mb-2">Suggested Categories:</h4>
                            <div className="tw-flex tw-flex-wrap tw-gap-2">
                                {suggestedCategories.map((category, index) => (
                                    <button
                                        key={index}
                                        className="tw-border-2 tw-border-black tw-px-2 tw-py-1 tw-text-xs tw-bg-gray-200 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                                        onClick={() => setNewCategory(category)}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-end tw-space-x-2">
                            <button className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-bg-gray-200 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={handleCreateCategory}>Create</button>
                            <button className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={() => setShowCategoryModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LibraryVideoUpload;