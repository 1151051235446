import React from 'react';

import './ActiveCorporates.css';


function ActiveCorporates() {
  const corporateManageCorporate = [
    {
        corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/88013_1727347472.jpg',
        corpTItleManageCorporate: 'ONGC',
        corpUrlManageCorporate: '/corporate/',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/79550_1727348575.jpg',
      corpTItleManageCorporate: 'Indian Oil Corporation',
      corpUrlManageCorporate: '/corporate/',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/96708_1727348242.jpg',
      corpTItleManageCorporate: 'HPCL',
      corpUrlManageCorporate: '/corporate/',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/18364_1727348619.jpg',
      corpTItleManageCorporate: 'Cognizant GCC',
      corpUrlManageCorporate: '/corporate/',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/66567_1727348776.jpg',
      corpTItleManageCorporate: 'LinkedIN',
      corpUrlManageCorporate: '/corporate/',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/35510_1727348880.jpg',
      corpTItleManageCorporate: 'Larsen & Toubro',
      corpUrlManageCorporate: '/corporate/',
    },
    {
      corpImgManageCorporate: 'https://appcdn.goqii.com/storeimg/45555_1727349080.jpg',
      corpTItleManageCorporate: 'Tata Motors',
      corpUrlManageCorporate: '/corporate/',
    },
    
  ];

  return (
    <>
      {corporateManageCorporate.map((statEmp, index) => (
            <div  className="corporate-profile-imageContainer col-md mt-3" key={index} >
                <a href={statEmp.corpUrlManageCorporate}>
                    <img src={statEmp.corpImgManageCorporate} className="corporate-profile-img" alt="" />
                    <h1 className="corporate-profile-img-title mb-0"> {statEmp.corpTItleManageCorporate} </h1>
                </a>
            </div>
      ))}
    </>
  );
}

export default ActiveCorporates;
