import React, { useState } from 'react';
import { SiElasticsearch } from 'react-icons/si';
import { FaShieldAlt } from 'react-icons/fa';

const siemSolutions = [
  { 
    name: 'Elastic Search', 
    icon: SiElasticsearch, 
    description: 'Distributed, RESTful search and analytics engine capable of addressing a growing number of use cases.',
  },
  { 
    name: 'QRadar', 
    icon: FaShieldAlt, 
    description: "IBM's security intelligence platform designed to provide insights into an organization's security posture.",
  },
];

const SIEMCard = ({ name, Icon, description, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-start">
      <div className="tw-w-20 tw-h-20 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-5xl" />
      </div>
      <div>
        <h3 className="tw-text-xl tw-font-bold tw-text-[#03335b] tw-mb-2">{name}</h3>
        <p className="tw-text-sm tw-text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const SIEM = () => {
  const [activeSolution, setActiveSolution] = useState(null);

  return (
    <div className="tw-bg-gray-50 tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <p className="tw-text-gray-600 tw-mb-6">
        Integrate SIEM solutions to enhance security monitoring, event correlation, and threat detection capabilities in your healthcare platform.
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
        {siemSolutions.map((solution, index) => (
          <SIEMCard
            key={solution.name}
            name={solution.name}
            Icon={solution.icon}
            description={solution.description}
            isActive={index === activeSolution}
            onClick={() => setActiveSolution(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SIEM;