import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './TopBar.css'

const TopBar = (props) => {
    const active = (props.active) ? props.active : "";
    const params = useParams();
    const userId = params.memberId;
    const handleTabClose = (user) => {
        props.closeUserTab(user);
    };

    //const uniqueItems = [...new Set(props.userTabs)];

    const uniqueUsers = [...new Map(props.userTabs.map(user => [user.userId, user])).values()];
    return (
        <>
            <Row className='top-bar'>
                <Col className='px-0'>
                    <ul>
                        <li className={`${active == "dashboard" ? "active" : ""}`}><Link to={`/dashboard`}><i className="bi bi-grid-fill"></i> Dashboard</Link></li>
                        {uniqueUsers.map((user, index) => (
                            <li key={index} className={`${user.userId == userId ? "active" : ""}`}>
                                <Link to={`/members/${user.userId}`}>{user.name}</Link>
                                <i className="bi bi-x-lg cross-btn-closs" aria-hidden="true" onClick={() => handleTabClose(user)}></i>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row >
        </>
    )
}

export default TopBar