import React,{useState} from "react";
import moment from "moment";
import CalendarDatePicker from "../../../componenents/CalendarDatePicker";
import CareNavigatorCommunicationsTable from "./CareNavigatorCommunicationsTable";
import { decryptData } from "../../../utils/Utils";


function CareNavigatorCommunicationsDetails() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());
    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <>
        <div className="ContentUserManagement">

        
            <div className="container-fluid">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md"> 
                        <h4 className="mb-0 content-main-title">CareNavigator Communications</h4>
                    </div>
                    <div className="col-md-3"> 
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange}/>
                    </div>
                </div>

                <div className="row align-items-center pb-3">
                <div className='reports-programs'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://appcdn.goqii.com/storeimg/25004_1719310619.png" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The CareNavigator Communication function allows you to review all past communications sent to CareNavigator, perform audits, and find the status of these communications, such as delivery and receipt. This is crucial for maintaining effective and transparent member engagement.</p>
                        </div>
                    </div>
                </div>
                </div>

                <div className="row align-items-center pb-3">
                <div className='comntent-UserManagement mb-3'>
                <div className="row">
                    <div className="col-md-12">
                    <div className="table-responsive">
                        <CareNavigatorCommunicationsTable startDate={startDate} endDate={endDate} />
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
            
        </>
    );
}

export default CareNavigatorCommunicationsDetails;
