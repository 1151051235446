import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchMembersDisenroll, updateMembersDisenroll } from '../../../services/medengage/Service';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';

function NewRequestTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('All');
    const [selectedCondition, setSelectedCondition] = useState('All');

    const [membersDisenrollList, setMembersDisenrollList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMembersDisenrollList();
    }, []);
    
    const fetchMembersDisenrollList = async () => {
        setIsLoading(true);
        try {
            const response = await fetchMembersDisenroll();

            if (response.data.status === 'success') {
                setMembersDisenrollList(response.data.data.filter(data => data.requestStatus === 'Pending'));
            } else {
                setMembersDisenrollList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const formatDate = (inputDate) => {
        if (!inputDate) return '';
        const date = new Date(inputDate);
    
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        return formattedDate;
    }

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.disEnrollRequestedDate,
            format: row => row.disEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Program',
            selector: row => row.carePrograms,
            sortable: true,
        },
        {
            name: 'Condition',
            selector: row => row.chronicConditions,
            sortable: true,
        },
        {
            name: 'Enrollment Date',
            selector: row => row.enrollmentTime,
            format: row => row.enrollmentTime,
            sortable: true,
        },
        {
            name: 'Care Navigator Name',
            selector: row => row.disEnrollBy,
            sortable: true,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
        },
        {
            name: 'Care Navigator Comments',
            selector: row => row.disEnrollmentNote,
            sortable: true,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.disEnrollmentNote }} />
            ),
        },
        {
            name: 'Dis-EnrollmentType',
            id: 'disEnrollmentType',
            selector: row => row.disEnrollmentType,
            format: row => row.disEnrollmentType,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    <a href="#" className="btn-approve" onClick={() => handleStatusUpdate(row.logId, 'Approve')}>Approve</a> / <a href="#" className="btn-reject" onClick={() => handleStatusUpdate(row.logId, 'Reject')}>Reject</a>
                </div>
            ),
        },
    ];

    const handleStatusUpdate = async (id, type) => {
        setIsLoading(true);
        try {
            const payload = {
                logId: id,
                requestStatus: (type === 'Approve' ? 'Approved' : 'Rejected')
            }
            const response = await updateMembersDisenroll(payload);

            if (response.data.status === 'success') {
                setMembersDisenrollList((prev) => prev.filter((data) => data.logId !== id));

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('All');
        setSelectedCondition('All');
    };

    const newFilteredData = membersDisenrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesProgram = selectedProgram === 'All' || user.callCurrentStatus === selectedProgram;
            const matchesCondition = selectedCondition === 'All' || user.chronicConditions === selectedCondition;
            return matchesSearchQuery && matchesProgram && matchesCondition;
    });


    const handleExport = () => {

        const modifiedData = newFilteredData.map(item => {
            return {
                'Request Date': item.disEnrollRequestedDate,
                'Member ID': item.membershipNo,
                'Member Name': item.memberName,
                'Mobile No': item.mobile,
                'Program': item.carePrograms,
                'Condition': item.chronicConditions,
                'Enrollment Date': item.enrollmentTime,
                'Care Navigator Name': item.disEnrollBy,
                'Reason': item.reason,
                'Care Navigator Comments': item.disEnrollmentNote,
                'Dis-EnrollmentType': item.disEnrollmentType,
            };
        });

        modifiedData.forEach(function(v){ delete v.approvedBy;delete v.approvedDate;delete v.logId;delete v.mobile; delete v.requestStatus; delete v.userId; });

        exportTableData("De-EnrollNewRequest",modifiedData);
    };

    return (
        <>
        <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search by Member Name"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Programs</option>
                            <option value="CareConnect Model">CareConnect Model</option>
                            
                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Conditions</option>
                            <option value="Diabetes Mellitus">Diabetes Mellitus</option>
                            
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={newFilteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default NewRequestTable;
