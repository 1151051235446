import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './InsurePlus.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import InsurePlusEmployeesOnboarded from './component/InsurePlus/InsurePlusEmployeesOnboarded';
import InsurePlusCovered from './component/InsurePlus/InsurePlusCovered';
import EmployeePolicyTable from './component/InsurePlus/EmployeePolicyTable';
import ClaimHistorTable from './component/InsurePlus/ClaimHistorTable';
import DeletePolicyTable from './component/InsurePlus/DeletePolicyTable';

function InsurePlus () {

  const handleBackButton = () => {
    navigate('/blocker-pop-ups');
  }

  const navigate = useNavigate();

  
  return (
    <>
      <section className='InsurePlus'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                {/* <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button> */}
                  <h1 className="main-title" style={{ margin: '0' }}>Insure+</h1>
              </div>
              <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#addNewPolicyPopUps">Add Policy</Link></div>

          </div>

          <div className='insure-plus p-3 mb-3'>
            <div className="row align-items-center">
                {/* <div className="col-md-auto">
                  <h1 className="dashboard-title">Hello abcd !</h1>
                  <h2 className="dashboard-subtitle mb-3">Lorem ipsum dolor sit amet, consectetur</h2>
                  <Link to="" className="btn program-save-btn">Add Policy</Link>
                </div> */}
                <div className="col-md">
                    <InsurePlusEmployeesOnboarded/>
                </div>
            </div>
          </div>

          <div className='insure-plus p-3 mb-3'>
              <div className="row">
                  <div className="col">
                    <h1 className="dashboard-corpwell-title">Insure+ Insights</h1>
                  </div>
              </div>
              <div className="row">
                  <InsurePlusCovered/>
              </div>
          </div>


          <div className='insure-plus p-3 mb-3'>
              <div className="row">
                  <div className="col-md-12">
                    <Tabs>
                        <TabList>
                          <Tab>Employee Policy</Tab>
                          <Tab>Claim History</Tab>
                          <Tab>Delete Policy</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="table-responsive tableEmployeePolicy">
                              <EmployeePolicyTable/>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="table-responsive tableEmployeePolicy">
                              <ClaimHistorTable/>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="table-responsive tableEmployeePolicy">
                              <DeletePolicyTable/>
                            </div>
                        </TabPanel>

                    </Tabs>
                  </div>
              </div>
            
          </div>

        </div>
      </section>


      <div className="modal fade" id="addNewPolicyPopUps" tabindex="-1" aria-labelledby="addNewPolicyPopUpsLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title" id="addNewPolicyPopUpsLabel">Add New Policy</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Policy Name:</label>
                        <input
                        type="text"
                        name="weight"
                        className="form-control control-input-ui"
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Policy Email:</label>
                        <input
                        type="text"
                        name="height"
                        className="form-control control-input-ui"
                        />
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn btn-primary save-btn me-3">
                        Add Policy
                        </button>
                    </div>
                    </div>
                </div>
        </div>
      </div>
    </div>

    </>
  );
}

export default InsurePlus ;
