import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL;

//fetch user list
export const fetch_user_data_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_user_data_list');
    return dataPass;
};

//fetch program master data MTP
export const fetch_program_data = () => {
    var dataPass = common_axios.get (baseURL + 'met/fetch_program_data');
    return dataPass;
};

//fetch service master data MTP

export const fetch_service_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_service_data');
    return dataPass;
};


export const fetch_admin_user_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_admin_user_data');
    return dataPass;
};


//To get admin user information
export const mtp_fetch_admin_user_data = (userId) => {
    let params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'met/met_fetch_admin_user_data', params);
    return dataPass;
};

//To check username already exist.
export const mtp_check_username_available = (userName) => {
    var dataPass = common_axios.get(baseURL + 'met/mtp_check_username_available?userName=' + userName);
    return dataPass;
};

//To get pathway data with frequency services
export const fetch_pathway_frequency_data = (pathwayId) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_pathway_frequency_data?pathwayId=' + pathwayId);
    return dataPass;
};

//To get member communication data
export const fetch_member_communication_data = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'met/fetch_member_communication_data', params);
    return dataPass;
};

//To get whatsapp template data
export const fetch_whatsapp_template_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_whatsapp_template_data');
    return dataPass;
};

//To get selected whatapp template data
export const single_whatsapp_template = (templateId) => {
    var dataPass = common_axios.get(baseURL + 'met/single_whatsapp_template?templateId=' + templateId);
    return dataPass;
};

//get condition from program data
export const fetch_program_condition_data = (programId) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_program_condition_data?programId=' + programId);
    return dataPass;
};

// get pathway data from condition
export const fetch_program_condition_pathway_data = (conditionId) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_program_condition_pathway_data?conditionId=' + conditionId);
    return dataPass;
};


//post data user creation MTP
export const mtp_user_creation_data = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/met_user_creation_data', formData);
    return dataPass;
};

//post data for program (codition,pathway) frequency creation MTP
export const program_creation_frequency_data = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/program_creation_frequency_data', formData);
    return dataPass;
};

//create frequency configuration(services data assign) template MTP
export const frequency_configuration_template_creation = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/frequency_configuration_template_creation', formData);
    return dataPass;
};



//Update data admin user met
export const mtp_admin_user_update_data = (userId, formData) => {
    var dataPass = common_axios.post(baseURL + 'met/met_admin_user_update_data?userId=' + userId, formData);
    return dataPass;
};


export const fetch_ad_users_list = (searchString) => {
    var requestBody = { searchString };
    var dataPass = common_axios.post(baseURL + 'met/fetch_ad_users', requestBody);
    return dataPass;
};
export const add_new_services = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/add_new_services', formData);
    return dataPass;
};

export const frequency_service_update = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/frequency_service_update', formData);
    return dataPass;
};

export const fetch_single_frequency_configuration_template = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/fetch_single_frequency_configuration_template', formData);
    return dataPass;
};

export const agent_call_initiate = (userId) => {
    var userId = { userId };
    var dataPass = common_axios.post(baseURL + 'met/agent_call_initiate', userId);
    return dataPass;
};

export const agent_call_hold = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_hold', data);
    return dataPass;
};

export const agent_call_unhold = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_unhold', data);
    return dataPass;
};

export const agent_call_mute = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_mute', data);
    return dataPass;
};

export const agent_call_disconnect = (confNumber) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_disconnect', { confNumber });
    return dataPass;
};

export const direct_agent_call_disconnect = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/direct_agent_call_disconnect', data);
    return dataPass;
};

// carenavigatorId and confNumber required
export const agent_transfer_call = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_transfer_call', formData);
    return dataPass;
};


export const fetch_uploaded_user_data_list = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_user_data_list', params);
    return dataPass;
};

export const fetch_single_user_data_list = (userId) => {
    var userId = { userId };
    var dataPass = common_axios.post(baseURL + 'met/fetch_single_user_data_list', userId);
    return dataPass;
};

export const fetch_soft_phone_token = (confNumber) => {
    var confNumber = { confNumber };
    var dataPass = common_axios.post(baseURL + 'met/fetchsoftphonetoken', confNumber);
    return dataPass;
};

export const fetch_user_callback_data_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_user_callback_data_list');
    return dataPass;
};

export const fetch_uploaded_summery_data_list = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_summery_data_list', params);
    return dataPass;
};

export const fetch_soft_number = (data) => {
    console.log("fetch_soft_number");
    var dataPass = common_axios.post(baseURL + 'met/fetch_soft_number', data);
    return dataPass;
};

export const direct_fetch_soft_number = (data) => {
    console.log("fetch_soft_number");
    var dataPass = common_axios.post(baseURL + 'met/direct_fetch_soft_number', data);
    return dataPass;
};

export const make_soft_phone_call = (confNumber) => {
    var confNumber = { confNumber };
    var dataPass = common_axios.post(baseURL + 'met/make_soft_phone_call', confNumber);
    return dataPass;
};


//fetch role master data  mcm
export const fetch_role_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_role_data');
    return dataPass;
};

//fetch agent master data for mcm
export const fetch_agent_user_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_agent_user_list');
    return dataPass;
};

//Enroll process
export const agent_enroll_user = (userId) => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/agent_enroll_user?userId=' + userId);
    return dataPass;
};


//assign care navigator process
export const agent_book_care_navigator = (userId, date, time, careNavigatorId) => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/agent_book_care_navigator?userId=' + userId + '&date=' + date + '&time=' + time + '&careNavigatorId=' + careNavigatorId);
    return dataPass;
};

//assign call back

export const agent_call_back_plan = (data) => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.post(baseURL + 'met/agent_call_back_plan', data);
    return dataPass;
};


export const agent_create_pool = () => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/create_pool');
    return dataPass;
};

export const agent_stats = () => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/agent_stats');
    return dataPass;
};

export const fetch_uploaded_user_batch_list = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_user_batch_list', params);
    return dataPass;
};

export const fetch_member_insight_data_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_member_insight_data_list');
    return dataPass;
};

export const agent_insight_stats = (startDate, endDate, agentId) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_insight_stats', params);
    return dataPass;
};

export const fetch_member_agent_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_member_agent_list');
    return dataPass;
};

export const agent_configuration_setting = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_configuration_setting', formData);
    return dataPass;
};





export const agent_call_user_status = (confNumber) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_user_status', { confNumber });
    return dataPass;
}


export const reject_enrollment = (data) => {

    var dataPass = common_axios.post(baseURL + 'met/reject_enrollment', data);
    return dataPass;
}


export const fetch_carenavigator_list = () => {

    var dataPass = common_axios.get(baseURL + 'met/fetch_care_list');
    return dataPass;
}

export const fetch_graph_enroll_program_per_agent = (startDate, endDate, agentId) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.get(baseURL + 'met/fetch_graph_enroll_program_per_agent');
    return dataPass;
}


export const fetch_available_slot = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/fetch_available_slot', data);
    return dataPass;
}


export const save_carenavigator_slot = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/save_carenavigator_slot ', data);
    return dataPass;
}


export const call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/call_disposition', data);
    return dataPass;
}

export const direct_call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/direct_call_disposition', data);
    return dataPass;
}


export const call_disposition_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/call_disposition_list');
    return dataPass;
}

export const fetch_incorrect_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_incorrect_data');
    return dataPass;
}


export const data_quality_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/data_quality_list');
    return dataPass;
}


export const save_alt_number = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/save_alt_number', data);
    return dataPass;
}

export const fetch_progtramcondition_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_programcondition_data');
    return dataPass;
}

export const fetch_agent_setting_script_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_agent_setting_script_data');
    return dataPass;
}

export const fetch_template_count_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_template_count_data');
    return dataPass;
}

export const fetch_last_save_setting_list = (data) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_last_save_setting_list', data);
    return dataPass;
}


export const cn_call_exit = (data) => {
    let response = common_axios.post(baseURL + 'met/cn_call_exit', data);
    return response;
}

export const accept_enrollment = (data) => {
    let response = common_axios.post(baseURL + 'met/accept_enrollment', data);
    return response;
}

export const direct_accept_enrollment = (data) => {
    let response = common_axios.post(baseURL + 'met/direct_accept_enrollment', data);
    return response;
}

export const cn_call_user_status = (data) => {
    let response = common_axios.post(baseURL + 'met/cn_call_user_status', data);
    return response;
}
export const fetch_audio_play_list = (callId) => {
    let params = { 'callId': callId }
    let response = common_axios.post('met/fetch_audio_play_list', params);
    return response;
}


export const soft_number_logger = (data) => {
    return common_axios.post(baseURL + 'met/soft_number_logger', data);
}

export const fetch_user_attempt_call_list = (startDate, endDate, userId) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'userId': userId }
    return common_axios.post(baseURL + 'met/fetch_user_attempt_call_list', params);
}

export const fetch_setting_disposition_agent_list = () => {
    return common_axios.get(baseURL + 'met/fetch_setting_disposition_agent_list');
}

export const add_new_call_disposition = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/add_new_call_disposition', formData);
    return dataPass;
};

export const edit_new_call_disposition = (formData) => {
    console.log("form", formData);
    var dataPass = common_axios.post(baseURL + 'met/edit_new_call_disposition', formData);
    return dataPass;
};

export const edit_get_new_call_disposition = (id) => {
    let params = { 'dispositionId': id };
    var dataPass = common_axios.post(baseURL + 'met/edit_get_new_call_disposition', params);
    return dataPass;
};

export const single_delete_new_call_disposition = (id) => {
    let params = { 'dispositionId': id };
    var dataPass = common_axios.post(baseURL + 'met/single_delete_new_call_disposition', params);
    return dataPass;
};

export const data_quality_disposition_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/data_quality_disposition_list');
    return dataPass;
}

export const incorrect_data = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/incorrect_data', data);
    return dataPass;
}

export const enroll_later = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/enroll_later', data);
    return dataPass
}

export const fetch_agent_enroll_later_pending_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_agent_enroll_later_pending_list');
    return dataPass
}

export const fetch_agent_enroll_later_approve_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_agent_enroll_later_approve_list');
    return dataPass
}

export const update_enroll_later_agent = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/update_enroll_later_agent', data);
    return dataPass
}

export const reject_enroll_later_agent = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/reject_enroll_later_agent', data);
    return dataPass
}


export const fetchMemberPersonalData = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'met/fetch_member_personal_data', params, cancelToken)
    return dataPass;
};

export const checkMembership = (membershipNo, cancelToken) => {
    const params = { 'membershipNo': membershipNo }
    var dataPass = common_axios.post(baseURL + 'met/check_member_details', params, cancelToken)
    return dataPass;
};

export const saveNewMember = (formData, memberType, cancelToken) => {
    const params = { 'formData': formData, 'memberType': memberType }
    var dataPass = common_axios.post(baseURL + 'met/save_new_member', params, cancelToken)
    return dataPass;
};

export const agent_call_disposition_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_call_disposition_graph', params)
    return dataPass;
};

export const members_age_group_conversion_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/members_age_group_conversion_graph', params)
    return dataPass;
};

export const agent_correct_incorrect_graph = (dataRating, startDate, endDate, agentId) => {
    const params = { 'dataRating': dataRating, 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_correct_incorrect_graph', params)
    return dataPass;
};

export const agent_data_quality_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_data_quality_graph', params)
    return dataPass;
};

export const agent_call_conversion_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_call_conversion_graph', params)
    return dataPass;
};

export const agent_daily_weekly_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_daily_weekly_graph', params)
    return dataPass;
};

export const agent_call_made_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_call_made_graph', params)
    return dataPass;
};

export const agent_members_reached_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_members_reached_graph', params)
    return dataPass;
};

export const agent_members_enrolled_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_members_enrolled_graph', params)
    return dataPass;
};

export const agent_target_achievement_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_target_achievement_graph', params)
    return dataPass;
};

export const member_conversion_funnel_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/member_conversion_funnel_graph', params)
    return dataPass;
};


export const member_insight_summery_data = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/member_insight_summery_data', params)
    return dataPass;
};

export const fetch_all_categories = () => {
    var dataPass = common_axios.get('cms/fetch_all_categories');
    return dataPass;
}

export const fetch_all_tags = () => {
    var dataPass = common_axios.get('cms/fetch_all_tags');
    return dataPass;
}

export const fetch_all_programs = () => {
    var dataPass = common_axios.get('cms/fetch_all_programs');
    return dataPass;
}

export const fetch_all_conditions = (programId) => {
    var dataPass = common_axios.post('cms/fetch_all_conditions', { programId });
    return dataPass;
}

export const fetch_all_pathways = (data) => {
    var dataPass = common_axios.post('cms/fetch_all_pathways', data);
    return dataPass;
}

export const save_post = (data) => {
    var dataPass = common_axios.post('cms/save_post', data);
    return dataPass;
}

export const update_post = (data) => {
    var dataPass = common_axios.post('cms/resave_post', data);
    return dataPass;
}

export const save_members_notes = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/save_members_notes', data);
    return dataPass;
}

export const fetch_members_notes = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/fetch_members_notes', data);
    return dataPass;
}

export const fetch_single_article = (data) => {
    var dataPass = common_axios.post(baseURL + 'cms/fetch_single_article', data);
    return dataPass;
}

export const all_drafted_articles = (data) => {
    var dataPass = common_axios.post(baseURL + 'cms/all_drafted_articles', data);
    return dataPass;
}

export const all_approval_articles = (data) => {
    var dataPass = common_axios.post(baseURL + 'cms/all_approval_articles', data);
    return dataPass;
}

export const fetch_download_agent = (startDate, endDate, downloadEvent) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'downloadEvent': downloadEvent }
    var dataPass = common_axios.post(baseURL + 'met/fetch_download_agent', params);
    return dataPass;
}

export const fetch_reports_agent = () => {
    var dataPass = common_axios.get(baseURL + 'met/reports_agents_fetch');
    return dataPass;
}

export const getFileLink = (id) => {
    let params = { 'reportId': id }
    var dataPass = common_axios.post(baseURL + 'met/fetch_fileurl', params);
    return dataPass;
}

export const getPresignedUrl = (params) => {
    var dataPass = common_axios.get(baseURL + 'cms/generatepresignedurl', params);
    return dataPass;
}

export const generateviewurl = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/generateviewurl', params);
    return dataPass;
}

export const fetchAllBlogs = (params) => {
    var dataPass = common_axios.get(baseURL + 'cms/fetch_all_blogs', params);
    return dataPass;
}

export const fetchSelectedBlog = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/fetch_selected_blog', params);
    return dataPass;
}

export const fetchSelectedVideo = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/fetch_selected_video', params);
    return dataPass;
}

export const fetchVideoLibrary = (params) => {
    var dataPass = common_axios.get(baseURL + 'cms/fetch_video_library', params);
    return dataPass;
}

export const saveLibraryPost = (params) => {
    var dataPass = common_axios.get(baseURL + 'cms/save_library_post', params);
    return dataPass;
}

export const updatePost = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/update_post', params);
    return dataPass;
}

export const fetchCategoryCount = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/fetch_category_count', params);
    return dataPass;
}

export const addNewCategoryTag = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/add_new_category_tag', params);
    return dataPass;
}


export const fetchAuthors = (params) => {
    var dataPass = common_axios.get(baseURL + 'cms/fetch_authors', params);
    return dataPass;
}

export const approveArticles = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/approve_articles', params);
    return dataPass;
}

export const sendApproval = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/send_approval', params);
    return dataPass;
}

export const rejectArticles = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/reject_articles', params);
    return dataPass;
}

export const fetchApprovedArticles = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/fetch_approved_articles', params);
    return dataPass;
}

export const publishUnpublishArticles = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/publish_unpublish_articles', params);
    return dataPass;
}

export const createLog = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/create_log', params);
    return dataPass;
}

export const generateAiBlog = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/generate_ai_blog', params);
    return dataPass;
}

export const fetchPublishedBlogs = (params) => {
    var dataPass = common_axios.get(baseURL + 'cms/fetch_published_blogs', params);
    return dataPass;
}

export const addComments = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/add_comments', params);
    return dataPass;
}

export const fetchBlog = (params) => {
    var dataPass = common_axios.post(baseURL + 'cms/fetch_blog', params);
    return dataPass;
}



