import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load the 3D module
require('highcharts/highcharts-3d')(Highcharts);

const SAFETeamChart = () => {
    const columnGraphHeight = 300; // Adjust height to match design
    const teamCategories = ['Core', 'GOQii BetheForce', 'Design, E commerce & Warehouse Team', 'KKs Team', 'GOQii Levelup'];
    const yAxisJson = {
        title: {
            text: null // Remove Y-axis title
        },
        max: 150 // Adjust max value based on the chart
    };

    // Updated data based on the screenshot's bars
    const columnData = [
        {
            name: 'Not Started',
            data: [0, 0, 10, 0, 0],
            color: '#808080'
        },
        {
            name: 'Inactive',
            data: [0, 0, 10, 0, 0],
            color: '#800080'
        },
        {
            name: 'Sedentary',
            data: [0, 0, 20, 0, 20],
            color: '#eb6b56'
        },
        {
            name: 'Active',
            data: [0, 10, 10, 20, 0],
            color: '#f1c04d'
        },
        {
            name: 'Fit',
            data: [0, 10, 30, 40, 10],
            color: '#5ea97d'
        },
        {
            name: 'Elite',
            data: [50, 10, 0, 40, 0],
            color: '#4572a7'
        }
    ];

    const optionsSLT = {
        chart: {
            animation: false,
            type: 'bar',
            height: columnGraphHeight,
            options3d: {
                enabled: true,
                alpha: 1,
                beta: 10,
                depth: 550,
                viewDistance: 5
            },
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: teamCategories,
            labels: {
                rotation: 0,
                style: {
                    fontSize: '14px',
                    fontWeight: 400
                }
            },
            gridLineWidth: 0
        },
        series: columnData, // Use updated data
        credits: {
            enabled: false
        },
        yAxis: {
            tickInterval: 20,
            gridLineWidth: 0,
            startOnTick: false,
            min: 0,
            max: 100, // Adjust max to fit the highest value
            title: {
                text: ''
            },
            labels: {
                enabled: false
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                return `<b>${this.series.name}</b>: ${this.y}`;
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
                }
            }
        },
        plotOptions: {
            series: {
                animation: false,
                stacking: 'normal',
                showInLegend: true,
                pointPadding: 0.1,
                pointWidth: 30, // Set a static width for bars
                dataLabels: {
                    enabled: false,
                    align: 'right', // Align labels to the right of the bars
                    inside: false, // Ensure labels are outside the bars
                    x: 5, // Adjust x position to move it slightly away from the bar
                    style: {
                        textOutline: 'none',
                    }
                },
            }
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={optionsSLT} />;
};

export default SAFETeamChart;
