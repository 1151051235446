import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { fetch_agent_enroll_later_approve_list } from '../../../services/met/Service';
import { time } from 'highcharts';

function PastRequestRegisterLaterTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('All');
    const [selectedCondition, setSelectedCondition] = useState('All');

    const [membersEnrollList, setMembersEnrollList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAgentMemberEnrollList();
    }, []);
    
    const fetchAgentMemberEnrollList = async () => {
        setIsLoading(true);
        try {
            let response = await fetch_agent_enroll_later_approve_list();
            if(response.data.code === 200){
                setMembersEnrollList(response.data.data);
            }
           
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const formatDate = (inputDate) => {
        if (!inputDate) return '';
        const date = new Date(inputDate);
    
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        return formattedDate;
    }

    const columns = [
        {
            name: 'Request Created Date',
            selector: row => row.createdDate,
            format: row => row.createdDate,
            sortable: true,
        },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            format: row => row.agentName,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            format: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            format: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.memberMobile,
            format: row => row.memberMobile,
            sortable: true,
        },
        
        {
            name: 'Program',
            selector: row => row.programName,
            format: row => row.programName,
            sortable: true,
        },
        {
            name: 'Condition',
            selector: row => row.conditionName,
            format: row => row.conditionName,
            sortable: true,
        },

        {
            name: 'CareNavigator Booking Date',
            selector: row => row.careBookingDate,
            format: row => row.careBookingDate,
            sortable: true,
        },
        {
            name: 'Care Navigator',
            selector: row => row.careNavigatorName,
            sortable: true,
        },
        {
            name: 'Action By',
            selector: row => row.actionBy,
            sortable: true,
        },

        {
            name: 'Action ',
            selector: row => row.approvedStatus,
            sortable: true,
        },
       
        {
            name: 'Action Date ',
            selector: row => row.approvedDate,
            sortable: true,
        },
    ];

    
    const handleStatusUpdate = async (id, type) => {
        // setIsLoading(true);
        // try {
        //     const payload = {
        //         logId: id,
        //         requestStatus: (type === 'approve' ? 'approved' : 'rejected')
        //     }
        //     const response = await updateMembersDisenroll(payload);

        //     if (response.data.status === 'success') {
        //         setMembersEnrollList((prev) => prev.filter((data) => data.logId !== id));

        //         setIsLoading(false);
        //     }
        // } catch (error) {
        //     console.log(error);
        //     setIsLoading(false);
        // }
    }

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('All');
        setSelectedCondition('All');
    };

    const newFilteredDataPast = membersEnrollList.filter(user => {
        const matchesSearchQuery =
        user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.agentName.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesCondition = selectedCondition === 'All' || user.conditionName === selectedCondition;
        const matchesProgram = selectedProgram === 'All' || user.programName === selectedProgram;
        return matchesSearchQuery && matchesProgram && matchesCondition;
    });


    const handleExport = () => {

        const modifiedData = newFilteredDataPast.map(item => {
            return {
                'Request Created Date': item.createdDate,
                'Agent Name':item.agentName,
                'Member ID': item.membershipNo,
                'Member Name': item.memberName,
                'Mobile No': item.memberMobile,
                'Program': item.programName,
                'Condition': item.conditionName,
                'CareNaviogator Booking Date': item.careBookingDate,
                'Care Navigator': item.conditionName,
                'Action ': item.actionBy,
                'Action By': item.approvedStatus,
                'Action Date': item.approvedDate,
            };
        });

        modifiedData.forEach(function(v){ delete v.approvedBy;delete v.approvedDate;delete v.logId;delete v.mobile; delete v.requestStatus; delete v.userId; });

        exportTableData("pastrequest_data",modifiedData);
    };

    return (
        <>
        <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Member Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Programs</option>
                            <option value="Diabetes Care Program">Diabetes Care Program</option>
                            
                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Conditions</option>
                            <option value="Diabetes Mellitus">Diabetes Mellitus</option>
                            
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={newFilteredDataPast}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default PastRequestRegisterLaterTable;
