import React, { useState } from 'react';
import { FaGoogle, FaMicrosoft, FaEnvelope, FaMobile } from 'react-icons/fa';

const authMethods = [
  { name: 'Google', icon: FaGoogle, description: 'Enable Google authentication for your users.' },
  { name: 'Microsoft', icon: FaMicrosoft, description: 'Enable Microsoft authentication for your users.' },
  { name: 'Email & Password', icon: FaEnvelope, description: 'Enable Email & Password authentication for your users.' },
  { name: 'Mobile Number & OTP', icon: FaMobile, description: 'Enable Mobile Number & OTP authentication for your users.' },
];

const AuthCard = ({ name, Icon, description, isActive, onClick }) => (
  <div 
    className={`tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-cursor-pointer
      ${isActive ? 'tw-border-2 tw-border-[#03335b] tw-bg-[#03335b]/10' : 'tw-border tw-border-gray-200'}`}
    onClick={onClick}
  >
    <div className="tw-flex tw-items-start tw-mb-4">
      <div className="tw-w-16 tw-h-16 tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-mr-4 tw-flex-shrink-0">
        <Icon className="tw-text-[#03335b] tw-text-4xl" />
      </div>
      <div>
        <h3 className="tw-text-lg tw-font-semibold tw-text-[#03335b] tw-mb-2">{name}</h3>
        <p className="tw-text-sm tw-text-gray-600 tw-leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

const Authentication = () => {
  const [activeMethod, setActiveMethod] = useState(1); // Default to Microsoft (index 1)

  return (
    <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-m-4 tw-shadow-lg">
      <h2 className="tw-text-2xl tw-font-bold tw-text-[#03335b] tw-mb-6">Authentication Methods</h2>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
        {authMethods.map((method, index) => (
          <AuthCard
            key={method.name}
            name={method.name}
            Icon={method.icon}
            description={method.description}
            isActive={index === activeMethod}
            onClick={() => setActiveMethod(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Authentication;