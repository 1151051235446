import React, { useState, useEffect } from 'react';
import { fetch_program_condition_data, fetch_program_condition_pathway_data, fetch_program_data, fetch_service_data, program_creation_frequency_data } from '../../../services/mtp/Service';
import { Checkbox } from '@mui/material';
import { LoaderLoader } from '../../utils/CommonLibrary';

function ContentProgramsDetails() {

  const [programData, setProgramData] = useState([]);
  const [conditionData, setConditionData] = useState([]);
  const [pathwayData, setPathwayData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');
  const [conditionSelectedValue, setConditionSelectedValue] = useState('');
  const [pathwaySelectedValue, setPathwaySelectedValue] = useState('');
  const [selectedServices, setSelectedServices] = useState({}); // To hold the selected services per month
  const [isLoading, setIsLoading] = useState(false);
  

  const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

  useEffect(() => {
    fetchProgramData();
  }, []);

  const fetchProgramData = async () => {
    try {
      setIsLoading(true); // show loader
      let resp = await fetch_program_data();
      if (resp.data.code === 200) {
        setProgramData(resp.data.data);
      }
      setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
  };

  const fetchServiceData = async () => {
    try {
      setIsLoading(true); // show loader
      let resp = await fetch_service_data();
      if (resp.data.code === 200) {
        setServiceData(resp.data.data);
      }
      setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
  };

  const getProgramChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    conditionSelection(value);
  };

  const conditionSelection = async (programId) => {
    try{
        setIsLoading(true); // show loader
        let response = await fetch_program_condition_data(programId);
        if (response.data.code === 200) {
          setConditionData(response.data.data || []);
        } else {
          setConditionData([]);
        }
        setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
  };

  const getConditionChange = (event) => {
    const value = event.target.value;
    setConditionSelectedValue(value);
    pathwaySelection(value);
  };

  const pathwaySelection = async (conditionId) => {
    try {
          setIsLoading(true); // show loader
          let response = await fetch_program_condition_pathway_data(conditionId);
          if (response.data.code === 200) {
            setPathwayData(response.data.data || []);
          } else {
            setPathwayData([]);
          }
          setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
  };

  const getPathwayChange = (event) => {
    const value = event.target.value;
    setPathwaySelectedValue(value);
    fetchServiceData();
  };

  const handleCheckboxChange = (month, serviceId) => {
    setSelectedServices((prev) => ({
      ...prev,
      [month]: {
        ...prev[month],
        [serviceId]: !prev[month]?.[serviceId]
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = {
      programId: selectedValue,
      conditionId: conditionSelectedValue,
      pathwayId: pathwaySelectedValue,
      services: selectedServices
    };
    try {
      setIsLoading(true); // show loader
      let response = await program_creation_frequency_data(formData);
      if (response.data.code === 200) {
        //setPathwayData(response.data.data || []);
      } else {
        //setPathwayData([]);
      }
      setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
   console.log("formData",formData);
  };


  const handleBackClick = () => {
    window.history.back();
  };


  return (
    <>
      <LoaderLoader isLoading={isLoading} />
      <div className="container-fluid px-0">
      <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    {/* <IconButton onClick={handleBackClick}>
                        <ArrowBackIcon />
                    </IconButton> */}
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}><i className="bi bi-chevron-left me-1"></i> Back</button>
                    <div className="col"> 
                        <h4 className="mb-0 content-main-title">Add New Program</h4>
                    </div>
                    </div>
                    
      </div>

      <div className='content-programs p-4'>
            <div className="row align-items-center">
              <div className="col-md-6 mb-3">
                <label htmlFor="nameOfProgram" className="form-label">Name of Program</label>
                <input type="text" className="form-control input-field-ui" id="nameOfProgram" name="nameOfProgram" placeholder='Enter Name of Program' value=''/>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="descriptionOfProgram" className="form-label">Description of Program</label>
                <input type="text" className="form-control input-field-ui" id="descriptionOfProgram" name="descriptionOfProgram" placeholder='Enter Description of Program' value=''/>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="descriptionForTheMember" className="form-label">Description for the Member</label>
                <input type="text" className="form-control input-field-ui" id="descriptionForTheMember" name="descriptionForTheMember" placeholder='Enter Description for the Member' value=''/>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-12">
                <hr />
              </div>
            </div>

            <div className="row align-items-center mb-2">
             
              <div className="col"> 
                        <h4 className="mb-0 add-new-program-title">Conditions under Program</h4>
              </div>
              <div className='col-auto'>
                <button  className="btn btn-primary program-add-new-btn">Add Condition</button>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-6 mb-3">
                <label htmlFor="conditionName" className="form-label">Condition Name</label>
                <input type="text" className="form-control input-field-ui" id="conditionName" name="conditionName" placeholder='Enter Condition Name' value=''/>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="conditionDescription" className="form-label">Condition Description</label>
                <input type="text" className="form-control input-field-ui" id="conditionDescription" name="conditionDescription" placeholder='Enter Condition Description' value=''/>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-6 mb-3">
                <label htmlFor="pathwayName" className="form-label">Pathway Name</label>
                <input type="text" className="form-control input-field-ui" id="pathwayName" name="pathwayName" placeholder='Enter Pathway Name' value=''/>
              </div>
              <div className="col-md-6 mb-3 mt-4 pt-1" >
                <button type="submit" className="btn program-add-new-btn me-2">Add Services</button>
                <button type="button" className="btn remove-btn">Remove Pathway</button>
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-6">
                  <label htmlFor="selectMonth" className="form-label">Select Month</label>
                  <select className="form-select input-field-ui" id="conditionId" name="selectMonth" aria-label="Select Month">
                  <option value="">Select Month</option>
                    <option>1 Month</option>
                    <option>2 Month</option>
                </select>
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-12">
                <div className="form-check">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxCategory1" id="checkboxCategory1"/>
                    <label className="form-check-label checkbox-program-title">Category 1</label>
                </div>
              </div>

              <div className="col-12 mt-2" style={{paddingLeft:"2.7rem"}}>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices1" id="checkboxServices1"/>
                    <label className="form-check-label mt-1">Service 1</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices2" id="checkboxServices2"/>
                    <label className="form-check-label mt-1">Service 2</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices3" id="checkboxServices3"/>
                    <label className="form-check-label mt-1">Service 3</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices4" id="checkboxServices4"/>
                    <label className="form-check-label mt-1">Service 4</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices5" id="checkboxServices5"/>
                    <label className="form-check-label mt-1">Service 5</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices6" id="checkboxServices6"/>
                    <label className="form-check-label mt-1">Service 6</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices7" id="checkboxServices7"/>
                    <label className="form-check-label mt-1">Service 7</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices8" id="checkboxServices8"/>
                    <label className="form-check-label mt-1">Service 8</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices9" id="checkboxServices9"/>
                    <label className="form-check-label mt-1">Service 9</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices10" id="checkboxServices10"/>
                    <label className="form-check-label mt-1">Service 10</label>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-12">
                <div className="form-check">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxCategory2" id="checkboxCategory2"/>
                    <label className="form-check-label checkbox-program-title">Category 2</label>
                </div>
              </div>

              <div className="col-12 mt-2" style={{paddingLeft:"2.7rem"}}>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices1" id="checkboxServices1"/>
                    <label className="form-check-label mt-1">Service 1</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices2" id="checkboxServices2"/>
                    <label className="form-check-label mt-1">Service 2</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices3" id="checkboxServices3"/>
                    <label className="form-check-label mt-1">Service 3</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices4" id="checkboxServices4"/>
                    <label className="form-check-label mt-1">Service 4</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices5" id="checkboxServices5"/>
                    <label className="form-check-label mt-1">Service 5</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices6" id="checkboxServices6"/>
                    <label className="form-check-label mt-1">Service 6</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices7" id="checkboxServices7"/>
                    <label className="form-check-label mt-1">Service 7</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices8" id="checkboxServices8"/>
                    <label className="form-check-label mt-1">Service 8</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices9" id="checkboxServices9"/>
                    <label className="form-check-label mt-1">Service 9</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-check-input-ui " type="checkbox" name="checkboxServices10" id="checkboxServices10"/>
                    <label className="form-check-label mt-1">Service 10</label>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-md-12" >
                <button type="button" className="btn remove-btn">Remove Service</button>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-12" >
                <button type="submit" className="btn program-add-new-btn me-2">Add Pathway</button>
                <button type="button" className="btn remove-btn">Remove Condition</button>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-12">
                <hr />
              </div>
            </div>

            <div className="row align-items-center">
              
              <div className='col-12 text-center'>
              <button type="submit" className="btn btn-primary btn-primary program-save-btn me-2">Submit</button>
              <button type="button" className="btn clear-btn" >Clear</button>
              </div>
            </div>


            


      </div>



      {/* <div className='content-programs mb-3'>
          
          <form onSubmit={handleSubmit}>
            <div className="row align-items-center mb-3">
              <div className="col-2">
                <label htmlFor='programId' className="col-form-label">Program Name:</label>
              </div>
              <div className="col-6">
                <select className="form-select input-field-ui" id="programId" name="programId" value={selectedValue} onChange={getProgramChange} aria-label="Select Program">
                  <option value="" disabled>Select Program</option>
                  {programData.map(option => (
                    <option key={option.programId} value={option.programId}>{option.programName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-2">
                <label htmlFor='conditionId' className="col-form-label">Condition:</label>
              </div>
              <div className="col-6">
                <select className="form-select input-field-ui" id="conditionId" name="conditionId" value={conditionSelectedValue} onChange={getConditionChange} aria-label="Select Condition">
                  <option value="" disabled>Select Condition</option>
                  {conditionData.map(option => (
                    <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-2">
                <label htmlFor='pathwayId' className="col-form-label">Pathways:</label>
              </div>
              <div className="col-6">
                <select className="form-select input-field-ui" id="pathwayId" name="pathwayId" value={pathwaySelectedValue} onChange={getPathwayChange} aria-label="Select Pathway">
                  <option value="" disabled>Select Pathways</option>
                  {pathwayData.map(option => (
                    <option key={option.pathwayId} value={option.pathwayId}>{option.pathwayName}</option>
                  ))}
                </select>
              </div>
            </div>
            {serviceData.length > 0 ? (
            <>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive table-bg p-2">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        {months.map((month, index) => (
                          <th scope="col" className='text-start' key={index}>{month} Month</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {months.map((month, index) => (
                          <td key={index} scope="col">
                            {serviceData.map((service, i) => (
                              <div key={i}>
                                <Checkbox
                                  checked={selectedServices[month]?.[service.serviceId] || false}
                                  onChange={() => handleCheckboxChange(month, service.serviceId)}
                                />
                                <img src={require("../../../images/icons-doctor.png")} className="table-icon" alt="" />
                                {service.serviceName}
                              </div>
                            ))}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
          ) : "" }
            <div className="row mb-3">
              <div className="col-2"></div>
              <div className='col-10'>
                <button type="submit" className="btn btn-primary program-save-btn">Save</button>
              </div>
            </div>
          </form>
        </div> */}



      </div>
    </>
  );
}

export default ContentProgramsDetails;
