import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight, FaPlay } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import { fetchVideoLibrary, saveLibraryPost, save_post, log_data } from "../../../services/cms/Service";
import { useNavigate } from 'react-router-dom';

const ChooseVideoFromLibrary = ({ onVideoChosen, onBack }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoLibrary, setVideoLibrary] = useState([]);
  const [mockDriveLinks, setMockDriveLinks] = useState([]);
  const navigate = useNavigate();


  const [video, setVideo] = useState({
    title: '',
    description: '',
    videoUrl: "",
    thumbnailUrl: '',
    contentType: 'Workout Video',
    program: '',
    condition: '',
    pathway: '',
    language: 'English',
    author: '',
    categories: [],
    tags: [],
    publishedDate: new Date().toISOString().split('T')[0],
    videoType: 'library',
  });

  // Function to extract video ID from Google Drive link
  const extractVideoId = (url) => {
    const match = url.match(/\/d\/(.+?)\//);
    return match ? match[1] : null;
  };

  // Function to generate thumbnail URL
  const getThumbnailUrl = (videoId) => {
    return `https://drive.google.com/thumbnail?id=${videoId}`;
  };

  // Function to fetch video details
  const fetchVideoDetails = async (url) => {
    console.log(url, "url")
    const videoId = extractVideoId(url);
    console.log(videoId, "videoId")
    if (!videoId) {
      console.error('Invalid video ID');
      return null;
    }
    return {
      id: videoId,
      title: `Video ${videoId.slice(0, 5)}`, // In a real app, you'd fetch the actual title
      thumbnail: getThumbnailUrl(videoId),
      duration: '00:00', // Duration might not be available without processing the video
      url: url
    };
  };

  // async function fetch_Video_Library() {
  //   try {

  //     let response = await fetchVideoLibrary();
  //     if (response) {
  //       console.log(response.data.videoUrls, "response")
  //       setMockDriveLinks(response.data.videoUrls)
  //       Promise.all(response.data.videoUrls.map(fetchVideoDetails))
  //         .then(videos => {
  //           const validVideos = videos.filter(video => video !== null);
  //           setVideoLibrary(validVideos);
  //         });
  //     } else {
  //       throw new Error("Failed to fetch blogs");
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  async function save_Library_Post() {
    try {

      let response = await saveLibraryPost();
      if (response) {
        console.log(response, "response")
      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }

  // useEffect(() => {
  //   fetch_Video_Library()
  // }, []);

  useEffect(() => {
    // Mock video library data with Google Drive links
    const mockDriveLinks = [
      'https://drive.google.com/file/d/1IcVJP3aA-bEtCQ3uaeJ8wQ2tIn2GCRc-/view?usp=drive_link',
      'https://drive.google.com/file/d/15xJKNgwRXiWfwOCx2WNYTdhJS2-7vFA0/view?usp=drive_link',
      'https://drive.google.com/file/d/1YJUUvrPw7v7l7XtZ9viEXpLeZiqZyy_5/view?usp=drive_link',
      'https://drive.google.com/file/d/1tyrbgWWPIq4G-YY1h9XMtQCWvTcpfy0X/view?usp=drive_link',
      'https://drive.google.com/file/d/1NyE3RpE1Dc8Yt2Hu530vbeNTnzZCEKM2/view?usp=drive_link',
      'https://drive.google.com/file/d/1mORWnF_E77FRGqxE-bIjHQFZwiFe6MWe/view?usp=drive_link',
      'https://drive.google.com/file/d/1Wlf_kc_ROjNYxk2NFtHJ0iGefanxj-Kc/view?usp=drive_link',
      'https://drive.google.com/file/d/1625ls3fWUvIVZxg8vJKyA_Gp2RjM-1q9/view?usp=drive_link',
      'https://drive.google.com/file/d/1YFWiQRfdz8CLdIbNUCmpSRcjTMmXCv09/view?usp=drive_link'
      // Add more mock Google Drive links as needed
    ];

    // Fetch video details for each link
    Promise.all(mockDriveLinks.map(fetchVideoDetails))
      .then(videos => {
        const validVideos = videos.filter(video => video !== null);
        setVideoLibrary(validVideos);
      });
  }, []);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  const handleProceed = async () => {
    if (selectedVideo) {
      const { title, description, program, pathway, condition } = video;
      let categoryIds = video.categories.map((category) => category.value);
      let tagIds = video.tags.map((tag) => tag.value);
      // let videoURL = `<iframe
      //         width="100%"
      //         height="100%"
      //         src=${selectedVideo.url}
      //         title="YouTube video player"
      //         frameBorder="0"
      //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      //         allowFullScreen
      //         className="tw-w-full tw-aspect-video"
      //       ></iframe>`;
      let videoURL = selectedVideo.url

      let data = { title, description, contentType: "library_video", author: 1, content: videoURL, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };


      try {
        let response = await save_post(data);
        if (response.data.code == 200) {
          console.log(response.data.data.pId, "response")
          let id = response.data.data.pId
          log_data({ pid: id, action: "send for Draft" })
          navigate(`/content-management/add-video-library?id=${id}`);
        } else {
          throw new Error("Failed to save");
        }
      } catch (e) {

      }
    }
  };

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl">
      <HeaderDesc
        image={'https://media.licdn.com/dms/image/D5612AQFvJE3IlOdJOw/article-cover_image-shrink_720_1280/0/1681278919474?e=2147483647&v=beta&t=6rOv5oTouM2-Kd7TziGJM4VTgroCrSF0wxF0YtMuMpU'}
        desc={'Choose Video from Library'}
      />
      <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-8 tw-text-gray-800">Choose Video from Library</h2>

      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-6">
        <div className="tw-video-grid tw-h-[calc(100vh-300px)] tw-overflow-y-auto tw-pr-4">
          <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-4">
            {videoLibrary.map(video => (
              <div
                key={video.id}
                className={`tw-cursor-pointer tw-rounded-lg tw-overflow-hidden tw-shadow-md tw-transition-all tw-duration-300 ${selectedVideo?.id === video.id ? 'tw-ring-2 tw-ring-blue-500' : 'hover:tw-shadow-lg'}`}
                onClick={() => handleVideoSelect(video)}
              >
                <div className="tw-relative">
                  <img src={video.thumbnail} alt={video.title} className="tw-w-full tw-aspect-video tw-object-cover" />
                  <div className="tw-absolute tw-bottom-2 tw-right-2 tw-bg-black tw-bg-opacity-75 tw-text-white tw-text-xs tw-px-2 tw-py-1 tw-rounded">
                    {video.duration}
                  </div>
                </div>
                <div className="tw-p-2 tw-bg-gray-100">
                  <p className="tw-font-semibold tw-text-sm tw-truncate">{video.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="tw-video-preview tw-bg-gray-100 tw-p-4 tw-rounded-lg">
          {selectedVideo ? (
            <>
              <div className="tw-relative tw-w-full tw-aspect-video tw-mb-4">
                <iframe
                  src={`https://drive.google.com/file/d/${selectedVideo.id}/preview`}
                  className="tw-w-full tw-h-full"
                  allow="autoplay"
                ></iframe>
              </div>
              <h3 className="tw-text-xl tw-font-semibold tw-mb-2">{selectedVideo.title}</h3>
              <p className="tw-text-sm tw-text-gray-600 tw-mb-4">Duration: {selectedVideo.duration}</p>
              <button
                className="tw-w-full tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-transition tw-duration-300 tw-ease-in-out tw-flex tw-items-center tw-justify-center"
                onClick={handleProceed}
              >
                Use This Video <FaArrowRight className="tw-ml-2" />
              </button>
            </>
          ) : (
            <div className="tw-h-full tw-flex tw-items-center tw-justify-center tw-text-gray-500">
              Select a video to preview
            </div>
          )}
        </div>
      </div>

      <div className="tw-flex tw-justify-start tw-mt-6">
        <button
          className="tw-border tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-100 tw-font-semibold tw-py-2 tw-px-4 tw-rounded tw-flex tw-items-center tw-transition tw-duration-300 tw-ease-in-out"
          onClick={onBack}
        >
          <FaArrowLeft className="tw-mr-2" /> Back to Options
        </button>
      </div>
    </div>
  );
};

export default ChooseVideoFromLibrary;